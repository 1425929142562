import { useEffect, useState } from "react";
import {
  PlusCircleOutlined,
  CloseOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import {
  Table,
  Modal,
  Button,
  Form,
  Input,
  DatePicker,
  Select,
  Space,
  Row,
  Col,
  Tag,
  Popconfirm,
} from "antd";
import "../../styles/Home-module.css";
import { Circles } from "react-loader-spinner";
import { request } from "../../share/request";
import { getTageStyle } from "../../share/getStatusContent";
import { formateDateClient } from "../../share/helper";
import { openNotification } from "../../share/message";
import { getCurrentUser, getPermission } from "../../share/getProfile";
import * as FileSaver from "file-saver";
import dayjs from "dayjs";
import ExcelJS from "exceljs";
import PatientForm from "../AnknomphiemPatient/anknomphiem_patient";
import {
  boxHeader,
  boxHeaderTag,
  boxHeaderTagText,
} from "../../styles/boxShadow";
import useOrganizationImage from "../../share/GetImageOrg";
const WeekendCaseReport = () => {
  const userProfile = getCurrentUser();
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isOpenModel, setOpenModel] = useState(false);
  const [list, setList] = useState([]);
  // get list
  const [currentPage, setCurrentPage] = useState(null);
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [sickday, setSickday] = useState("");
  const [dateIn, setDateIn] = useState("");
  const [dateOut, setDateOut] = useState("");
  const [diagnosis, setDiagnosis] = useState(""); //
  const [other, setOther] = useState("");

  const [list_province, setListProvince] = useState([]);
  const [list_city, setListCity] = useState([]);
  const [list_com, setListCom] = useState([]);
  const [list_village, setListVillage] = useState([]);
  const [id, setId] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [com, setCom] = useState("");
  const [village, setVillage] = useState("");
  const [proCode, setProCode] = useState(null);
  const [cityCode, setCityCode] = useState(null);
  const [comCode, setComCode] = useState(null);
  const [villageCode, setVillageCode] = useState(null);

  const [list_weekly, setListWeekly] = useState([]);
  const [weekly, setWeekly] = useState("");
  const LogoPath = useOrganizationImage();
  // download to excel file
  const [list_excel, setListExcel] = useState([]);
  // -----------------  permisstion page --------------------
  const userPermission = getPermission();
  const weeklyReportValue = userPermission.find(
    (permission) => permission.pagename === "caseweekendreport"
  ) || { add: 0, edit: 0, deleted: 0 };
  const {
    add: addvalue,
    edit: Editvalue,
    deleted: deletevalue,
    print: printvalue,
  } = weeklyReportValue;
  // -----------------  end permisstion page --------------------
  const [totalPage, setTotalPages] = useState("");
  const [objFilter, setObjFilter] = useState({
    weekly: "",
    page_number: 1,
    page_size: 10,
    org_code: userProfile.org_code,
  });

  useEffect(() => {
    console.log("param on effect: ", objFilter);
    getList(objFilter);
  }, []);

  const onChangePage = async (page) => {
    setCurrentPage(page);
    try {
      setLoadingSpin(true);
      var objTmp = {
        ...objFilter,
        page_number: page, // Update the correct property `page_number`
      };
      await new Promise((resolve) => setTimeout(resolve, 400));
      setObjFilter(objTmp); // Ensure this happens before calling `getList`
      getList(objTmp); // Pass the updated filter to `getList`
    } catch (error) {
      console.error("Error changing page:", error);
    } finally {
      setLoadingSpin(false);
    }
  };

  const getList = async (ParamObjFilter) => {
    try {
      setLoading(true); // Start loading

      // Construct query parameters dynamically based on `ParamObjFilter` or default values
      const params = new URLSearchParams({
        weekly: ParamObjFilter.weekly, // Optional value
        page_number: ParamObjFilter.page_number, // Correct the `page_number`
        org_code: ParamObjFilter.org_code, // Optional value
      });

      // Send the GET request
      const res = await request("casereportweekend?" + params, "get", {});
      console.log("Query Params:", params.toString());
      console.log("Response from casereportweekend:", res);

      // Simulate delay (optional)
      await new Promise((resolve) => setTimeout(resolve, 400));

      // Check if the response exists and set the data accordingly
      if (res) {
        setList(Array.isArray(res.list) ? res.list : []);
        setListProvince(res.list_province || []);
        setListWeekly(res.list_weekly || []);
        setTotalPages(res.countpage); // Handle `countpage` safely
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const { Option } = Select;
  const columns = [
    {
      key: "id",
      title: "ល.រ",
      dataIndex: "id",
      render: (text, record, index) => {
        return (currentPage - 1) * 10 + index + 1;
      },
    },

    {
      key: "name_kh",
      title: "ឈ្មោះ",
      dataIndex: "name_kh",
    },

    {
      key: "age",
      title: "អាយុ",
      dataIndex: "age",
      render: (record) => {
        return (
          <div>
            <Tag>{record}</Tag>
          </div>
        );
      },
    },
    {
      key: "gender",
      title: "ភេទ",
      dataIndex: "gender",
      render: (value) => {
        return (
          <Tag
            color="#108ee9"
            style={{ borderRadius: 50, paddingLeft: 10, paddingRight: 10 }}
          >
            {value}
          </Tag>
        );
      },
    },
    {
      key: "village_name_khmer",
      title: (
        <Tag
          color="#108ee9"
          style={{ borderRadius: 50, paddingLeft: 10, paddingRight: 10 }}
        >
          {"ភូមិ"}
        </Tag>
      ),
      dataIndex: "village_name_khmer",
      render: (record) => {
        return (
          <div>
            <Tag>{record}</Tag>
          </div>
        );
      },
    },

    // {
    //   key: "com_name_khmer",
    //   title: (
    //     <Tag
    //       color="#108ee9"
    //       style={{ borderRadius: 50, paddingLeft: 10, paddingRight: 10 }}
    //     >
    //       {"ឃុំ"}
    //     </Tag>
    //   ),
    //   dataIndex: "com_name_khmer",
    // },
    // {
    //   key: "city_name_khmer",
    //   title: (
    //     <Tag
    //       color="#108ee9"
    //       style={{ borderRadius: 50, paddingLeft: 10, paddingRight: 10 }}
    //     >
    //       {"ស្រុក"}
    //     </Tag>
    //   ),
    //   dataIndex: "city_name_khmer",
    // },
    // {
    //   key: "province_name_khmer",
    //   title: (
    //     <Tag
    //       color="#108ee9"
    //       style={{ borderRadius: 50, paddingLeft: 10, paddingRight: 10 }}
    //     >
    //       {"ខេត្ត"}
    //     </Tag>
    //   ),
    //   dataIndex: "province_name_khmer",
    // },
    {
      key: "date_sickday",
      title: (
        <div>
          <Tag>ថ្ងៃចេញរោគសញ្ញា</Tag>
        </div>
      ),
      dataIndex: "date_sickday",
      render: (value) => {
        return (
          <div>
            <Tag color="green">{formateDateClient(value)}</Tag>
          </div>
        );
      },
    },
    {
      key: "date_in",
      title: (
        <div>
          <Tag>ថ្ងៃចូលពេទ្យ</Tag>
        </div>
      ),
      dataIndex: "date_in",
      render: (value) => {
        return (
          <div>
            {" "}
            <Tag color="magenta">{formateDateClient(value)}</Tag>
          </div>
        );
      },
    },
    {
      key: "date_out",
      title: (
        <div>
          <Tag>ថ្ងៃចេញពេទ្យ</Tag>
        </div>
      ),
      dataIndex: "date_out",
      render: (value) => {
        return (
          <div>
            {" "}
            <Tag color="magenta">{formateDateClient(value)}</Tag>
          </div>
        );
      },
    },
    {
      key: "diagnosis",
      title: (
        <div>
          <Tag>រោគសញ្ញា</Tag>
        </div>
      ),
      dataIndex: "diagnosis",
    },
    {
      key: "other",
      title: (
        <div>
          <Tag>ផ្សេងៗ</Tag>
        </div>
      ),
      dataIndex: "other",
    },
    {
      key: "create_date",
      title: (
        <div>
          <Tag>ថ្ងៃបង្កើត</Tag>
        </div>
      ),

      render: (item, index) => {
        return (
          <div>
            <Tag color="purple">{formateDateClient(item.create_date)}</Tag>
            <Tag color="green" style={{ marginTop: 2 }}>
              {item.week_name}
            </Tag>
          </div>
        );
      },
    },
    {
      key: "create_by",
      title: (
        <div>
          <Tag>បង្កើតដោយ</Tag>
        </div>
      ),
      dataIndex: "create_by",
      render: (value, index) => {
        return (
          <Tag color="#108ee9" style={{ borderRadius: 50 }}>
            {value}
          </Tag>
        );
      },
    },
    {
      key: "action",
      title: (
        <div>
          <Tag>សកម្មភាព</Tag>
        </div>
      ),
      dataIndex: "action",
      render: (value, item, index) => {
        return (
          <div>
            <Space key={index}>
              <Button
                type="primary"
                onClick={() => handleOpenEdit(item)}
                disabled={!Editvalue}
              >
                Edit
              </Button>

              <Popconfirm
                placement="topRight"
                title={"Delete Item"}
                description={"Do you want to delete this item?"}
                okText={"Yes"}
                cancelText="No"
                onConfirm={() => handleDeleteRecord(item)}
              >
                <Button
                  disabled={!deletevalue}
                  danger
                  type="primary"
                  icon={<CloseOutlined />}
                />
              </Popconfirm>
            </Space>
          </div>
        );
      },
    },
  ];
  const handleDeleteRecord = async (item) => {
    try {
      setLoadingSpin(true);
      const data = { id: item.id };
      const res = await request(`casereportdeleterecord`, "put", data);
      if (res) {
        openNotification("success", "Delete Record", res.message);
        getList(objFilter);
      }
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };
  const onOpenModel = () => {
    setName("");
    setAge("");
    setGender("");
    setProCode("");
    setCityCode("");
    setComCode("");
    setVillageCode("");
    setSickday("");
    setDateIn("");
    setDateOut("");
    setDiagnosis("");
    setOther("");
    setId(null); // Reset id if necessary
    setProvince("");
    setCity("");
    setCom("");
    setVillage(""); // Reset city if necessary
    setWeekly("");
    setSickday(dayjs().format("YYYY-MM-DD"));
    setDateIn(dayjs().format("YYYY-MM-DD"));
    setDateOut(dayjs().format("YYYY-MM-DD"));
    setOpenModel(true);
  };
  const handleOpenEdit = (record) => {
    // `record` is the row or data entry you want to edit

    // Set the current data to the form fields for editing
    setId(record.id);
    setName(record.name_kh);
    setAge(record.age);
    setGender(record.gender);
    setProCode(record.province_id);
    setCityCode(record.city_id);
    setComCode(record.commun_id);
    setVillageCode(record.village_id);
    setSickday(record.date_sickday);
    setDateIn(record.date_in);
    setDateOut(record.date_out);
    setDiagnosis(record.diagnosis);
    setOther(record.other);
    setProvince(record.province_name_khmer);
    setCity(record.city_name_khmer);
    setCom(record.com_name_khmer);
    setVillage(record.village_name_khmer);
    setWeekly(record.week_name);
    setOpenModel(true); // This could be a flag to show the edit form
  };

  const onClickProvince = async (value) => {
    // try {
    //   const res = await request("casereportweekend", "get", {});
    //   await new Promise((resolve) => setTimeout(resolve, 400));
    //   console.log("casereportweekend", res);
    //   if (res && Array.isArray(res.list_province)) {
    //     setListProvince(res.list_province); // Set the array correctly
    //   }
    // } catch (error) {}
  };
  const onChangeProvince = async (value) => {
    console.log("onChangeProvince", value);
    setProvince(value);
    setProCode(value);

    // Reset city, commune, and village when province changes
    setCity(""); // Reset city to empty string (or undefined)
    setCom(""); // Reset commune to empty string (or undefined)
    setVillage(""); // Reset village to empty string (or undefined)

    // Also reset the corresponding codes
    setCityCode(null);
    setComCode(null);
    setVillageCode(null);

    // Clear the lists for commune and village when province changes
    setListCity([]);
    setListCom([]);
    setListVillage([]);

    const res = await request("useraddresscity/" + value, "get", {});
    console.log("API response:", res);
    if (res && res.API_Server_listCity) {
      setListCity(res.API_Server_listCity);
    }
  };

  const onChangeCity = async (value) => {
    console.log("onChangeCity", value);
    setCityCode(value);
    setCity(value);
    setCom("");
    setVillage("");
    setListCom([]);
    setListVillage([]);
    const res = await request("useraddresscom/" + value, "get", {});
    console.log("API response:", res);
    if (res && res.API_Server_listCom) {
      setListCom(res.API_Server_listCom);
    }
  };
  const onClickCity = async () => {
    console.log("province code is : ", proCode);
    const res = await request("useraddresscity/" + proCode, "get", {});
    console.log("API response:", res);
    if (res && res.API_Server_listCity) {
      setListCity(res.API_Server_listCity);
    }
  };
  const onChangeCom = async (value) => {
    setCom(value);
    setComCode(value);
    setVillage("");
    console.log("com code is : ", value);
    const res = await request("useraddressvillage/" + value, "get", {});
    console.log("API response:", res);
    if (res && res.API_Server_listVillage) {
      setListVillage(res.API_Server_listVillage);
    }
  };

  const onChangeVillage = async (value) => {
    console.log("on change village ", value);
    setVillage(value);
    setVillageCode(value);
  };
  const onClickCom = async () => {
    console.log("com code is : ", comCode);
    const res = await request("useraddresscom/" + cityCode, "get", {});
    console.log("API response:", res);
    if (res && res.API_Server_listCom) {
      setListCom(res.API_Server_listCom);
    }
  };
  const onClickVillage = async () => {
    const res = await request("useraddressvillage/" + comCode, "get", {});
    console.log("API response:", res);
    if (res && res.API_Server_listVillage) {
      setListVillage(res.API_Server_listVillage);
    }
  };
  const onSaveData = async () => {
    try {
      // Check if any field is null or empty
      if (
        !name ||
        !age ||
        !gender ||
        !proCode ||
        !cityCode ||
        !comCode ||
        !villageCode ||
        !sickday ||
        !dateIn ||
        !dateOut ||
        !diagnosis ||
        !other ||
        !weekly
      ) {
        openNotification(
          "error",
          "Error",
          "Please fill in all the required fields."
        );
        return; // Stop the function if validation fails
      }

      // Parse the date fields to compare
      const sickdayDate = new Date(sickday);
      const dateInDate = new Date(dateIn);
      const dateOutDate = new Date(dateOut);

      // Check if sickday is after dateIn or dateOut
      if (sickdayDate > dateInDate || sickdayDate > dateOutDate) {
        openNotification(
          "error",
          "Error",
          "'Sickday' must be before or equal to 'Date In' and 'Date Out'."
        );
        return; // Stop the function if validation fails
      }

      // Check if dateIn is after dateOut
      if (dateInDate > dateOutDate) {
        openNotification(
          "error",
          "Error",
          "'Date In' must be before or equal to 'Date Out'."
        );
        return; // Stop the function if validation fails
      }

      const data = {
        id: id,
        name_kh: name,
        age: age,
        gender: gender,
        province_id: proCode,
        city_id: cityCode,
        commun_id: comCode,
        village_id: villageCode,
        date_sickday: sickday,
        date_in: dateIn,
        date_out: dateOut,
        diagnosis: diagnosis,
        other: other,
        create_by: userProfile.fullname,
        weekly: weekly,
        org_code: userProfile.org_code,
      };

      let res;
      setOpenModel(false);
      setLoadingSpin(true);
      if (id == null) {
        // Save (insert) new data
        res = await request("casereportweekend", "post", data);
      } else {
        // Update existing data
        res = await request(`casereportweekend`, "put", data);
      }

      if (res) {
        openNotification("success", "Success", res.message);

        // Clear all form fields after successful save
        setName("");
        setAge("");
        setGender("");
        setProCode("");
        setCityCode("");
        setComCode("");
        setVillageCode("");
        setSickday("");
        setDateIn("");
        setDateOut("");
        setDiagnosis("");
        setOther("");
        setId(null); // Reset id if necessary
      }
    } catch (error) {
      openNotification("error", "Error", error);
    } finally {
      setLoadingSpin(false);
      getList(objFilter);
    }
  };

  const handleDateSickDayChange = (date, dateString) => {
    setSickday(dayjs(dateString, "DD/MM/YYYY")); // Store the date as a string in YYYY-MM-DD format
  };

  const handleDateInChange = (date, dateString) => {
    setDateIn(dayjs(dateString, "DD/MM/YYYY"));
  };

  const handleDateOutChange = (date, dateString) => {
    setDateOut(dayjs(dateString, "DD/MM/YYYY"));
  };
  // Change handlers
  const onChangeName = (e) => {
    setName(e.target.value);
  };

  const onChangeAge = (e) => {
    setAge(e.target.value);
  };

  const onChangeGender = (value) => {
    setGender(value); // Assuming the value is the selected gender option
  };

  const onChangeDiagnosis = (e) => {
    setDiagnosis(e.target.value);
  };

  const onChangeOther = (e) => {
    setOther(e.target.value);
  };
  const onChangeWeekly = (e) => {
    setWeekly(e);
  };

  const onChangeWeeklySearch = async (value) => {
    setWeekly(value);
    setCurrentPage(1);
    if (value === undefined) {
      try {
        setLoadingSpin(true);
        var objTmp = {
          weekly: "", // Update the correct property `page_number`
          page_number: 1,
          org_code: userProfile.org_code,
        };
        setObjFilter(objTmp); // Ensure this happens before calling `getList`
        getList(objTmp); // Pass the updated filter to `getList`
      } catch (error) {
        console.error("Error changing page:", error);
      } finally {
        setLoadingSpin(false);
      }
    } else {
      try {
        setLoadingSpin(true);
        var objTmp = {
          ...objFilter,
          page_number: 1,
          weekly: value, // Update the correct property `page_number`
        };

        setObjFilter(objTmp); // Ensure this happens before calling `getList`
        getList(objTmp); // Pass the updated filter to `getList`
      } catch (error) {
        console.error("Error changing page:", error);
      } finally {
        setLoadingSpin(false);
      }
    }
  };

  const onHanndleDownloadReport = async () => {
    if (weekly == null || weekly === undefined) {
      openNotification(
        "error",
        "Canot Download Report",
        "Please select Weekly Download Report"
      );
    } else {
      try {
        setLoadingSpin(true);
        await new Promise((resolve) => setTimeout(resolve, 400));

        // Fetch data to be exported to Excel
        const data = await onExporttoExcel();

        // Ensure data is an array and contains items
        if (!Array.isArray(data) || data.length === 0) {
          console.error("Data is not an array or is empty.", data);
          setLoadingSpin(false);
          openNotification("error", "Error", "No data available for download.");
          return;
        }

        // Load the template Excel file from the public folder
        const templatePath =
          process.env.PUBLIC_URL + "/template/weeklyreport.xlsx";
        const response = await fetch(templatePath);
        const templateArrayBuffer = await response.arrayBuffer();

        // Create a workbook from the template file
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(templateArrayBuffer);

        // Get the 'detail' sheet from the workbook
        const dataSheet = workbook.getWorksheet("detail");
        if (!dataSheet) {
          console.error('Sheet with name "detail" not found in the template.');
          setLoadingSpin(false);
          return;
        }
        // Set the weekly string to cell N10
        dataSheet.getCell("N10").value = `${weekly}`;
        dataSheet.getCell("A4").value = `${userProfile.org_name_kh}`;
        try {
          console.log("Fetching image from:", LogoPath);

          const response = await fetch(LogoPath, { mode: "cors" }); // Ensure CORS is allowed
          if (!response.ok) {
            throw new Error(
              `Failed to fetch image. Status: ${response.status}`
            );
          }

          const imageBuffer = await response.arrayBuffer(); // Convert to array buffer

          // Add the image to the workbook
          const imageId = workbook.addImage({
            buffer: Buffer.from(imageBuffer), // Convert ArrayBuffer to Buffer
            extension: "png", // Ensure correct image format
          });

          // Add the image to the worksheet
          dataSheet.addImage(imageId, {
            tl: { col: 1, row: 2 },
            ext: { width: 150, height: 150 },
          });

          console.log("Image added successfully!");
        } catch (error) {
          console.error("Error adding image to workbook:", error);
        }

        // Convert the fetched data to an array format (rows)
        const newData = data.map((item) => Object.values(item));

        // Ensure newData is not empty and contains valid column names
        if (newData.length > 0 && Object.keys(data[0]).length > 0) {
          const columns = Object.keys(data[0]).map((key) => ({ name: key }));

          // Append the new data to the existing 'detail' sheet starting from cell A10
          dataSheet.addTable({
            name: "dataTable",
            ref: "A10", // Ensure this is where your table starts
            headerRow: true,
            columns: columns,
            rows: newData,
          });

          // Calculate the last row of the data
          const lastRow = newData.length + 10; // Adjust based on starting row (A10)

          // Hide rows from the last data row + 1 to row 100
          for (let i = lastRow + 1; i <= 100; i++) {
            const row = dataSheet.getRow(i);
            row.hidden = true; // Hide the row
          }
        } else {
          console.error(
            "New data is empty or does not contain valid column names."
          );
          setLoadingSpin(false);
          return;
        }

        // Save the modified workbook as a Blob with XLSX format
        const blob = await workbook.xlsx.writeBuffer();
        const name_report = `Weekly-Report-${weekly}`;
        FileSaver.saveAs(new Blob([blob]), `${name_report}.xlsx`);
        setLoadingSpin(false);
      } catch (error) {
        setLoadingSpin(false);
        console.error("Error during file download:", error);
        openNotification("error", "error", "File can't be downloaded!");
      }
    }
  };

  const onExporttoExcel = async () => {
    const params = new URLSearchParams({
      weekly: weekly,
      org_code: userProfile.org_code,
    });

    await new Promise((resolve) => setTimeout(resolve, 400)); // Simulating delay
    const res = await request(
      "caseweekdownload?" + params.toString(),
      "get",
      {}
    );

    // Ensure res.listdownload is an array
    if (res && Array.isArray(res.listdownload)) {
      const dataArray = res.listdownload; // Use the full array

      // Function to format the date to 'DD-MM-YYYY'
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-GB"); // 'en-GB' formats to DD-MM-YYYY
      };

      // Create a new array of objects excluding specific fields and formatting the dates
      const filteredData = dataArray.map((item, index) => {
        const {
          create_date,
          create_by,
          province_id,
          city_id,
          commun_id,
          village_id,
          week_name,
          ...filteredItem
        } = item;

        // Format the dates before returning the filtered item
        if (filteredItem.date_sickday)
          filteredItem.date_sickday = formatDate(filteredItem.date_sickday);
        if (filteredItem.date_in)
          filteredItem.date_in = formatDate(filteredItem.date_in);
        if (filteredItem.date_out)
          filteredItem.date_out = formatDate(filteredItem.date_out);
        filteredItem.id = index + 1;
        return filteredItem;
      });

      // Log and return the filtered array for further use if needed
      console.log(
        "Filtered data with formatted dates for export:",
        filteredData
      );
      return filteredData;
    }

    console.error("No valid data received from server.");
    return []; // Return an empty array if no data
  };
  const [patientModel, setpatientModel] = useState(false);
  const onOpenModelanknomphiem_patient = () => {
    setpatientModel(true);
  };
  return (
    <div>
      <Modal open={patientModel} width={900}>
        <PatientForm />
      </Modal>

      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>

      <div style={boxHeader}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Space>
            {boxHeaderTagText("តារាងជំងឺប្រចាំសប្តាហ៍")}
            {/* <Input placeholder="Search..." style={{ height: 40 }}></Input> */}
            <Select
              style={{ width: 150 }}
              className="styleTageStatusForm"
              // onChange={onChangeYearReport}
              placeholder="Select Year"
              // value={Year_Report}
              allowClear
            >
              <Option value="2022">2022</Option>
              <Option value="2023">2023</Option>
              <Option value="2024">2024</Option>
              <Option value="2025">2025</Option>
              <Option value="2026">2026</Option>
              <Option value="2027">2027</Option>
            </Select>
            <Select
              className="styleTageStatusForm"
              placeholder="Select an Weekly"
              style={{ width: 250, fontWeight: "bold" }}
              onChange={onChangeWeeklySearch}
              allowClear={true}
            >
              {list_weekly.map((option) => (
                <Option
                  className="styleTageStatusForm"
                  style={{ fontWeight: "bold" }}
                  key={option.id}
                  value={option.week_name}
                >
                  {option?.week_name || null}{" "}
                </Option>
              ))}
            </Select>
          </Space>
        </div>
        <div style={{ display: "flex" }}>
          <Space>
            <Button
              disabled={!printvalue}
              onClick={onHanndleDownloadReport}
              className="buttonstylekhmer"
              type="primary"
              icon={<PrinterOutlined />}
            >
              ទាញយករបាយការណ៍
            </Button>
            <Button
              disabled={!addvalue}
              onClick={onOpenModel}
              className="buttonstylekhmer"
              type="primary"
              icon={<PlusCircleOutlined />}
            >
              បង្កើតថ្មី
            </Button>
          </Space>
        </div>
      </div>
      <div>
        {loading ? (
          <div className="sprinCustome">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Table
            pagination={{
              pageSize: 10,
              total: totalPage,
              current: currentPage,
              onChange: onChangePage,
              // position: ["bottomLeft"], // Set pagination to bottom left
            }}
            columns={columns}
            size="small"
            dataSource={list}
          />
        )}
      </div>
      <Modal
        open={isOpenModel}
        width={900}
        title={id == null ? "បង្កើតថ្មី (New Case)" : "កែប្រែ (Update)"}
        onCancel={() => {
          setOpenModel(false);
        }}
        onClose={() => {
          setOpenModel(false);
        }}
        footer={[
          <Button key="cancel" onClick={() => setOpenModel(false)}>
            បោះបង់ (Cancel)
          </Button>,
          <Button key="ok" type="primary" onClick={() => onSaveData()}>
            {id == null ? "រក្សាទុក (Save)" : "កែប្រែ (Update)"}
          </Button>,
        ]}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ margin: 20, width: 300 }}>
            <Form.Item
              label="ឈ្មោះ (Name)"
              name="name"
              rules={[{ required: true, message: "សូមបញ្ចូលឈ្មោះ!" }]}
            ></Form.Item>
            <Input
              style={{ width: 250, height: 40 }}
              placeholder="បញ្ចូលឈ្មោះ"
              onChange={onChangeName}
              value={name}
            />

            <Form.Item
              label="អាយុ (Age)"
              name="age"
              rules={[{ required: true, message: "សូមបញ្ចូលអាយុ!" }]}
            ></Form.Item>
            <Input
              value={age}
              style={{ width: 250, height: 40 }}
              placeholder="បញ្ចូលអាយុ"
              onChange={onChangeAge}
            />

            <Form.Item
              label="ភេទ (Gender)"
              name="gender"
              rules={[{ required: true, message: "សូមជ្រើសរើសភេទ!" }]}
            ></Form.Item>
            <Select
              placeholder="ជ្រើសរើសភេទ"
              onChange={onChangeGender}
              value={gender}
              style={{ width: 250, height: 40 }}
            >
              <Option value="ប្រុស">ប្រុស</Option>
              <Option value="ស្រី">ស្រី</Option>
            </Select>
          </div>
          <div style={{ margin: 20, width: 300 }}>
            <Form.Item
              label="ខេត្ត (Province)"
              name="province"
              rules={[{ required: true, message: "សូមបញ្ចូលខេត្ត!" }]}
            ></Form.Item>
            <Select
              className="styleTageStatusForm"
              placeholder="Select an Province"
              style={{ width: 250 }}
              onClick={onClickProvince}
              onChange={onChangeProvince}
              value={province}
            >
              {list_province.map((option) => (
                <Option
                  className="styleTageStatusForm"
                  key={option.province_code}
                  value={option.province_code}
                >
                  {option.province_name_khmer}
                  {/* Replace with the field from your MySQL table */}
                </Option>
              ))}
            </Select>

            <Form.Item
              label="ស្រុក (District)"
              name="district"
              rules={[{ required: true, message: "សូមបញ្ចូលស្រុក!" }]}
            ></Form.Item>
            <Select
              value={city}
              className="styleTageStatusForm"
              placeholder="Select an City"
              style={{ width: 250 }}
              onChange={onChangeCity}
              onClick={onClickCity}
              disabled={!list_city.length}
            >
              {list_city.map((option) => (
                <Option
                  className="styleTageStatusForm"
                  key={option.city_code}
                  value={option.city_code}
                >
                  {option?.city_name_khmer || null}{" "}
                </Option>
              ))}
            </Select>

            <Form.Item
              label="ឃុំ (Commune)"
              name="commune"
              rules={[{ required: true, message: "សូមបញ្ចូលឃុំ!" }]}
            ></Form.Item>
            <Select
              className="styleTageStatusForm"
              placeholder="Select an Commune"
              style={{ width: 250 }}
              onChange={onChangeCom}
              onClick={onClickCom}
              value={com}
              disabled={!list_com.length}
            >
              {list_com.map((option) => (
                <Option
                  className="styleTageStatusForm"
                  key={option.com_code}
                  value={option.com_code}
                >
                  {option?.com_name_khmer || null}{" "}
                </Option>
              ))}
            </Select>

            <Form.Item
              label="ភូមិ (Village)"
              name="village"
              rules={[{ required: true, message: "សូមបញ្ចូលភូមិ!" }]}
            ></Form.Item>
            <Select
              className="styleTageStatusForm"
              placeholder="Select an Village"
              style={{ width: 250 }}
              onChange={onChangeVillage}
              onClick={onClickVillage}
              value={village}
              disabled={!list_village.length}
            >
              {list_village.map((option) => (
                <Option
                  className="styleTageStatusForm"
                  key={option.village_code}
                  value={option.village_code}
                >
                  {option?.village_name_khmer || null}{" "}
                  {/* Replace with the field from your MySQL table */}
                </Option>
              ))}
            </Select>
          </div>
          <div style={{ margin: 20, width: 300 }}>
            <Form.Item
              label="ថ្ងៃចេញរោគសញ្ញា (Symptom Date)"
              name="symptomDate"
              rules={[
                { required: true, message: "សូមជ្រើសរើសថ្ងៃចេញរោគសញ្ញា!" },
              ]}
            ></Form.Item>
            <DatePicker
              name={sickday}
              value={dayjs(sickday)}
              format="DD/MM/YYYY"
              style={{ width: "100%", height: 40 }}
              onChange={handleDateSickDayChange}
            />

            <Form.Item
              label="ថ្ងៃចូលពេទ្យ (Admission Date)"
              name="admissionDate"
              rules={[{ required: true, message: "សូមជ្រើសរើសថ្ងៃចូលពេទ្យ!" }]}
            ></Form.Item>
            <DatePicker
              name={dateIn}
              value={dayjs(dateIn)}
              format="DD/MM/YYYY"
              style={{ width: "100%", height: 40 }}
              onChange={handleDateInChange}
            />

            <Form.Item
              label="ថ្ងៃចេញពេទ្យ (Discharge Date)"
              name="dischargeDate"
              rules={[{ required: true, message: "សូមជ្រើសរើសថ្ងៃចេញពេទ្យ!" }]}
            ></Form.Item>
            <DatePicker
              name={dateOut}
              value={dayjs(dateOut)}
              format="DD/MM/YYYY"
              style={{ width: "100%", height: 40 }}
              onChange={handleDateOutChange}
            />
            <Form.Item
              label="សប្តាហ៍ទី (Weekend)"
              rules={[{ required: true, message: "សប្តាហ៍ទី (Weekend)" }]}
            ></Form.Item>
            <Select
              value={weekly}
              className="styleTageStatusForm"
              placeholder="Select an Weekly"
              style={{ width: 250 }}
              onChange={onChangeWeekly}
            >
              {list_weekly.map((option) => (
                <Option
                  className="styleTageStatusForm"
                  key={option.id}
                  value={option.week_name}
                >
                  {option?.week_name || null}{" "}
                </Option>
              ))}
            </Select>
          </div>
        </div>

        {/* Symptoms and Other Information */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="រោគសញ្ញា (Symptoms)"
              name="symptoms"
              rules={[{ required: true, message: "សូមបញ្ចូលរោគសញ្ញា!" }]}
            >
              {" "}
            </Form.Item>
            <Input.TextArea
              value={diagnosis}
              placeholder="បញ្ចូលរោគសញ្ញា"
              rows={2}
              onChange={onChangeDiagnosis}
            />
          </Col>

          <Col span={12}>
            <Form.Item
              label="ផ្សេងៗ (Other information)"
              name="others"
            ></Form.Item>
            <Input.TextArea
              placeholder="បញ្ចូលព័ត៌មានផ្សេងៗ"
              rows={2}
              value={other}
              onChange={onChangeOther}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
export default WeekendCaseReport;
