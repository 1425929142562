import React, { useEffect, useState, useRef } from "react";
import { DownloadOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Modal, Button, Input, Select, Space, Tag, DatePicker } from "antd";
import "../../styles/Home-module.css";
import styles from "../../styles/Font.css";
import { request } from "../../share/request";
import Table from "react-bootstrap/Table";
import { Stack } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { openNotification } from "../../share/message";
import { Circles } from "react-loader-spinner";
import { getPermission } from "../../share/getProfile";
import {} from "../../share/getStatusContent";
import {
  formateDateClient,
  formateDateSystem,
  toKhmerDay,
  toKhmerMonth,
  toKhmerYear,
} from "../../share/helper";
import { MdOutlineEdit, MdOutlineDelete } from "react-icons/md";

import MyDatePicker from "../../share/MyDatePicker";

import { BiCheckDouble } from "react-icons/bi";
import ComponentToPrint from "../accounting/PrintComponents";
import ComponentToPrint62028 from "./PrintComponents62028";
import { useReactToPrint } from "react-to-print";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";

const { Option } = Select;

const BankingReport = () => {
  const [list, setList] = useState([]);
  const [UserID, setUserID] = useState("");
  const [type, setType] = useState("");
  const [bank_no, setBankNo] = useState();
  const [date, setDate] = useState();
  const [total_eng, setTotalEng] = useState();
  const [total_kh, setTotalKH] = useState();
  const [grade, setGrade] = useState();

  const [loading, setLoading] = useState(true);
  const [loadingSpin, setLoadingSpin] = useState(false);

  // print component
  const componentRef = useRef(null);
  const [itemPrint, setItemPrint] = useState(null);
  const [shouldPrint, setShouldPrint] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  const componentRef62028 = useRef(null);
  const [itemPrint62028, setItemPrint62028] = useState(null);
  const [shouldPrint62028, setShouldPrint62028] = useState(false);
  const [dataFetched62028, setDataFetched62028] = useState(false);
  // -----------------  permisstion page --------------------
  const userPermission = getPermission();
  const RightManagerPageValue = userPermission.find(
    (permission) => permission.pagename === "bankreport"
  ) || { add: 0, edit: 0, deleted: 0, print: 0 };
  const {
    add: addvalue,
    edit: Editvalue,
    deleted: deletevalue,
    print: printValue,
  } = RightManagerPageValue;
  // -----------------  end permisstion page --------------------

  //--------------------------- end list photo
  const getList = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 400));
      const res = await request("bankreport", "get", {});
      if (res) {
        setList(res.API_Data_Bank);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  //-------------- page management ----------------------
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = list.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  //-------------------------------------------

  //------------- save ----------
  const [visableNewModel, setVisableNewModel] = useState(false);

  //------------------- save data to letters --------

  const onSaveData = async () => {
    var data = {
      id: UserID,
      type: type,
      bank_no: bank_no,
      date: date,
      total_eng: total_eng,
      total_kh: total_kh,
      grade: grade,
    };

    let method = "post";
    if (UserID !== "" && UserID !== null) {
      method = "put";
    }

    const res = await request("bankreport", method, data);
    setVisableNewModel(false);

    if (res) {
      getList(); // re call function list
      setLoadingSpin(true);
      await new Promise((resolve) => setTimeout(resolve, 400));
      openNotification(
        "success",
        "Success",
        method === "post" ? "Save Success" : "Update Success"
      );
    } else {
      alert("Error!");
    }

    setLoadingSpin(false);
  };

  const [DownloadReport, setDownloadReport] = useState(false);
  const onClickDownloadReport = () => {
    setDownloadReport(true);
  };

  //-------------------------
  const onCancelModel = () => {
    setVisableNewModel(false);
  };

  const onEditData = async (item) => {
    setType(item.type);
    setBankNo(item.bank_no);
    setDate(item.date);
    setTotalEng(item.total_eng);
    setTotalKH(item.total_kh);
    setGrade(item.grade);
    setUserID(item.id);
    setVisableNewModel(true);
  };
  const onOpenSaveNew = () => {
    setType("");
    setBankNo("");
    setDate("");
    setTotalEng("");
    setTotalKH("");
    setGrade("");
    setUserID("");
    setVisableNewModel(true);
  };

  useEffect(() => {
    if (dataFetched && shouldPrint) {
      printFunction();
      setDataFetched(false); // Reset dataFetched after printing
      setShouldPrint(false); // Reset shouldPrint after printing
    }
  }, [dataFetched, shouldPrint]);

  useEffect(() => {
    if (dataFetched62028 && shouldPrint62028) {
      printFunction62028();
      setDataFetched62028(false); // Reset dataFetched after printing
      setShouldPrint62028(false); // Reset shouldPrint after printing
    }
  }, [dataFetched62028, shouldPrint62028]);
  const handlePrint = async (item) => {
    setDataFetched(true);
    setItemPrint(item);
    setShouldPrint(true);
  };
  const handlePrint62028 = async (item) => {
    setDataFetched62028(true);
    setItemPrint62028(item);
    setShouldPrint62028(true);
  };
  // end print component
  const printFunction = useReactToPrint({
    content: () => componentRef.current,
  });
  const printFunction62028 = useReactToPrint({
    content: () => componentRef62028.current,
  });
  const formattedDate = (date) => {
    const [day, month, year] = date.split("/");
    return ` ${"ថ្ងៃទី" + toKhmerDay(day)} ${"ខែ" + toKhmerMonth(month)} ${
      "ឆ្នាំ" + toKhmerYear(year)
    }`;
  };

  class MyComponentToPrint extends React.Component {
    render() {
      return (
        <div className="print-content">
          {itemPrint && (
            <ComponentToPrint
              bank_no={itemPrint.bank_no}
              datereport={itemPrint.date}
              total_eng={itemPrint.total_eng}
              total_kh={itemPrint.total_kh}
              grade={itemPrint.grade}
            />
          )}
        </div>
      );
    }
  }

  class MyComponentToPrint62028 extends React.Component {
    render() {
      return (
        <div className="print-content">
          {itemPrint62028 && (
            <ComponentToPrint62028
              bank_no={itemPrint62028.bank_no}
              datereport={itemPrint62028.date}
              total_eng={itemPrint62028.total_eng}
              total_kh={itemPrint62028.total_kh}
              grade={itemPrint62028.grade}
            />
          )}
        </div>
      );
    }
  }

  const onChange_BankNO = (e) => {
    // Remove non-numeric characters and limit length to 4
    const value = e.target.value.replace(/\D/g, "").slice(0, 4);
    setBankNo(value);
  };
  const onChange_Date = (e) => {
    setDate(formateDateClient(e));
  };
  const onChange_TotalEng = (e) => {
    const value = e.target.value;
    // Remove commas from the input value before parsing
    const parsedValue = parseFloat(value.replace(/,/g, ""));
    // Check if the parsedValue is a valid number
    if (!isNaN(parsedValue)) {
      // Format the parsed value as currency using toLocaleString()
      const formattedValue = parsedValue.toLocaleString();
      // Update the state with the formatted value
      setTotalEng(formattedValue);
    } else {
      // If the input value is not a valid number, set the state to an empty string
      setTotalEng("");
    }
  };
  const onChange_TotalKH = (e) => {
    setTotalKH(e.target.value);
  };
  const onChange_Grade = (e) => {
    setGrade(e.target.value);
  };
  const onChange_Type = (value) => {
    setType(value);
  };
  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>

      <div style={boxHeader}>
        <div style={{ display: "flex", justifyItems: "center", width: "auto" }}>
          <Space>
            {boxHeaderTagText("របាយការណ៍អាណត្តិបើកប្រាក់")}
            <Input
              style={{ width: 200 }}
              className="styleTageStatusForm"
              placeholder="Search..."
              allowClear
            />
          </Space>
        </div>
        <div>
          <Space>
            <Button
              onClick={onOpenSaveNew}
              disabled={!addvalue}
              className="custom-form-item"
              type="primary"
              icon={<PlusCircleOutlined />}
            >
              បន្ថែមថ្មី
            </Button>
          </Space>{" "}
        </div>
      </div>

      <div>
        {loading ? (
          <div className="sprinCustome">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Table
            // striped
            // bordered
            // hover
            className="rounded-table"
            itemsPerPage={10}
          >
            <thead>
              <tr class="table-primary">
                <th>NO</th>
                <th>ជំពូក គណនី </th>
                <th>លេខអាណត្តិ </th>
                <th>ថ្ងែខែឆ្នាំ</th>
                <th>ទឹកប្រាក់សរុប</th>
                <th>ទឹកប្រាក់ជាអក្សរ</th>
                <th>បុរេប្រទានជុំទី</th>
                <th>សកម្មភាព</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((item, index) => (
                <tr key={index} class="table-Secondary">
                  <td>{item.id} </td>

                  <td>
                    <div>
                      <div style={{ paddingTop: 5 }}>
                        <Tag
                          style={{ fontFamily: "Khmer OS Battambang" }}
                          bordered={false}
                          color="#108ee9"
                        >
                          {item.type}
                        </Tag>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div>
                      <div style={{ paddingTop: 5 }}>
                        <Tag
                          style={{ fontFamily: "Khmer OS Battambang" }}
                          bordered={false}
                          color="#108ee9"
                        >
                          {item.bank_no}
                        </Tag>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div>
                        <Tag
                          style={{ fontFamily: "Khmer OS Battambang" }}
                          color="#f50"
                        >
                          {item.date}
                        </Tag>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div>
                      <Tag
                        style={{ fontFamily: "Khmer OS Battambang" }}
                        color="#f50"
                      >
                        {" "}
                        {item.total_eng}{" "}
                      </Tag>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div>
                        <Tag
                          style={{ fontFamily: "Khmer OS Battambang" }}
                          color="#2db7f5"
                        >
                          {item.total_kh}
                        </Tag>
                      </div>
                    </div>
                  </td>
                  <td class="word-wrap">
                    <div style={{ whiteSpace: "pre-wrap", color: "#108ee9" }}>
                      <BiCheckDouble /> ជុំទី{item.grade}
                    </div>
                  </td>

                  <td style={{ width: 100 }}>
                    <Stack gap={1} direction="horizontal">
                      <Button
                        disabled={!Editvalue}
                        onClick={() => onEditData(item)}
                        type="primary"
                        icon={<MdOutlineEdit />}
                      />

                      <div className="print-container">
                        <MyComponentToPrint
                          ref={componentRef}
                          key={index}
                          item={itemPrint}
                        />
                        <Button
                          danger
                          type="primary"
                          disabled={!printValue}
                          onClick={() => handlePrint(item)}
                          icon={<DownloadOutlined />}
                        />
                      </div>
                      <div className="print-container">
                        <MyComponentToPrint62028
                          ref={componentRef62028}
                          key={index}
                          item={itemPrint62028}
                        />
                        <Button
                          type="primary"
                          disabled={!printValue}
                          onClick={() => handlePrint62028(item)}
                          icon={<DownloadOutlined />}
                        />
                      </div>
                      <Button
                        disabled={!deletevalue}
                        type="primary"
                        icon={<MdOutlineDelete />}
                        danger
                      />
                    </Stack>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        <Pagination>
          {Array.from({ length: Math.ceil(list.length / itemsPerPage) }).map(
            (_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            )
          )}
        </Pagination>

        <Modal
          title={UserID == "" ? "បង្កើតថ្មី " : "កែប្រែ"}
          className="styleTageStatusForm"
          open={visableNewModel}
          onCancel={onCancelModel}
          width={700}
          footer={[
            <div>
              <Button onClick={onCancelModel}>Cancel</Button>
              <Button type="primary" onClick={onSaveData}>
                {UserID == "" ? "Save" : "Update"}
              </Button>
            </div>,
          ]}
        >
          <div style={{ display: "flex", justifyContent: "start" }}>
            <div style={{ width: 300 }}>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ជំពូក-គណនី-អនុគណនី ៖
              </p>

              <Select
                className="styleTageStatusForm"
                value={type}
                onChange={onChange_Type}
                style={{ width: "100%" }}
                allowClear
              >
                <Option className="styleTageStatusForm" value="62021">
                  62021
                </Option>
                <Option className="styleTageStatusForm" value="62028">
                  62028
                </Option>
              </Select>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                លេខអាណត្តិ ៖
              </p>

              <Input
                value={bank_no}
                onChange={onChange_BankNO}
                className="styleTageStatusForm"
                rows={2}
                placeholder="បញ្ចូល លេខអាណត្តិ..."
              />
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ចំនួនទឹកប្រាក់ជាលេខ ៖
              </p>

              <Input
                value={total_eng}
                onChange={onChange_TotalEng}
                className="styleTageStatusForm"
                rows={2}
                placeholder="បញ្ចូល ចំនួនទឹកប្រាក់ជាលេខ..."
              />

              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                បុរេប្រទានជុំទី ៖
              </p>

              <Input
                value={grade}
                onChange={onChange_Grade}
                className="styleTageStatusForm"
                rows={2}
                placeholder="បញ្ចូល បុរេប្រទានជុំទី...ឆ្នាំ២០២..."
              />
            </div>

            <div style={{ paddingLeft: 20, width: 300 }}>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                របាយការណ៍ថ្ងៃទីឆ្នាំ ៖
              </p>
              <MyDatePicker
                value={date}
                onChange={onChange_Date}
                width="100%"
              />

              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ចំនួនទឹកប្រាក់ជាអក្សរ (ខ្មែរ)៖
              </p>
              <Input.TextArea
                value={total_kh}
                onChange={onChange_TotalKH}
                className="styleTageStatusForm"
                rows={8}
                placeholder="បញ្ចូល ចំនួនទឹកប្រាក់ជាអក្សរ....."
              />
            </div>
          </div>
        </Modal>

        <Modal
          title="ទាញរបាយការណ៍"
          className="custom-modal" // Use the custom-modal class for responsive styles
          open={DownloadReport}
          onCancel={() => {
            setDownloadReport(false);
          }}
          footer={[
            <div>
              <Button className="custom-form-item" type="primary">
                ទាញយក (Excel)
              </Button>
            </div>,
          ]}
        >
          <div style={{ width: "520px" }}>
            <Space>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Space style={{ width: "100%" }}>
                  ច្បាប់ចាប់ពីថ្ងៃ ទី ខែ :
                  <DatePicker
                  //  onChange={handleDateChange_Start}
                  />
                </Space>
              </div>
              <div>
                <Space>
                  ដល់ថ្ងៃ ទី ខែ :
                  <DatePicker
                  //  onChange={handleDateChange_End}
                  />
                </Space>
              </div>
            </Space>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default BankingReport;
