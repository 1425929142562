import React, { useRef } from "react";
import "../../styles/Home-module.css";
import { linkPathIDCard } from "../../share/helper";
import { QRCode, Card } from "antd";
import { linkPath } from "../../share/helper";

export default class CardComponentBack extends React.Component {
  render() {
    const {
      staffname,
      deparment,
      position,
      image,
      backImage,
      profileqrcode,
      logoName,
    } = this.props;
    return (
      <div style={{ paddingTop: 80, paddingLeft: 5, width: "50%" }}>
        <div>
          <Card>
            {/* បើអ្នកចង់ទាញរូបថតចេញពី database  */}
            {/* <div>
              <Avatar className="avatar-card-profile" src={linkPath+image}   alt="Preview"  /> 
              </div> */}
            {/* <div className="text-overlaycard">
              <BiGroup />ឈ្មោះ : {staffname}<br/>
              <BiHeading />ផ្នែក :{deparment} <br/>
              <BiHandicap />តួរនាទី :{position}  
              </div> */}
            {/* <div className="qrcode-overlaycard-BackPapar">
              <QRCode
                errorLevel="H"
                size={120}
                color="rgb(18, 89, 156)"
                value="https://web.facebook.com/profile.php?id=100063585156363"
                icon={linkPath + "logoRHSTG"}
                iconSize={20}
              />
            </div>
            <img
              src={linkPathIDCard + backImage}
              className="back-paper"
              style={{
                width: 285,
                height: 407,
                objectFit: "fit",
              }}
            /> */}

            <div style={{ position: "relative", width: 285, height: 407 }}>
              {/* Background image */}
              <img
                src={linkPathIDCard + backImage}
                className="back-paper"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover", // Use cover to fit the container
                }}
              />

              {/* QR Code overlay */}
              <div
                className="qrcode-overlaycard-BackPapar"
                style={{
                  position: "absolute",
                  top: "10px", // Adjust position as needed
                  left: "10px", // Adjust position as needed
                  zIndex: 1,
                }}
              >
                <QRCode
                  errorLevel="H"
                  size={120}
                  color="#2471a3"
                  value={profileqrcode}
                  icon={linkPathIDCard + logoName}
                  iconSize={20}
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
