import React, { useRef } from "react";
import "../../styles/Home-module.css";
import { linkPath, linkPathIDCard } from "../../share/helper";
import { QRCode, Card, Avatar } from "antd";

import { BiGroup, BiHeading, BiHandicap } from "react-icons/bi";
import { IoCardOutline } from "react-icons/io5";
export default class CardComponentFront extends React.Component {
  render() {
    const {
      staffname,
      deparment,
      position,
      image,
      idnumber,
      frontImage,
      impage_profile,
      logoName,
    } = this.props;
    const style_icon = {
      color: "white",
      background: "#5499c7",
      borderRadius: 50,
      padding: 3,
    };
    return (
      <div style={{ paddingTop: 80, width: "50%" }}>
        <div>
          <Card>
            {/* បើអ្នកចង់ទាញរូបថតចេញពី database  */}
            {/* <div>
              <Avatar className="avatar-card-profile" src={linkPath+image}   alt="Preview"  /> 
              </div> */}
            <Avatar
              className="image-profile"
              src={linkPath + impage_profile}
              alt="profile"
            />

            <div className="text-overlaycard">
              <IoCardOutline size={18} style={style_icon} />
              <span style={{ marginLeft: 2, color: "#2874a6" }}>
                អត្តលេខ :{idnumber}
              </span>
              <br />
              <BiGroup size={18} style={style_icon} />
              <span style={{ marginLeft: 2, color: "#2874a6" }}>
                ឈ្មោះ : {staffname}
              </span>
              <br />
              <BiHeading size={18} style={style_icon} />
              <span style={{ marginLeft: 2, color: "#2874a6" }}>
                ផ្នែក :{deparment}
              </span>
              <br />
              <BiHandicap size={18} style={style_icon} />{" "}
              <span style={{ marginLeft: 2, color: "#2874a6" }}>
                តួរនាទី :{position}
              </span>
            </div>
            <div className="qrcode-overlaycard">
              <QRCode
                errorLevel="H"
                size={100}
                color="#2471a3"
                value={`https://cards.devcomputing.pro/${idnumber}`}
                //icon="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
                //icon={linkPathIDCard + logoName }
                icon="https://cdn.pixabay.com/animation/2023/06/13/15/13/15-13-11-358_512.gif"
                iconSize={30}
              />
            </div>
            <img
              src={linkPathIDCard + frontImage}
              style={{ width: 285, height: 407, objectFit: "fit" }}
            />
          </Card>
        </div>
      </div>
    );
  }
}
