import React, { useRef } from "react";
import "../../styles/Home-module.css";
import { linkPathReport } from "../../share/helper";
import { formateDateClient } from "../../share/helper";
import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
export default class ComponentToPrint extends React.Component {
  render() {
    const { bank_no, datereport, total_eng, total_kh, grade } = this.props;
    const spaces = " ".repeat(4); // Creates a string with 20 spaces
    const spaces1 = " ".repeat(5); // Creates a string with 20 spaces

    const province = "សុខាភិបាល";
    const rh_name = "ខេត្តស្ទឹងត្រែង";

    const number_grade = bank_no;
    const spacedNumber = number_grade
      .split("") // Split the string into an array of characters
      .join(spaces); // Insert three spaces between each character and repeat this sequence five times

    const hr = "919129 ";
    const locatin_num = "199401"; //123456
    const position_num = "70112"; // 12345
    const sponsor_num = " 11  "; // 12345
    const Activity_num = "91940619";
    const plan_num = "";
    const number_hr = hr + locatin_num;
    const convert_num_hr = number_hr
      .split("") // Split the string into an array of characters
      .join(spaces1); // Insert three spaces between each character and repeat this sequence five times
    const convert_num_po = (position_num + sponsor_num) // Split the string)
      .split("") // Split the string into an array of characters
      .join(spaces1); // Insert three spaces between each character and repeat this sequence five times

    const convert_num_po1 = (Activity_num + plan_num)
      .split("") // Split the string) // Split the string into an array of characters
      .join(spaces1); // Insert three spaces between each character and repeat this sequence five times
    const convert_num_po3 = "62028".split("").join(spaces1);
    const convert_num_po4 = "     ".split("").join(spaces1);
    const total_num = total_eng + " រ ០០";
    const total_num_inkhmer = total_kh;
    const date = datereport;
    const account_number = "1900-02341790-10";
    const bank_name = "ធនាគារអេស៊ីលីដា";
    const printpath = (value) => {
      if (value <= 3) {
        var imagebackground = linkPathReport + "backing_v11.png";
      } else {
        var imagebackground = linkPathReport + "backing_v11.png";
      }
      return imagebackground;
    };
    const toKhmerNumeral = (number) => {
      const khmerDigits = ["០", "១", "២", "៣", "៤", "៥", "៦", "៧", "៨", "៩"];
      return String(number)
        .split("")
        .map((digit) => khmerDigits[digit])
        .join("");
    };
    const styels = {
      fontSize: "10px",
      fontFamily: "Noto Serif Khmer",
      whiteSpace: "pre",
    };

    return (
      <div className="image-container-report">
        {/* <img src={printpath(3)} alt="Banking Report" className="print-image" /> */}

        <div className="text-overlay" style={{ top: 65, left: 110 }}>
          <p style={styels}>{province}</p>
        </div>
        <div className="text-overlay" style={{ top: 85, left: 110 }}>
          <p style={styels}>{rh_name}</p>
        </div>
        <div className="text-overlay" style={{ top: 85, left: 693 }}>
          <p style={styels}>{spacedNumber}</p>
        </div>
        <div className="text-overlay" style={{ top: 120, left: 50 }}>
          <p style={styels}>{convert_num_hr}</p>
        </div>
        <div className="text-overlay" style={{ top: 120, left: 300 }}>
          <p style={styels}>{convert_num_po}</p>
        </div>
        <div className="text-overlay" style={{ top: 120, left: 496 }}>
          <p style={styels}>{convert_num_po1}</p>
        </div>
        <div className="text-overlay" style={{ top: 155, left: 51 }}>
          <p style={styels}>{convert_num_po3 + " ".repeat(10) + total_num}</p>
        </div>
        <div className="text-overlay" style={{ top: 170, left: 400 }}>
          <p style={styels}>
            សំណើរចំណាយថវិកាសម្រាប់ប្រតិបត្តិការការពង្រឹងគុណភាពនិងលើកកម្ពស់{" "}
            {<br />}
            សមធម៏សុខាភិបាល {grade} របស់មន្ទីរពេទ្យបង្អែកខេត្ត {<br />}
            សក្ខីប័ត្រភ្ជាប់ {<br />}
            ដូចមានក្នុងដីការអមលិខិតយុត្តិការ
          </p>
        </div>

        <div className="text-overlay" style={{ top: 290, left: 53 }}>
          <p style={styels}>{convert_num_po4 + " ".repeat(15) + total_num}</p>
        </div>
        <div className="text-overlay" style={{ top: 287, left: 400 }}>
          <p style={styels}>{"មន្ទីរពេទ្យបង្អែកខេត្តស្ទឹងត្រែង"}</p>
        </div>
        <div className="text-overlay" style={{ top: 310, left: 190 }}>
          <p style={styels}>
            លោក កែវ ស៊ីណាត ជារដ្ឋនេយ្យករបុរេប្រធាន {<br />}
            មន្ទីរពេទ្យបង្អែកខេត្តស្ទឹងត្រែង
          </p>
        </div>
        <div className="text-overlay" style={{ top: 329, left: 455 }}>
          <p style={styels}>{account_number}</p>
        </div>
        <div className="text-overlay" style={{ top: 350, left: 530 }}>
          <p style={styels}>{bank_name}</p>
        </div>
        <div className="text-overlay" style={{ top: 392, left: 190 }}>
          <p style={styels}>{total_num_inkhmer}</p>
        </div>
        <div className="text-overlay" style={{ top: 460, left: 650 }}>
          <p style={styels}> {date}</p>
        </div>
        <div className="text-overlay" style={{ top: 515, left: 390 }}>
          <p style={styels}>{total_num}</p>
        </div>
        <div className="text-overlay" style={{ top: 546, left: 390 }}>
          <p style={styels}>{"គ្មាន"}</p>
        </div>
        <div className="text-overlay" style={{ top: 577, left: 390 }}>
          <p style={styels}>{total_num}</p>
        </div>
        <div className="text-overlay" style={{ top: 622, left: 110 }}>
          <p style={styels}>{province}</p>
        </div>
        <div className="text-overlay" style={{ top: 640, left: 110 }}>
          <p style={styels}>{rh_name}</p>
        </div>
        <div className="text-overlay" style={{ top: 619, left: 694 }}>
          <p style={styels}>{spacedNumber}</p>
        </div>
        <div className="text-overlay" style={{ top: 638, left: 650 }}>
          <p style={styels}>{date}</p>
        </div>

        <div className="text-overlay" style={{ top: 670, left: 50 }}>
          <p style={styels}>{convert_num_hr}</p>
        </div>
        <div className="text-overlay" style={{ top: 670, left: 298 }}>
          <p style={styels}>{convert_num_po}</p>
        </div>
        <div className="text-overlay" style={{ top: 670, left: 494 }}>
          <p style={styels}>{convert_num_po1}</p>
        </div>

        <div className="text-overlay" style={{ top: 704, left: 50 }}>
          <p style={styels}>{convert_num_po3 + " ".repeat(15) + total_num}</p>
        </div>
        <div className="text-overlay" style={{ top: 725, left: 400 }}>
          <p style={styels}>
            សំណើរទូទាត់សងវេជ្ជទេយ្យបុរេប្រទានជុំទី{grade} {<br />}
            របស់មន្ទីរពេទ្យបង្អែកខេត្ត {<br />}
            សក្ខីប័ត្រភ្ជាប់ {<br />}
            ដូចមានក្នុងដីការអមលិខិតយុត្តិការ
          </p>
        </div>
        <div className="text-overlay" style={{ top: 840, left: 53 }}>
          <p style={styels}>{convert_num_po4 + " ".repeat(15) + total_num}</p>
        </div>
        <div className="text-overlay" style={{ top: 838, left: 400 }}>
          <p style={styels}>{"មន្ទីរពេទ្យបង្អែកខេត្តស្ទឹងត្រែង"}</p>
        </div>
        <div className="text-overlay" style={{ top: 860, left: 190 }}>
          <p style={styels}>{total_num_inkhmer}</p>
        </div>

        <div className="text-overlay" style={{ top: 917, left: 370 }}>
          <p style={styels}>{total_num}</p>
        </div>
        <div className="text-overlay" style={{ top: 945, left: 370 }}>
          <p style={styels}>{"គ្មាន"}</p>
        </div>
        <div className="text-overlay" style={{ top: 970, left: 370 }}>
          <p style={styels}>{total_num}</p>
        </div>
        <div className="text-overlay" style={{ top: 937, left: 565 }}>
          <p style={styels}>{account_number}</p>
        </div>
        <div className="text-overlay" style={{ top: 960, left: 610 }}>
          <p style={styels}>{bank_name}</p>
        </div>
      </div>
    );
  }
}
