import { useState, useEffect } from "react";
import { getCurrentUser } from "./getProfile";
import { request } from "./request";
import { linkPathIDCard } from "./helper";

const useOrganizationImage = () => {
  const [imagePath, setImagePath] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      const getProfile = getCurrentUser();
      const param = new URLSearchParams({ org_code: getProfile.org_code });

      try {
        const res = await request(
          "settingorgidcard?" + param.toString(),
          "get",
          {}
        );
        if (res && res.list_api) {
          const logo_org = res.list_api.find((item) => item.param === "logo");
          if (logo_org) {
            setImagePath(linkPathIDCard + logo_org.imagelink);
          }
        }
      } catch (error) {
        console.error("Failed to fetch organization images:", error);
      }
    };

    fetchImage();
  }, []); // Run once on mount

  return imagePath;
};

export default useOrganizationImage;
