import React from "react";
import { Card, Typography, Avatar, Tag, Divider } from "antd";
import {
  UserOutlined,
  WomanOutlined,
  ManOutlined,
  CheckCircleOutlined,
  PauseCircleOutlined,
  BookOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const DepartmenList = ({ list }) => {
  return (
    <div
      style={{
        margin: 5,
        paddingTop: 3,
        paddingLeft: 10,
        paddingBottom: 30,
        paddingRight: 10,

        height: "auto",
        borderRadius: 10,
        textAlign: "center",
        border: "0.5px solid gray",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Divider
        variant="dotted"
        style={{
          borderColor: "#7cb305",
          fontWeight: "bold",
          fontFamily: "",
          fontFamily: "Khmer OS Battambang",
        }}
      >
        {list.department_name}
      </Divider>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "4px",
          fontWeight: "bold",
        }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
          <Tag
            style={{
              padding: 3,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: "40px",
              fontFamily: "Khmer OS Battambang",
            }}
            color={getRandomColor()}
          >
            <UserOutlined /> សរុប: {list.total_count}
          </Tag>
          <Tag
            style={{
              padding: 3,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: "40px",
              fontFamily: "Khmer OS Battambang",
            }}
            color={getRandomColor()}
          >
            <WomanOutlined /> ស្រី: {list.female_count}
          </Tag>
          <Tag
            style={{
              padding: 3,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: "40px",
              fontFamily: "Khmer OS Battambang",
            }}
            color={getRandomColor()}
          >
            <ManOutlined /> ប្រុស: {list.male_count}
          </Tag>
          <Tag
            style={{
              padding: 3,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: "40px",
              fontFamily: "Khmer OS Battambang",
            }}
            color={getRandomColor()}
          >
            <CheckCircleOutlined /> កំពុងធ្វើការ: {list.active_staff}
          </Tag>
          <Tag
            style={{
              padding: 3,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: "40px",
              fontFamily: "Khmer OS Battambang",
            }}
            color={getRandomColor()}
          >
            <PauseCircleOutlined /> ព្យួរ: {list.inactive_staff}
          </Tag>
          <Tag
            style={{
              padding: 3,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: "40px",
              fontFamily: "Khmer OS Battambang",
            }}
            color={getRandomColor()}
          >
            <BookOutlined /> បណ្តុះបណ្តាល: {list.pending_learning}
          </Tag>
          <Tag
            style={{
              padding: 3,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: "40px",
              fontFamily: "Khmer OS Battambang",
            }}
            color={getRandomColor()}
          >
            <CloseCircleOutlined /> ឈប់: {list.stopped_work}
          </Tag>
        </div>
      </div>
    </div>
  );
};

export default DepartmenList;
