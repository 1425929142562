import { Button, Select, Space } from "antd";
import "../../styles/Home-module.css";
import { useEffect, useState } from "react";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { ImLocation2 } from "react-icons/im";
import { request } from "../../share/request";
import { PlusCircleOutlined } from "@ant-design/icons";
import { openNotification } from "../../share/message";
import axios from "axios";
import Loading from "../../share/Loading";
import GetLoading from "../../share/getLoading";
const UserAddress = ({ idNumber, refreshFlag, onRefreshComplete }) => {
  const { Option } = Select;
  //  for ក. អាស័យដ្ឋានបច្ចុប្បន្ន
  const [loading, setLoading] = useState(false);

  const [listItem, setListItem] = useState([]);
  const [list_province, setListProvince] = useState([]);
  const [list_city, setListCity] = useState([]);
  const [list_com, setListCom] = useState([]);
  const [list_village, setListVillage] = useState([]);
  const [id, setId] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [com, setCom] = useState("");
  const [village, setVillage] = useState("");
  const [proCode, setProCode] = useState("");
  const [cityCode, setCityCode] = useState("");
  const [comCode, setComCode] = useState("");
  const [villageCode, setVillageCode] = useState("");

  // --- អាស័យដ្ឋាន(ទីកន្លែងកំណើត)
  const [listItem_pob, setListItem_pob] = useState([]);
  const [list_province_pob, setListProvince_pob] = useState([]);
  const [list_city_pob, setListCity_pob] = useState([]);
  const [list_com_pob, setListCom_pob] = useState([]);
  const [list_village_pob, setListVillage_pob] = useState([]);
  const [id_pob, setId_pob] = useState("");
  const [province_pob, setProvince_pob] = useState("");
  const [city_pob, setCity_pob] = useState("");
  const [com_pob, setCom_pob] = useState("");
  const [village_pob, setVillage_pob] = useState("");
  const [proCode_pob, setProCode_pob] = useState("");
  const [cityCode_pob, setCityCode_pob] = useState("");
  const [comCode_pob, setComCode_pob] = useState("");
  const [villageCode_pob, setVillageCode_pob] = useState("");

  useEffect(() => {
    console.log("province_pob :", province_pob.length);
    getlist();
    getlist_pob();
    console.log("idNumber:", idNumber);
    if (!idNumber) return;
    if (refreshFlag) {
      console.log("onRefreshComplete", refreshFlag);
      console.log("onRefreshComplete", onRefreshComplete);
      getlist();
      getlist_pob();
    }
  }, [idNumber, refreshFlag, onRefreshComplete]);

  const getlist = async () => {
    if (!idNumber) return; // Ensure idNumber is not null or undefined
    try {
      console.log("this is staff idnumber : " + idNumber);
      const res = await request("useraddresspro/" + idNumber, "get", {});
      console.log("API response:", res);

      if ((res && res.API_Server_listProvince) || res.API_Server_AddressByID) {
        setListProvince(res.API_Server_listProvince);
        setListItem(res.API_Server_AddressByID);

        const proNames1 = listItem.map((value) => value.province_name_khmer);
        const proCode1 = listItem.map((value) => value.province_id);
        const cityNames1 = listItem.map((value) => value.city_name_khmer);
        const cityCode1 = listItem.map((value) => value.city_id);
        const comNames1 = listItem.map((value) => value.com_name_khmer);
        const comCode1 = listItem.map((value) => value.commune_id);
        const villageNames1 = listItem.map((value) => value.village_name_khmer);
        const villageCode1 = listItem.map((value) => value.village_id);
        const id1 = listItem.map((value) => value.id);
        setProvince(proNames1);
        setCity(cityNames1);
        setCom(comNames1);
        setVillage(villageNames1);
        setProCode(proCode1);
        setCityCode(cityCode1);
        setComCode(comCode1);
        setVillageCode(villageCode1);
        setId(id1);
      } else {
        console.error("Invalid API response:", res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      onRefreshComplete();
    }
  };

  const getlist_pob = async () => {
    if (!idNumber) return; // Ensure idNumber is not null or undefined
    try {
      console.log("this is staff idnumber : " + idNumber);
      const res = await request("useraddresspropob/" + idNumber, "get", {});
      console.log("API response:", res);

      if ((res && res.API_Server_listProvince) || res.API_Server_AddressByID) {
        setListProvince_pob(res.API_Server_listProvince);
        setListItem_pob(res.API_Server_AddressByID);

        const proNames_pob1 = listItem_pob.map(
          (value) => value.province_name_khmer
        );
        const proCode_pob1 = listItem_pob.map((value) => value.province_id);
        const cityNames_pob1 = listItem_pob.map(
          (value) => value.city_name_khmer
        );
        const cityCode_pob1 = listItem_pob.map((value) => value.city_id);
        const comNames_pob1 = listItem_pob.map((value) => value.com_name_khmer);
        const comCode_pob1 = listItem_pob.map((value) => value.commune_id);
        const villageNames_pob1 = listItem_pob.map(
          (value) => value.village_name_khmer
        );
        const villageCode_pob1 = listItem_pob.map((value) => value.village_id);
        const id1 = listItem_pob.map((value) => value.id);
        setProvince_pob(proNames_pob1);
        setProCode_pob(proCode_pob1);
        setCity_pob(cityNames_pob1);
        setCityCode_pob(cityCode_pob1);
        setCom_pob(comNames_pob1);
        setComCode_pob(comCode_pob1);
        setVillage_pob(villageNames_pob1);
        setVillageCode_pob(villageCode_pob1);
        setId_pob(id1);
      } else {
        console.error("Invalid API response:", res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      onRefreshComplete();
    }
  };
  // ---------------  on chang and on click for ក. អាស័យដ្ឋានបច្ចុប្បន្ន
  const onClickProvince = async (value) => {
    console.log("province code is : ", value);
    const res = await request("useraddresspro/" + proCode, "get", {});
    console.log("API response:", res);
    if (res && res.API_Server_listProvince) {
      setListProvince(res.API_Server_listProvince);
    }
  };
  const onChangeProvince = async (value) => {
    setProvince(value);
    setProCode(value);
    setCity("");
    setCom("");
    setVillage("");
    setCityCode("");
    setComCode("");
    setVillageCode("");

    const res = await request("useraddresscity/" + value, "get", {});
    console.log("API response:", res);
    if (res && res.API_Server_listCity) {
      setListCity(res.API_Server_listCity);
    }
  };
  const onClickCity = async () => {
    console.log("province code is : ", proCode);
    const res = await request("useraddresscity/" + proCode, "get", {});
    console.log("API response:", res);
    if (res && res.API_Server_listCity) {
      setListCity(res.API_Server_listCity);
    }
  };
  const onChangeCity = async (value) => {
    setCity(value);
    setCityCode(value);
    setCom("");
    setVillage("");
    const res = await request("useraddresscom/" + value, "get", {});
    console.log("API response:", res);
    if (res && res.API_Server_listCom) {
      setListCom(res.API_Server_listCom);
    }
  };
  const onClickCom = async () => {
    console.log("com code is : ", comCode);
    const res = await request("useraddresscom/" + cityCode, "get", {});
    console.log("API response:", res);
    if (res && res.API_Server_listCom) {
      setListCom(res.API_Server_listCom);
    }
  };
  const onChangeCom = async (value) => {
    setCom(value);
    setComCode(value);
    setVillage("");
    console.log("com code is : ", value);
    const res = await request("useraddressvillage/" + value, "get", {});
    console.log("API response:", res);
    if (res && res.API_Server_listVillage) {
      setListVillage(res.API_Server_listVillage);
    }
  };

  const onClickVillage = async () => {
    const res = await request("useraddressvillage/" + comCode, "get", {});
    console.log("API response:", res);
    if (res && res.API_Server_listVillage) {
      setListVillage(res.API_Server_listVillage);
    }
  };
  const onChangeVillage = (value) => {
    setVillage(value);
  };
  // ---------------  on chang and on click for ខ. អាស័យដ្ឋាន(ទីកន្លែងកំណើត)

  const onChangeProvince_pob = async (value) => {
    setProvince_pob(value);
    setProCode_pob(value);
    setCity_pob("");
    setCom_pob("");
    setVillage_pob("");

    setCityCode_pob("");
    setComCode_pob("");
    setVillageCode_pob("");

    console.log("province code is : ", value);
    const res = await request("useraddresscity/" + value, "get", {});

    console.log("API response:", res);
    if (res && res.API_Server_listCity) {
      setListCity_pob(res.API_Server_listCity);
    }
  };
  const onClickCity_pob = async () => {
    console.log("province code is : ", proCode_pob);
    const res = await request("useraddresscity/" + proCode_pob, "get", {});
    console.log("API response:", res);
    if (res && res.API_Server_listCity) {
      setListCity_pob(res.API_Server_listCity);
    }
  };
  const onChangeCity_pob = async (value) => {
    setCity_pob(value);
    setCityCode_pob(value);
    setCom_pob("");
    setVillage_pob("");
    const res = await request("useraddresscom/" + value, "get", {});
    console.log("API response:", res);
    if (res && res.API_Server_listCom) {
      setListCom_pob(res.API_Server_listCom);
    }
  };
  const onClickCom_pob = async () => {
    console.log("com code is : ", comCode);
    const res = await request("useraddresscom/" + cityCode_pob, "get", {});
    console.log("API response:", res);
    if (res && res.API_Server_listCom) {
      setListCom_pob(res.API_Server_listCom);
    }
  };

  const onChangeCom_pob = async (value) => {
    setCom_pob(value);
    setComCode_pob(value);
    setVillage_pob("");
    console.log("com code is : ", value);
    const res = await request("useraddressvillage/" + value, "get", {});
    console.log("API response:", res);
    if (res && res.API_Server_listVillage) {
      setListVillage_pob(res.API_Server_listVillage);
    }
  };

  const onClickVillage_pob = async () => {
    const res = await request("useraddressvillage/" + comCode_pob, "get", {});
    console.log("API response:", res);
    if (res && res.API_Server_listVillage) {
      setListVillage_pob(res.API_Server_listVillage);
    }
  };
  const onChangeVillage_pob = (value) => {
    setVillage_pob(value);
  };

  //--------- save
  const handleSave = async () => {
    try {
      setLoading(true);
      const records = {
        staff_id: idNumber,
        province_id: proCode,
        city_id: cityCode,
        commune_id: comCode,
        village_id: village,
        create_by: "Admin",
        status: 1,
        id: id,
      };

      const method = listItem.length === 0 ? "post" : "put";
      const res = await request("useraddress", method, records);
      if (res) {
        openNotification(
          "success",
          "Success",
          listItem.length !== 0
            ? "Data updated successfully "
            : "Insert  successfully "
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSave_pob = async () => {
    try {
      setLoading(true);
      const records = {
        staff_id: idNumber,
        province_id: proCode_pob,
        city_id: cityCode_pob,
        commune_id: comCode_pob,
        village_id: village_pob,
        create_by: "Admin",
        status: 1,
        id: id_pob,
      };

      const method = listItem_pob.length === 0 ? "post" : "put";
      const res = await request("useraddresspob", method, records);
      if (res) {
        openNotification(
          "success",
          "Success",
          listItem_pob.length !== 0
            ? "Data updated successfully "
            : "Insert  successfully "
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <div>
          <div className="sprinSave">
            <GetLoading />
          </div>
        </div>
      ) : null}

      <div className="span-styels">
        <ImLocation2 />
        ក. អាស័យដ្ឋានបច្ចុប្បន្ន
      </div>
      <div
        style={{ display: "flex", alignItems: "flex-start" }}
        className="span-styels"
      >
        <Space>
          <div>
            <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
              ខេត្ត / Province
            </p>
            <Select
              className="styleTageStatusForm"
              placeholder="Select an Province"
              style={{ width: 250 }}
              onChange={onChangeProvince}
              onClick={onClickProvince}
              value={province}
            >
              {list_province.map((option) => (
                <Option
                  className="styleTageStatusForm"
                  key={option.province_code}
                  value={option.province_code}
                >
                  {option?.province_name_khmer || null}{" "}
                  {/* Replace with the field from your MySQL table */}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
              ក្រុង សង្កាត់ ស្រុក / City
            </p>
            <Select
              className="styleTageStatusForm"
              placeholder="Select an Commune"
              style={{ width: 250 }}
              onChange={onChangeCity}
              onClick={onClickCity}
              value={city}
            >
              {list_city.map((option) => (
                <Option
                  className="styleTageStatusForm"
                  key={option.city_code}
                  value={option.city_code}
                >
                  {option?.city_name_khmer || ""}{" "}
                  {/* Replace with the field from your MySQL table */}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
              ឃុំ / Commune
            </p>
            <Select
              className="styleTageStatusForm"
              placeholder="Select an Commune"
              style={{ width: 250 }}
              onChange={onChangeCom}
              onClick={onClickCom}
              value={com}
            >
              {list_com.map((option) => (
                <Option
                  className="styleTageStatusForm"
                  key={option.com_code}
                  value={option.com_code}
                >
                  {option?.com_name_khmer || null}{" "}
                  {/* Replace with the field from your MySQL table */}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
              ភូមិ / Village
            </p>
            <Select
              className="styleTageStatusForm"
              placeholder="Select an Commune"
              style={{ width: 250 }}
              onChange={onChangeVillage}
              onClick={onClickVillage}
              value={village}
            >
              {list_village.map((option) => (
                <Option
                  className="styleTageStatusForm"
                  key={option.village_code}
                  value={option.village_code}
                >
                  {option?.village_name_khmer || null}{" "}
                  {/* Replace with the field from your MySQL table */}
                </Option>
              ))}
            </Select>
          </div>
          <div style={{ marginTop: 40 }}>
            <Button
              className="buttonstylekhmer"
              type="primary"
              style={{
                display:
                  village === "" || province.length === 0 ? "none" : "block",
              }}
              onClick={handleSave}
              icon={<PlusCircleOutlined />}
            />
          </div>
        </Space>
      </div>
      <div className="span-styels" style={{ paddingTop: 20 }}>
        <ImLocation2 />
        ខ. អាស័យដ្ឋាន(ទីកន្លែងកំណើត)
      </div>

      <div
        style={{ display: "flex", alignItems: "flex-start" }}
        className="span-styels"
      >
        <Space>
          <div>
            <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
              ខេត្ត / Province
            </p>
            <Select
              className="styleTageStatusForm"
              placeholder="Select an Province"
              style={{ width: 250 }}
              onClick={onClickProvince}
              onChange={onChangeProvince_pob}
              value={province_pob}
            >
              {list_province.map((option) => (
                <Option
                  className="styleTageStatusForm"
                  key={option.province_code}
                  value={option.province_code}
                >
                  {option.province_name_khmer}
                  {/* Replace with the field from your MySQL table */}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
              ក្រុង សង្កាត់ ស្រុក / City
            </p>
            <Select
              className="styleTageStatusForm"
              placeholder="Select an Commune"
              style={{ width: 250 }}
              onChange={onChangeCity_pob}
              onClick={onClickCity_pob}
              value={city_pob}
            >
              {list_city_pob.map((option) => (
                <Option
                  className="styleTageStatusForm"
                  key={option.city_code}
                  value={option.city_code}
                >
                  {option.city_name_khmer}{" "}
                  {/* Replace with the field from your MySQL table */}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
              ឃុំ / Commune
            </p>
            <Select
              className="styleTageStatusForm"
              placeholder="Select an Commune"
              style={{ width: 250 }}
              onChange={onChangeCom_pob}
              onClick={onClickCom_pob}
              value={com_pob}
            >
              {list_com_pob.map((option) => (
                <Option
                  className="styleTageStatusForm"
                  key={option.com_code}
                  value={option.com_code}
                >
                  {option.com_name_khmer}{" "}
                  {/* Replace with the field from your MySQL table */}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
              ភូមិ / Village
            </p>
            <Select
              className="styleTageStatusForm"
              placeholder="Select an Commune"
              style={{ width: 250 }}
              onChange={onChangeVillage_pob}
              onClick={onClickVillage_pob}
              value={village_pob}
            >
              {list_village_pob.map((option) => (
                <Option
                  className="styleTageStatusForm"
                  key={option.village_code}
                  value={option.village_code}
                >
                  {option.village_name_khmer}{" "}
                  {/* Replace with the field from your MySQL table */}
                </Option>
              ))}
            </Select>
          </div>
          <div style={{ marginTop: 40 }}>
            <Button
              className="buttonstylekhmer"
              type="primary"
              style={{
                display:
                  village_pob === "" || province_pob.length === 0
                    ? "none"
                    : "block",
              }}
              onClick={handleSave_pob}
              icon={<PlusCircleOutlined />}
            />
          </div>
        </Space>
      </div>
    </div>
  );
};

export default UserAddress;
