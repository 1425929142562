import { useEffect, useState } from "react";
import {
  DownloadOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import {
  Modal,
  message,
  Upload,
  Button,
  Form,
  Input,
  Item,
  UserOutlined,
  Select,
  Space,
  Tag,
  Checkbox,
  Spin,
  Popconfirm,
  DatePicker,
} from "antd";
import "../../styles/Home-module.css";
import styles from "../../styles/Font.css";
import { request } from "../../share/request";
import Table from "react-bootstrap/Table";
import { Stack } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { openNotification } from "../../share/message";
import { Circles } from "react-loader-spinner";
import { getPermission } from "../../share/getProfile";
import {
  getLettersContent,
  getCarActivityContent,
  getStatusContent,
  getTageStyle,
} from "../../share/getStatusContent";
import {
  formateDateClient,
  formateDateSystem,
  linkPathPhotoDocument,
} from "../../share/helper";
import { MdOutlineEdit, MdOutlineDelete } from "react-icons/md";
import { HiOutlineDownload } from "react-icons/hi";
import { FaRegEye } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";
import MyDatePicker from "../../share/MyDatePicker";
import { BsDownload } from "react-icons/bs";
import { PlusOutlined } from "@ant-design/icons";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";
const { Option } = Select;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const CarActivityPage = () => {
  const [totalCount, setTotalCount] = useState("");
  const [listdocument, setlistdocument] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [list, setList] = useState([]);
  const [listJoiner, setListJoiner] = useState([]);
  const [UserID, setUserID] = useState("");
  const [UserFile_ID, setUserFile_ID] = useState("");
  const [UserLetters_type, setUserLetters_Type] = useState("");
  const [UserLetters_FromTo, setUserLetters_FromTo] = useState("");
  const [UserLettter_number, setUserLettter_number] = useState("");
  const [UserLettter_Date, setUserLettter_Date] = useState("");
  const [UserLetters_Note, setUserLeters_Note] = useState();
  const [UserLettter_Description, setUserLettter_Description] = useState("");
  const [UserLetters_DateStart, setUserLetters_DateStart] = useState("");
  const [UserLetters_DateEnd, setUserLetters_DateEnd] = useState("");
  const [UserLetters_location, setUserLetters_location] = useState("");
  const [UserLetters_PrepareBy, setUserLetters_PrepareBy] = useState("");
  const [UserLetters_SupportBy, setUserLetters_SupportBy] = useState("");
  const [UserLetters_StaffCodeJoin, setUserLetters_StaffCodeJoin] = useState();

  const [imageDeletePath, setImageDeletePath] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingSpin, setLoadingSpin] = useState(false);

  // -----------------  permisstion page --------------------
  const userPermission = getPermission();
  const CarActivityPageValue = userPermission.find(
    (permission) => permission.pagename === "caractivity"
  ) || { add: 0, edit: 0, deleted: 0, print: 0 };
  const {
    add: addvalue,
    edit: Editvalue,
    deleted: deletevalue,
    print: printValue,
  } = CarActivityPageValue;
  // -----------------  end permisstion page --------------------
  const [previewFile, setPreviewFile] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    setVisableModelPreviewFile(false);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    console.log("link donwload " + previewImage);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const handleRemove = async (file) => {
    setImageDeletePath(file.name);
    console.log("Path delete" + imageDeletePath);
    const res = await request("caractivity/" + file.uid, "delete", {
      imageDeletePath,
    });
    if (res) {
      setFileList((prevFileList) =>
        prevFileList.filter((item) => item.uid !== file.uid)
      );
      console.log("delete file success");
    }
  };

  //--------------------------- end list photo
  const getList = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 400));
      const res = await request("caractivity", "get", {});
      if (res) {
        setList(res.API_Server_ListLetters[1]);
        setListJoiner(res.API_Server_ListJoiner);
        console.log("list of LETTER" + res.API_Server_ListJoiner);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    console.log("this path of delete file  ", imageDeletePath);
  }, [imageDeletePath]);

  //---------------- get Joiner------------

  const DataJoiner = listJoiner.map((staff, index) => ({
    label: staff.staffname_kh1,
    value: staff.staffname_kh1, // Using idnumber as the value
  }));
  const [StaffName, setStaffName] = useState("");
  const onChange_UserLetter_Staffjoin = (value) => {
    // Log the selected value(s) for debugging
    console.log("Selected value(s):", value);

    // Filter the DataJoiner array to get only the options corresponding to the selected value(s)
    const selectedOptions = DataJoiner.filter((option) =>
      value.includes(option.value)
    );

    // Extract idnumbers from the filtered options
    const selectedIdNumbers = selectedOptions.map((option) => option.value);

    // Join the idnumbers into a single string separated by commas
    const staffNamesString = selectedIdNumbers.join(", ");

    // Update state or perform any necessary action with the staffNamesString
    setStaffName(staffNamesString);

    // Log the updated state for debugging
    console.log("Selected staff names:", StaffName);

    setUserLetters_StaffCodeJoin(value);
  };

  //-------------- page management ----------------------
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = list.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  //-------------------------------------------
  const [car_name, setCarusing] = useState("");
  const [listcar, setListCar] = useState([]);
  const getListCar = async () => {
    const id = 1;
    const res = await request("caractivity/" + id, "get", {});
    console.log("API response:", res);

    if (res) {
      setListCar(res.API_Server_CarUsing);
    }
  };
  // ----------------- open modle ----------
  const [visableModelPreviewFile, setVisableModelPreviewFile] = useState(false);
  const onOpenModelPreviewFile = async (item) => {
    setUserID(item.id);
    const res = await request("caractivity/" + item.file_id, "get", {});
    console.log("API response:", res);

    if (res) {
      setlistdocument(res.API_Server_ListDocument || []);
      setTotalCount(res.API_Server_Total || 0);

      const fileList = res.API_Server_ListDocument.map((docItem, index) => ({
        uid: docItem.id,
        name: docItem.file_name,
        url: linkPathPhotoDocument + docItem.file_name,
        status: "done",
      }));

      setFileList(fileList);
    } else {
      setFileList([]);
    }
    setVisableModelPreviewFile(true);
  };
  const onCLoseModelDelete = () => {
    // setlistdocument([])
    // setFileList([])
    setVisableModelPreviewFile(false);
  };

  // --------------- end model delete--------

  //---------------------------

  //------------- save ----------
  const [visableNewModel, setVisableNewModel] = useState(false);
  const onOpenSaveNew = () => {
    getListCar();
    const today = new Date();
    // Formatting the date if needed (e.g., "YYYY-MM-DD")
    const formattedDate = today.toISOString().split("T")[0];
    setFileList(null);
    setUserID("");
    setTotalCount("");
    setStaffName("");
    setFileList([]);
    setlistdocument([]);
    setPreviewImage("");
    setPreviewTitle("");
    setUserLetters_Type("ចេញ");
    setUserLetters_FromTo("");
    setUserLettter_number("");
    setUserLettter_Date(formattedDate);
    setUserLeters_Note("លិខិតឧទ្ទេសនាមបេសកកម្ម");
    setUserLetters_StaffCodeJoin("");
    setUserLettter_Description("បញ្ចូន អ្នកជំងឺ");
    setUserLetters_DateStart(formattedDate);
    setUserLetters_DateEnd(formattedDate);
    setUserLetters_location("");
    setUserLetters_PrepareBy("មន្ទីរពេទ្យខេត្តបង្អែកខេត្តស្ទឹងត្រែង");
    setUserLetters_SupportBy("ថវិការដ្ឋ");
    setCarusing("");
    setVisableNewModel(true);
    setEnable(true);
    setDisable(false);
  };

  //-------------   enable and disable ---------------

  const [enable, setEnable] = useState(false);
  const [disable, setDisable] = useState(false);
  const handleCheckboxChange_Enable = (e) => {
    setEnable(e.target.checked);
    setDisable(false);
  };
  const handleCheckboxChange_Diable = (e) => {
    setDisable(e.target.checked);
    setEnable(false);
  };
  //--------------------------------------------

  //------------------- save data to letters --------

  const onSaveData = async () => {
    const formData = new FormData();
    formData.append("id", UserID);
    formData.append("transfer_name", UserLetters_type);
    formData.append("letters_from", UserLetters_FromTo);
    formData.append("letters_number", UserLettter_number);
    formData.append("letters_date", UserLettter_Date);
    formData.append("letters_type", UserLetters_Note);
    formData.append("invite_staff", StaffName);
    formData.append("join_des", UserLettter_Description);
    formData.append("joindate_start", UserLetters_DateStart);
    formData.append("joindate_end", UserLetters_DateEnd);
    formData.append("location", UserLetters_location);
    formData.append("prepare_by", UserLetters_PrepareBy);
    formData.append("support_by", UserLetters_SupportBy);
    formData.append("create_By", "admin update");
    formData.append("status", enable ? 1 : disable ? 0 : 0);
    formData.append("file_id", update_file_id);
    formData.append("other", "caractivity");
    formData.append("carusing", car_name);

    fileList.forEach((file) => {
      if (file.originFileObj instanceof Blob) {
        formData.append(
          UserID !== "" ? "upload_file_update" : "upload_file",
          file.originFileObj,
          file.name
        );
      } else {
        console.error("File object is not a Blob:", file);
      }
    });
    // Append each file to FormData
    if (UserID == "") {
      const res = request("caractivity", "post", formData);
      setVisableNewModel(false);
      if (res) {
        getList(); // re call function list
        setLoadingSpin(true);
        await new Promise((resolve) => setTimeout(resolve, 400));
        openNotification("success", "Success", "Save  Success");
      } else {
        alert("Error!");
      }
      setLoadingSpin(false);
    } else {
      const res = request("caractivity", "put", formData);
      setVisableNewModel(false);
      if (res) {
        getList(); // re call function list
        setLoadingSpin(true);
        await new Promise((resolve) => setTimeout(resolve, 400));
        openNotification("success", "Success", "Update  Success");
      } else {
        alert("Error!");
      }
      setLoadingSpin(false);
    }
  };
  const [DownloadReport, setDownloadReport] = useState(false);
  const onClickDownloadReport = () => {
    setDownloadReport(true);
  };

  //-------------------------
  const onCancelModel = () => {
    setFileList(null);
    setlistdocument(null);
    setFileList([]);
    setlistdocument([]);

    setVisableNewModel(false);
  };
  const onCanceDelete = () => {
    setVisableModelPreviewFile(false);
  };

  const onChange_UserLetters_Type = (event) => {
    setUserLetters_Type(event);
    console.log(UserLetters_type);
  };
  const onChange_UserLetters_FromTo = (event) => {
    setUserLetters_FromTo(event.target.value);
  };
  const onChange_UserLettter_number = (event) => {
    setUserLettter_number(event.target.value);
  };
  const onChange_UserLetters_Date = (value) => {
    setUserLettter_Date(formateDateSystem(value));
  };
  const onChange_UserLetters_Note = (value) => {
    setUserLeters_Note(value);
  };
  const onChange_UserLettter_Description = (event) => {
    setUserLettter_Description(event.target.value);
  };
  const onChange_UserLetters_DateStart = (value) => {
    setUserLetters_DateStart(formateDateSystem(value));
  };
  const onChange_UserLetters_DateEnd = (value) => {
    setUserLetters_DateEnd(formateDateSystem(value));
  };
  const onChange_UserLetters_location = (event) => {
    setUserLetters_location(event.target.value);
  };
  const onChange_UserLetters_PrepareBy = (event) => {
    setUserLetters_PrepareBy(event.target.value);
  };
  const onChange_UserLetters_SupportBy = (event) => {
    setUserLetters_SupportBy(event.target.value);
  };
  useEffect(() => {
    console.log("user file id " + UserFile_ID);
  }, [UserFile_ID]);
  useEffect(() => {
    console.log("File list updated:", fileList);
  }, [fileList, listdocument]);
  const [update_file_id, setUpdateFileID] = useState("");
  const [SaveFile, setSaveFile] = useState();
  const onEditData = async (item) => {
    try {
      setUserFile_ID(item.file_id);
      console.log("user file id " + UserFile_ID);

      const res = await request("caractivity/" + item.file_id, "get", {});
      console.log("API response:", res);

      if (res) {
        setlistdocument(res.API_Server_ListDocument || []);
        console.log("List document:", res.API_Server_ListDocument);

        setTotalCount(res.API_Server_Total || 0);
        console.log("Total count:", res.API_Server_Total);
        setListCar(res.API_Server_CarUsing);
        const fileList = res.API_Server_ListDocument.map((docItem, index) => ({
          uid: docItem.id,
          name: docItem.file_name,
          url: linkPathPhotoDocument + docItem.file_name,
          status: "done",
        }));
        console.log("Upload", fileList);
        setFileList(fileList);
      } else {
        console.log("No documents found.");
        setFileList([]);
      }

      setStaffName(item.invite_staff);
      setUserID(item.id);
      const arrayValueForSelect = item.invite_staff
        .split(",")
        .map((value) => value.trim());

      setUserLetters_Type(item.transfer_name);
      setUserLetters_FromTo(item.letters_from);
      setUserLettter_number(item.letters_number);
      setUserLettter_Date(formateDateSystem(item.letters_date));
      setUserLeters_Note(item.letters_type);
      setUserLetters_StaffCodeJoin(arrayValueForSelect);
      setUserLettter_Description(item.join_des);
      setUserLetters_DateStart(formateDateSystem(item.joindate_start));
      setUserLetters_DateEnd(formateDateSystem(item.joindate_end));
      setUserLetters_location(item.location);
      setUserLetters_PrepareBy(item.prepare_by);
      setUserLetters_SupportBy(item.support_by);
      setUpdateFileID(item.file_id);
      setCarusing(item.carusing);
      setVisableNewModel(true);

      if (item.status === 1) {
        setEnable(true);
        setDisable(false);
      } else {
        setEnable(false);
        setDisable(true);
      }
    } catch (error) {
      // Handle errors appropriately
      console.error("Error occurred:", error);
    }
  };
  const onClick_Download = () => {
    window.open(previewImage, "_blank");
  };
  const onChangeCarusing = (value) => {
    setCarusing(value);
  };
  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>

      <div style={boxHeader}>
        <div style={{ display: "flex", justifyItems: "center", width: "auto" }}>
          <Space>
            {boxHeaderTagText("ការគ្រប់គ្រង់ ទិន្នន័យបញ្ជូនជំងឺ ")}
            <Input
              style={{ width: 200 }}
              className="styleTageStatusForm"
              placeholder="Search..."
              allowClear
            />
          </Space>
        </div>
        <div>
          <Space>
            <Button
              disabled={!printValue}
              className="buttonstylekhmer"
              onClick={onClickDownloadReport}
              type="primary"
              icon={<BsDownload />}
            >
              {" "}
              ទាយយករបាយការណ៍
            </Button>
            <Button
              onClick={onOpenSaveNew}
              disabled={!addvalue}
              className="custom-form-item"
              type="primary"
              icon={<PlusCircleOutlined />}
            >
              បន្ថែមថ្មី
            </Button>
          </Space>{" "}
        </div>
      </div>

      <div>
        {loading ? (
          <div className="sprinCustome">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Table
            // striped
            // bordered
            // hover
            className="rounded-table"
            itemsPerPage={10}
          >
            <thead>
              <tr class="table-primary">
                <th>NO</th>
                <th>បញ្ជូនជំងឺ </th>
                <th>លិខិតលេខ</th>

                <th>ខ្លឹមសារលិខិត</th>
                <th>ថ្ងៃខែឆ្នាំបេសកកម្ម</th>
                <th>ទីតាំងបេសកកម្ម</th>
                <th>រៀបចំដោយ និងថវិការ</th>
                <th>ស្ថានភាព</th>
                <th>សកម្មភាព</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((item, index) => (
                <tr key={index} class="table-Secondary">
                  <td>{item.id}</td>

                  <td>
                    <div>
                      <div>{getCarActivityContent(item.transfer_name)}</div>
                      <div style={{ paddingTop: 5 }}>
                        <Tag bordered={false} color="processing">
                          {formateDateClient(item.letters_date)}
                        </Tag>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div>
                        <Tag
                          style={{ fontFamily: "Khmer OS Battambang" }}
                          color="#2db7f5"
                        >
                          {" "}
                          {item.letters_from}{" "}
                        </Tag>
                      </div>
                      <div>
                        <Tag
                          style={{ fontFamily: "Khmer OS Battambang" }}
                          bordered={false}
                          color="processing"
                        >
                          {item.letters_number}
                        </Tag>
                      </div>
                      <div>
                        <Tag
                          style={{ fontFamily: "Khmer OS Battambang" }}
                          color="#2db7f5"
                        >
                          {" "}
                          {item.letters_type}{" "}
                        </Tag>
                      </div>
                    </div>
                  </td>

                  <td> {getTageStyle(item.join_des)}</td>
                  <td>
                    <div>
                      <div>
                        <Tag
                          style={{ fontFamily: "Khmer OS Battambang" }}
                          bordered={false}
                          color="processing"
                        >
                          ចេញដំណើរ ៖ {formateDateClient(item.joindate_start)}
                        </Tag>
                      </div>
                      <div>
                        <Tag
                          style={{ fontFamily: "Khmer OS Battambang" }}
                          bordered={false}
                          color="processing"
                        >
                          ត្រឡប់មកវិញ ៖ {formateDateClient(item.joindate_end)}
                        </Tag>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div style={{ color: "rgb(11, 145, 112)" }}>
                        <GrLocation /> {item.location}
                      </div>
                      <div>
                        <Tag
                          style={{
                            paddingTop: 2,
                            fontFamily: "Khmer OS Battambang",
                            width: 120,
                            whiteSpace: "normal",
                          }}
                        >
                          {item.invite_staff}
                        </Tag>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div>{item.prepare_by}</div>
                      <div>
                        <Tag
                          style={{ fontFamily: "Khmer OS Battambang" }}
                          color="#f50"
                        >
                          {item.support_by}
                        </Tag>
                      </div>
                    </div>
                  </td>
                  <td>{getStatusContent(item.status)}</td>
                  <td style={{ width: 100 }}>
                    <Stack gap={1} direction="horizontal">
                      <Button
                        onClick={() => onOpenModelPreviewFile(item)}
                        type="primary"
                        icon={<FaRegEye />}
                      />
                      <Button
                        disabled={!Editvalue}
                        onClick={() => onEditData(item)}
                        type="primary"
                        icon={<MdOutlineEdit />}
                      />
                      <Button
                        disabled={!deletevalue}
                        type="primary"
                        icon={<HiOutlineDownload />}
                      />
                      <Button
                        disabled={!printValue}
                        type="primary"
                        icon={<MdOutlineDelete />}
                        danger
                      />

                      {/* <Button disabled={!Editvalue} type="primary" onClick={()=>onOpenEdit(item)}  >Edit</Button>{' '}
                                    <Button  disabled={!deletevalue}  type="primary" onClick={()=>onDeleteModel(item)} danger>Delete</Button> */}
                    </Stack>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        <Pagination>
          {Array.from({ length: Math.ceil(list.length / itemsPerPage) }).map(
            (_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            )
          )}
        </Pagination>

        <Modal
          title={
            UserID == ""
              ? "បង្កើតថ្មី សកម្មភាពបញ្ចូលអ្នកជំងឺ"
              : "កែប្រែ សកម្មភាពបញ្ចូលអ្នកជំងឺ"
          }
          className="styleTageStatusForm"
          open={visableNewModel}
          onCancel={onCancelModel}
          width={1100}
          footer={[
            <div>
              <Space>
                <Button onClick={onCancelModel}>Cancel</Button>
                <Button type="primary" onClick={onSaveData}>
                  {UserID == "" ? "Save" : "Update"}
                </Button>
              </Space>
            </div>,
          ]}
        >
          <div style={{ display: "flex", justifyContent: "start" }}>
            <div style={{ width: 300 }}>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                សកម្មភាពបញ្ចូលអ្នកជំងឺ ៖
              </p>
              <Select
                className="styleTageStatusForm"
                value={UserLetters_type}
                onChange={onChange_UserLetters_Type}
                style={{ width: "100%" }}
                allowClear
              >
                <Option className="styleTageStatusForm" value="ចូល">
                  ចូល
                </Option>
                <Option className="styleTageStatusForm" value="ចេញ">
                  ចេញ
                </Option>
              </Select>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                {UserLetters_type === "ចូល" ? "បញ្ជូនចូលក្នុង" : "ចេញទៅ "} ៖
              </p>
              <Input
                value={UserLetters_FromTo}
                onChange={onChange_UserLetters_FromTo}
                className="styleTageStatusForm"
                placeholder="Input...."
              />

              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                លិខិតលេខ ៖
              </p>
              <Input
                value={UserLettter_number}
                onChange={onChange_UserLettter_number}
                className="styleTageStatusForm"
                placeholder="Input...."
              />

              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                {UserLetters_type === "ចូល"
                  ? "ថ្ងៃខែ បញ្ជូនចូល"
                  : "ថ្ងៃខែ បញ្ចូនចេញ"}
              </p>
              <MyDatePicker
                value={UserLettter_Date}
                onChange={onChange_UserLetters_Date}
                width="100%"
              />
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                កំណត់សម្គាល់ លិខិត ៖
              </p>

              <Select
                className="styleTageStatusForm"
                value={UserLetters_Note}
                onChange={onChange_UserLetters_Note}
                style={{ width: "100%" }}
                allowClear
              >
                <Option className="styleTageStatusForm" value="លិខិតអញ្ជើញ">
                  លិខិតអញ្ជើញ
                </Option>
                <Option
                  className="styleTageStatusForm"
                  value="លិខិតឧទ្ទេសនាមបេសកកម្ម"
                >
                  លិខិតឧទ្ទេសនាមបេសកកម្ម
                </Option>
                <Option className="styleTageStatusForm" value="លិខិតបង្គាប់ការ">
                  លិខិតបង្គាប់ការ
                </Option>
                <Option className="styleTageStatusForm" value="លិខិតផ្សេងៗ">
                  លិខិតផ្សេងៗ
                </Option>
              </Select>
            </div>

            <div style={{ paddingLeft: 20, width: 300 }}>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ខ្លឹមសារលិខិត ៖
              </p>
              <Input.TextArea
                value={UserLettter_Description}
                onChange={onChange_UserLettter_Description}
                className="styleTageStatusForm"
                rows={5}
                placeholder="បញ្ចូល ខ្លឹមសាររបស់ឯកសារ....."
              />
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ចាប់ផ្តើមថ្ងៃទី ៖
              </p>
              <MyDatePicker
                value={UserLetters_DateStart}
                onChange={onChange_UserLetters_DateStart}
                width="100%"
              />
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ដល់ថ្ងៃទី ៖
              </p>
              <MyDatePicker
                value={UserLetters_DateEnd}
                onChange={onChange_UserLetters_DateEnd}
                width="100%"
              />
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ទីតាំងបេសកកម្ម ៖
              </p>
              <Input.TextArea
                value={UserLetters_location}
                onChange={onChange_UserLetters_location}
                className="styleTageStatusForm"
                rows={2}
                placeholder="បញ្ចូល ទីតាំងបេសកកម្ម....."
              />
            </div>
            <div style={{ paddingLeft: 20, width: 300 }}>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                រៀបចំដោយ ៖
              </p>
              <Input.TextArea
                value={UserLetters_PrepareBy}
                onChange={onChange_UserLetters_PrepareBy}
                className="styleTageStatusForm"
                rows={2}
                placeholder="បញ្ចូល រៀបចំដោយ....."
              />
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ឧបត្ថមលុយដោយ ៖
              </p>
              <Input.TextArea
                value={UserLetters_SupportBy}
                onChange={onChange_UserLetters_SupportBy}
                className="styleTageStatusForm"
                rows={2}
                placeholder="បញ្ចូល រៀបចំដោយ....."
              />
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                មន្ត្រីចូលរួម ៖
              </p>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                value={UserLetters_StaffCodeJoin}
                onChange={onChange_UserLetter_Staffjoin}
                options={DataJoiner}
                showSearch // Enable search functionality
                filterOption={(inputValue, option) =>
                  option.label
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                } // Custom
              />
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ប្រើប្រាស់រថយន្ត / Car Using
              </p>
              <Select
                style={{ width: 250 }}
                // showSearch
                placeholder="Select a Car"
                // optionFilterProp="children"
                onChange={onChangeCarusing}
                value={car_name}
              >
                {listcar.map((option) => (
                  <Option
                    className="styleTageStatusForm"
                    key={option.id}
                    value={option.car_name}
                  >
                    {option.car_name}{" "}
                    {/* Replace with the field from your MySQL table */}
                  </Option>
                ))}
              </Select>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ស្ថានភាព / Status
              </p>

              <Tag>
                <div style={{ padding: 10 }}>
                  <Checkbox
                    checked={enable}
                    onChange={handleCheckboxChange_Enable}
                  >
                    Enable
                  </Checkbox>
                  <Checkbox
                    style={{ color: "red" }}
                    checked={disable}
                    onChange={handleCheckboxChange_Diable}
                  >
                    Disable
                  </Checkbox>
                </div>
              </Tag>
            </div>
            <div style={{ paddingLeft: 20, width: 300 }}>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                File ឯកសាររបស់លិខិត ៖
              </p>

              <Upload
                key={null} //
                action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                beforeRemove={(file) => false} // Prevent automatic removal of files
                onRemove={(file) => {
                  return new Promise((resolve, reject) => {
                    Modal.confirm({
                      title: "Are you sure you want to delete this file?",
                      okText: "Yes",
                      cancelText: "No",
                      onOk: () => {
                        handleRemove(file);
                      },
                      // onCancel: () => {reject}
                    });
                  });
                }}
              >
                {fileList.length >= 8 ? null : uploadButton}
              </Upload>

              <p>
                សរុបឯកសារ ៖ <Tag color="#f50">{totalCount}</Tag>{" "}
              </p>
            </div>
          </div>
        </Modal>

        <Modal
          title="មើលលិខិតច្បាប់ដើម"
          className="custom-form-item"
          open={visableModelPreviewFile}
          onCancel={onCanceDelete}
          footer={[
            <div>
              <Button onClick={onCLoseModelDelete}>Close</Button>
            </div>,
          ]}
        >
          <Upload
            key={null} //
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
          >
            {fileList.length >= 8 ? null : null}
          </Upload>
        </Modal>

        <Modal
          open={previewOpen}
          // title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          {previewImage &&
            (previewImage.endsWith(".png") ||
              previewImage.endsWith(".jpg") ||
              previewImage.endsWith(".jpeg")) && (
              <img
                style={{
                  width: "80%",
                }}
                src={previewImage}
              />
            )}
          {!previewImage ||
            (!previewImage.endsWith(".png") &&
              !previewImage.endsWith(".jpg") &&
              !previewImage.endsWith(".jpeg") && (
                <dvi>
                  <p
                    style={{
                      margin: 5,
                      color: "darkgray",
                      paddingTop: 10,
                      fontFamily: "Khmer OS Battambang ,sans-serif",
                    }}
                  >
                    ឈ្មោះឯកសារ : {previewTitle}
                  </p>
                  <img
                    style={{
                      width: "50%",
                    }}
                    src=" https://icon-library.com/images/file-browser-icon/file-browser-icon-4.jpg"
                  />
                </dvi>
              ))}

          <Button
            type="primary"
            className="buttonstylekhmer"
            onClick={onClick_Download}
          >
            ទាញយក / Download
          </Button>
        </Modal>

        <Modal
          title="ទាញរបាយការណ៍"
          className="custom-modal" // Use the custom-modal class for responsive styles
          open={DownloadReport}
          onCancel={() => {
            setDownloadReport(false);
          }}
          footer={[
            <div>
              <Button className="custom-form-item" type="primary">
                ទាញយក (Excel)
              </Button>
            </div>,
          ]}
        >
          <div style={{ width: "520px" }}>
            <Space>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Space style={{ width: "100%" }}>
                  ច្បាប់ចាប់ពីថ្ងៃ ទី ខែ :
                  <DatePicker
                  //  onChange={handleDateChange_Start}
                  />
                </Space>
              </div>
              <div>
                <Space>
                  ដល់ថ្ងៃ ទី ខែ :
                  <DatePicker
                  //  onChange={handleDateChange_End}
                  />
                </Space>
              </div>
            </Space>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default CarActivityPage;
