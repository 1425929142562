import {Outlet} from "react-router-dom"
const LayoutLogin = () => {
    return (
        <div>
            
            <div style={{paddingTop:100}}>
                <Outlet/>
            </div>
        </div>
    )
}

export default LayoutLogin;

