import { useEffect, useState, useRef } from "react";
import {
  PlusCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  UsergroupAddOutlined,
  SearchOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  CloseOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";
import {
  Table,
  Modal,
  Button,
  Form,
  Input,
  Item,
  UserOutlined,
  Select,
  Space,
  Tag,
  Checkbox,
  Spin,
  Pagination,
  List,
  Row,
  Col,
  Tabs,
} from "antd";
import "../../styles/Home-module.css";
import { Circles } from "react-loader-spinner";
import { request } from "../../share/request";
import { getStatusContent, getTageStyle } from "../../share/getStatusContent";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";
import ShiftPage from "./ShiftPage";
import { FaPeopleArrows } from "react-icons/fa6";
import { BsShiftFill } from "react-icons/bs";
const { TabPane } = Tabs;
const LeaveTypeMaster = () => {
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    try {
      const res = await request("leavetype", "get", {});
      await new Promise((resolve) => setTimeout(resolve, 400));
      if (res) {
        setList(res.list_typeleave);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const columns = [
    {
      key: "typ_id",
      title: "លេខកូដ",
      dataIndex: "typ_id",
    },
    {
      key: "type_Leave",
      title: "ឈ្មោះសិទ្ធ",
      dataIndex: "type_Leave",
    },

    {
      key: "terget",
      title: "ចំនួនកំណត់",
      dataIndex: "terget",
    },
    {
      key: "usercreate",
      title: "អ្នកបង្កើត",
      dataIndex: "usercreate",
      render: getTageStyle,
    },
    {
      key: "status",
      title: "ស្ថានភាព",
      dataIndex: "status",
      render: getStatusContent,
    },

    {
      key: "action",
      title: "សកម្មភាព",
      dataIndex: "action",
      render: (value, item, index) => {
        return (
          <div>
            <Space key={index}>
              <Button type="primary">Edit</Button>
              <Button danger type="primary" icon={<CloseOutlined />} />
            </Space>
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>

      <div style={boxHeader}>
        <div style={{ display: "flex" }}>
          <Space>
            {boxHeaderTagText("ការគ្រប់គ្រង់ ប្រភេទច្បាប់")}
            <div>
              <Input></Input>
            </div>
          </Space>
        </div>
        <div style={{ display: "flex" }}>
          <Space>
            <Button
              className="buttonstylekhmer"
              type="primary"
              icon={<PlusCircleOutlined />}
            >
              បង្កើតថ្មី
            </Button>
          </Space>
        </div>
      </div>
      <div>
        {loading ? (
          <div className="sprinCustome">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Table
            pagination={{
              pageSize: 10,
            }}
            columns={columns}
            size="small"
            dataSource={list}
          />
        )}
      </div>
    </div>
  );
};
export default LeaveTypeMaster;
