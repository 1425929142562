import { useEffect, useState } from "react";
import "../../styles/Home-module.css";
import { Circles } from "react-loader-spinner";
import { Button, Input, Modal, Select, Space, Divider, Card } from "antd";
import { FaChartPie } from "react-icons/fa";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import Highcharts, { Color } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import { khmerMonthsData } from "../../share/helper";
import { request } from "../../share/request";
import { openNotification } from "../../share/message";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";
// Initialize exporting module
if (typeof Highcharts === "object") {
  exporting(Highcharts);
}
const { Option } = Select;
const CaseGraphic = () => {
  const [DownloadReport, setDownloadReport] = useState(false);
  const [loadingSpin, setLoadingSpin] = useState(false);
  // TODO: 1 លក្ខ័ណទី ១
  const [Year_start, setYear_start] = useState("");
  const [first_Month_Start, setFirst_Month_Start] = useState("");
  const [first_Month_End, setFirst_Month_End] = useState("");
  // TODO: 2 លក្ខ័ណទី ២
  const [Year_end, setYear_end] = useState("");
  const [seconde_Month_Start, setSeconde_Month_Start] = useState("");
  const [seconde_Month_End, setSeconde_Month_End] = useState("");
  const [listCaseName, setListCaseName] = useState([]);
  const [ValueSelect, setValueSelect] = useState("");
  const [chartDataView, setChartData] = useState([]);
  const [chartName, setChartName] = useState("");

  const categories = [...new Set(chartDataView.map((item) => item.year))];
  const years = [...new Set(chartDataView.map((item) => item.case_kh))];
  const series = years.map((case_kh) => ({
    name: case_kh.toString(),
    data: chartDataView
      .filter((item) => item.case_kh === case_kh)
      .map((item) => item.total_sum),
  }));
  // const ChartTypeName = "";
  // const chartOptions = {
  //   chart: {
  //     type: ChartTypeName,
  //   },
  //   title: {
  //     text: "Medical Cases by Year",
  //     align: "left",
  //   },
  //   xAxis: {
  //     categories,
  //   },

  //   yAxis: {
  //     allowDecimals: false,
  //     min: 0,
  //     title: {
  //       text: "",
  //     },
  //     labels: {
  //       overflow: "justify",
  //     },
  //   },

  //   tooltip: {
  //     pointFormat:
  //       "<b>{point.key}</b><br/>{series.name}: {point.y}<br/>" +
  //       "Total: {point.stackTotal}",
  //   },
  //   plotOptions: {
  //     bar: {
  //       borderRadius: "20%",
  //       dataLabels: {
  //         enabled: true,
  //       },
  //       groupPadding: 0.1,
  //     },
  //   },
  //   series,
  //   exporting: {
  //     enabled: true,
  //   },
  // };
  const Chart = ({ ChartTypeName }) => {
    const chartOptions = {
      chart: {
        type: ChartTypeName,
      },
      title: {
        text: " ", // chart title
        align: "left",
      },
      xAxis: {
        categories,
      },
      yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: "",
        },
        labels: {
          overflow: "justify",
        },
      },
      tooltip: {
        pointFormat:
          "<b>{point.key}</b><br/>{series.name}: {point.y}<br/>" +
          "Total: {point.stackTotal}",
      },
      plotOptions: {
        bar: {
          borderRadius: "10%",
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
        line: {
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
        column: {
          dataLabels: {
            enabled: true,
          },
          groupPadding: 0.1,
        },
      },
      series,
      exporting: {
        enabled: true,
      },
    };

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
  };
  const onChangeYear_start = (value) => {
    setYear_start(value);
  };
  const OnChangFirst_Month_Start = (value) => {
    setFirst_Month_Start(value);
  };
  const onChangeFirst_Month_End = (value) => {
    setFirst_Month_End(value);
  };
  const onChangeYear_End = (value) => {
    setYear_end(value);
  };
  const onChangeSeconde_Month_Start = (value) => {
    setSeconde_Month_Start(value);
  };
  const onChangeSeconde_Month_End = (value) => {
    setSeconde_Month_End(value);
  };
  useEffect(() => {
    getListCaseName();
    console.log("data case name : ", listCaseName);
  }, []);
  const getListCaseName = () => {
    const res = request("casename", "get", {});
    res.then((res) => {
      setListCaseName(
        res.data
        // res.data.map((caseName) => ({ label: caseName, value: caseName }))
      );
      console.log(res.data);
    });
  };

  const handleChange = (value) => {
    setValueSelect(value);
    console.log(`selected ${value}`);
  };

  const onClickGetReport = async () => {
    if (Year_start === "") {
      openNotification("error", "Error", "Please Select Year Start!");
    } else if (Year_end === "") {
      openNotification("error", "Error", "Please Select Year End!");
    } else if (first_Month_End === "") {
      openNotification("error", "Error", "Please Select Month End!");
    } else if (first_Month_Start === "") {
      openNotification("error", "Error", "Please Select Month Start!");
    } else if (seconde_Month_Start === "") {
      openNotification("error", "Error", "Please Select Month End!");
    } else if (seconde_Month_End === "") {
      openNotification("error", "Error", "Please Select Month End!");
    } else if (ValueSelect === "") {
      openNotification("error", "Error", "Please Select Case Name!");
    } else {
      if (
        first_Month_Start > first_Month_End ||
        seconde_Month_End < seconde_Month_Start
      ) {
        openNotification(
          "error",
          "error",
          " First Month cannot be less than second Month !"
        );
      } else {
        try {
          const param = new URLSearchParams({
            // operation 1
            year_start: Year_start,
            first_month_start: first_Month_Start,
            first_month_end: first_Month_End,
            // operation 2
            year_end: Year_end,
            seconde_month_start: seconde_Month_Start,
            seconde_month_end: seconde_Month_End,
            select_case_name: ValueSelect,
          });
          setDownloadReport(false);
          setLoadingSpin(true);
          await new Promise((resolve) => setTimeout(resolve, 400));
          request("casechartquery?" + param.toString(), "get", {})
            .then((res) => {
              // Combine the data, mapping only the necessary columns
              const dataOne = res.data.data_one.map((item) => ({
                case_kh: item.case_kh,
                year: item.year + `(${first_Month_Start}-${first_Month_End})`,
                total_sum: item.total_sum,
              }));

              const dataTwo = res.data.data_tow.map((item) => ({
                case_kh: item.case_kh,
                year:
                  item.year + `(${seconde_Month_Start}-${seconde_Month_End})`,
                total_sum: item.total_sum,
              }));

              const combinedData = [...dataOne, ...dataTwo];
              setChartData(combinedData);
              console.table(combinedData);

              setLoadingSpin(false);
            })
            .catch((error) => {
              // Handle error if necessary
              setLoadingSpin(false);
              openNotification(
                "error",
                "Error",
                "An error occurred while fetching data."
              );
            });
        } catch (error) {
          setLoadingSpin(false);
          openNotification("error", "Error", "An unexpected error occurred.");
        }
      }
    }
  };

  return (
    <div>
      <div className="sprinSave-container">
        {loadingSpin && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>
      {/* keep not scolling  */}
      <div style={boxHeader}>
        <div style={{ display: "flex" }}>
          {boxHeaderTagText("   ក. តារាងរៀបចំក្រាបរបស់ជំងឺ Chart Operation")}
        </div>
        <div style={{ display: "flex" }}>
          <Space>
            <Button
              className="buttonstylekhmer"
              type="primary"
              onClick={() => setDownloadReport(true)}
              icon={<FaChartPie style={{ width: 20, height: 20 }} />}
            >
              ទាញយកក្រាប
            </Button>
          </Space>
        </div>
      </div>
      {/* can scolling  */}
      <div>
        <div style={{ marginTop: 0, overflowY: "auto" }}>
          <Chart ChartTypeName="bar" />
        </div>
        <div>
          <Chart ChartTypeName="line" />
        </div>
        <div>
          <Chart ChartTypeName="column" />
        </div>
      </div>

      <Modal
        width={720}
        open={DownloadReport}
        onOk={onClickGetReport}
        onCancel={() => setDownloadReport(false)}
        title="Chart Query Setting"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Card
            size="small"
            title="ជម្រើសទី១"
            // extra={<a href="#">More</a>}
            style={{ width: 300 }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyItems: " flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <p style={{ paddingTop: 10, paddingRight: 10 }}>ឆ្នាំ ៖</p>

                <Select
                  style={{ width: 200 }}
                  onChange={onChangeYear_start}
                  placeholder="Select Year"
                  value={Year_start}
                  allowClear
                >
                  <Option value="2022">2022</Option>
                  <Option value="2023">2023</Option>
                  <Option value="2024">2024</Option>
                  <Option value="2025">2025</Option>
                  <Option value="2026">2026</Option>
                  <Option value="2027">2027</Option>
                </Select>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyItems: " flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Space>
                  ចាប់ពីខែ :
                  <Select
                    value={first_Month_Start}
                    onChange={OnChangFirst_Month_Start}
                    style={{ width: 200 }}
                  >
                    {khmerMonthsData.map((month, index) => (
                      <option key={index} value={index + 1}>
                        {month}
                      </option>
                    ))}
                  </Select>
                </Space>
              </div>
              <div
                style={{
                  paddingTop: "10px",
                  display: "flex",
                  justifyItems: " flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Space>
                  ដល់ ខែ :
                  <Select
                    value={first_Month_End}
                    onChange={onChangeFirst_Month_End}
                    style={{ width: 200 }}
                  >
                    {khmerMonthsData.map((month, index) => (
                      <option key={index} value={index + 1}>
                        {month}
                      </option>
                    ))}
                  </Select>
                </Space>
              </div>
            </div>
          </Card>
          <Card
            size="small"
            title="ជម្រើសទី២"
            // extra={<a href="#">More</a>}
            style={{ width: 300 }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyItems: " flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <p style={{ paddingTop: 10, paddingRight: 10 }}>ឆ្នាំ ៖</p>
                <Select
                  style={{ width: 200 }}
                  onChange={onChangeYear_End}
                  placeholder="Select Year"
                  value={Year_end}
                  allowClear
                >
                  <Option value="2022">2022</Option>
                  <Option value="2023">2023</Option>
                  <Option value="2024">2024</Option>
                  <Option value="2025">2025</Option>
                  <Option value="2026">2026</Option>
                  <Option value="2027">2027</Option>
                </Select>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyItems: " flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Space>
                  ចាប់ពីខែ :
                  <Select
                    value={seconde_Month_Start}
                    onChange={onChangeSeconde_Month_Start}
                    style={{ width: 200 }}
                  >
                    {khmerMonthsData.map((month, index) => (
                      <option key={index} value={index + 1}>
                        {month}
                      </option>
                    ))}
                  </Select>
                </Space>
              </div>
              <div
                style={{
                  paddingTop: "10px",
                  display: "flex",
                  justifyItems: " flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Space>
                  ដល់ ខែ :
                  <Select
                    value={seconde_Month_End}
                    onChange={onChangeSeconde_Month_End}
                    style={{ width: 200 }}
                  >
                    {khmerMonthsData.map((month, index) => (
                      <option key={index} value={index + 1}>
                        {month}
                      </option>
                    ))}
                  </Select>
                </Space>
              </div>
            </div>
          </Card>
        </div>
        <div
          style={{
            display: "flex",
            borderRadius: "5px",
            margin: "25px",
            padding: "10px",
            border: "0.5px solid #e5e7e9",
            paddingTop: "10px",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <p>សូមជ្រើសរើសជំងឺដែលចង់បង្ហាញ</p>
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%", height: "100px" }}
              placeholder="Please select"
              //  value={selectedItems}
              onChange={handleChange}
            >
              {listCaseName.map((item) => (
                <Option key={item.id} value={item.case_kh}>
                  {item.case_kh}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CaseGraphic;
