import React, { useRef } from "react";
import '../../styles/Home-module.css'
 import { linkPathReport } from "../../share/helper";
import { formateDateClient } from "../../share/helper";
import { BiCheckbox,BiCheckboxChecked } from "react-icons/bi";
export default class ComponentToPrint extends React.Component {
  render() {

    const textX = 212; // Adjust X coordinate as needed
    const textY = 560; // Adjust Y coordinate as needed
    const textY1 = 530; // Adjust Y coordinate as needed
 
    const {reason,node,date_start,date_end,support_by,car_using,
        liststaff}   =this.props;
 
    const printpath=(value)=>{
      if(value <=3){
      var  imagebackground = linkPathReport + "mission.jpg"
      }else{
    var  imagebackground =  linkPathReport + "mission.jpg"
      }
      return imagebackground;
    }
    const toKhmerNumeral = (number) => {
        const khmerDigits = ['០', '១', '២', '៣', '៤', '៥', '៦', '៧', '៨', '៩'];
        return String(number).split('').map(digit => khmerDigits[digit]).join('');
      };
      
    return (
      <div className="image-container-report">
   
        <img src={printpath(3)} alt="Leave Report" className="print-image" />
        {liststaff.map((staff, index) => (
          <div key={index} className="text-overlay" style={{ top: 360 + index * 30, left: 230 }}>
            <p className="text-on-image-name_lettersBold">{toKhmerNumeral(index+1)}- {staff.frontname} {staff.fullname}  {staff.position}</p>
          </div>
        ))}
        <div className="text-overlay" style={{ top: textY1, left: textX }}>
          <p className="text-on-image-name_letters">{node}</p>
        </div>
        <div className="text-overlay" style={{ top: textY, left: textX , width: 500 }}>
          <p className="text-on-image-name_letters">{reason}</p>
        </div>
        <div className="text-overlay" style={{ top: 646, left: 310 }}>
          <p className="text-on-image-name_letters">{date_start}</p>
        </div>

        <div className="text-overlay" style={{ top: 676, left: 310 }}>
          <p className="text-on-image-name_letters">{date_end}</p>
        </div>
      
        <div className="text-overlay" style={{ top: 706, left: 310 }}>
          <p className="text-on-image-name_letters">{car_using}</p>
        </div>
        <div className="text-overlay" style={{ top: 738, left: 310 }}>
          <p className="text-on-image-name_letters">{support_by}</p>
        </div>
    </div>
    );
  }
}