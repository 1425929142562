import { Button, Tag } from "antd";
import { CheckCircleOutlined,ClockCircleOutlined,} from '@ant-design/icons';
import { CiSquareCheck,CiStop1 } from "react-icons/ci";
import { BsDownload,BsUpload } from "react-icons/bs";
import { FaCarRear } from "react-icons/fa6";
import { AiOutlineIdcard } from "react-icons/ai";
const getStatusContent = (status)=>  {
    switch (status) {
      case 1: 
      case '1':
        return     <Button className='styleTageStatus'   type="primary" ghost  shape="round" icon={<CheckCircleOutlined />} > Active </Button>;
        case 0: 
        case '0':
        return   <Button className='styleTageStatus'  type="primary" danger ghost shape="round" icon={<ClockCircleOutlined />} >Disable </Button>;
       default:
        return null;
    }};

    const getStatusCheckbox = (status)=>  {
      switch (status) {
        case 1: 
        case '1':
          return    <CiSquareCheck    style={{width:30,height:30}}/> ;
        case 0:
          case '0': 
          return  <CiStop1  style={{width:30,height:30}} />;
         default:
          return null;
      }};

      const getTageStyle =(status) =>  {
        switch (status) {
          case 'Approved':
            return     <Tag color="success" className='styleTageStatus' icon={<CheckCircleOutlined />}>Approved</Tag>;
          case 'Pending':
            return    <Tag color="error" className='styleTageStatus' icon={<ClockCircleOutlined />}>Pending</Tag>;
          default:  
            return <Tag color="success" className='styleTageStatus' icon={<ClockCircleOutlined />}>{status}</Tag>;;
        }
      };
      const getJobStatus = (status)=>  {
        switch (status) {
         
          case 'ធ្វើការ':
            return     <Button className='styleTageStatus'   type="primary" ghost  shape="round" icon={<CheckCircleOutlined />} > {status} </Button>;
        
            case 'ព្យួរ':
            return   <Button className='styleTageStatus'  type="primary" danger ghost shape="round" icon={<ClockCircleOutlined />} >{status} </Button>;
            case 'រដ្ឋ':
            return   <Button className='styleTageStatus'  type="primary" ghost  shape="round" icon={<CheckCircleOutlined />} >{status} </Button>;
            case "បណ្តុះបណ្តាល":
              return   <Button className='styleTageStatus'  type="primary" danger ghost shape="round" icon={<ClockCircleOutlined />} >{status} </Button>;
         
            default: 
            return <Button className='styleTageStatus'  type="primary" danger ghost shape="round" icon={<ClockCircleOutlined />} >{status} </Button>;
        }};
        const getLettersContent = (status)=>  {
          switch (status) {
            case 'ចូល':
              return     <Button className='styleTageStatus'   type="primary" ghost  shape="round" icon={ <BsDownload   />} > ចូល </Button>;
             
              case 'ចេញ':
              return   <Button className='styleTageStatus'  type="primary" danger ghost shape="round" icon={<BsUpload />} >ចេញ </Button>;
              case 'ឯកសារផ្សេងៗ':
                return     <Button className='styleTageStatus'   type="primary" ghost  shape="round" icon={ <AiOutlineIdcard />} > ឯកសារផ្សេងៗ </Button>;
               
              default:
              return null;
          }};
          const getCarActivityContent = (status)=>  {
            switch (status) {
              case 'ចូល':
                return     <Button className='styleTageStatus'   type="primary" ghost  shape="round" icon={ <FaCarRear />} > ចូល </Button>;
               
                case 'ចេញ':
                return   <Button className='styleTageStatus'  type="primary" danger ghost shape="round" icon={<FaCarRear />} >ចេញ </Button>;
              
                default:
                return null;
            }};
export {  getStatusContent,
  getStatusCheckbox,
  getTageStyle,
  getJobStatus,
  getLettersContent,
  getCarActivityContent
};