import React, { useRef } from "react";
import '../../styles/Home-module.css'
 import { linkPathReport } from "../../share/helper";
import { formateDateClient } from "../../share/helper";
import { BiCheckbox,BiCheckboxChecked } from "react-icons/bi";
export default class ComponentToPrint extends React.Component {
  render() {

    
    const {reason,
      transfer_in_name1,
      transfer_in_name2,
      transfer_in_name3,
      transfer_out_name1,
      transfer_out_name2,
      transfer_out_name3,
      date_start,
      date_end,
      location}  
       =this.props;
       const spaces = " ".repeat(10); // Creates a string with 20 spaces
       const spaces1 = " ".repeat(1);
    const tilte1=spaces+"ខ្ញុំ"+transfer_in_name1
    const title2=spaces1+"ត្រូវទៅ"+reason+spaces1+" នៅ"+ location;
    const tilte3=spaces+"ខ្ញុំសូមផ្ទេរសិទ្ធជូន "+transfer_out_name1
    const title_out= transfer_out_name3+" ឲ្យទទួលដឹកនាំចរន្តការងារប្រចាំថ្ងៃជំនួសខ្ញុំ ចាប់ពី" +date_start +spaces1+"រហូតដល់ខ្ញុំមានវត្តមានការងារវិញ ។"
   
    const printpath=(value)=>{
      if(value <=3){
      var  imagebackground = linkPathReport + "rightmanager_v2.png"
      }else{
    var  imagebackground =  linkPathReport + "rightmanager_v2.png"
      }
      return imagebackground;
    }
    const toKhmerNumeral = (number) => {
        const khmerDigits = ['០', '១', '២', '៣', '៤', '៥', '៦', '៧', '៨', '៩'];
        return String(number).split('').map(digit => khmerDigits[digit]).join('');
      };
      
    return (
      <div className="image-container-report">
   
        <img src={printpath(3)} alt="Leave Report" className="print-image" />
    
        
        <div className="text-overlay" style={{ top: 400, left: 120 }}>
          <p className="text-on-image-name_rightmanager">{tilte1}
          <span  className="text-on-image-name_rightmanager1">
          {transfer_in_name2}
          </span>
           {transfer_in_name3}
           {title2}
           <br />
           {tilte3}
           <span  className="text-on-image-name_rightmanager1">
          {transfer_out_name2}
          </span>
          {title_out}
           </p>
        </div>
       
    </div>
    );
  }
}