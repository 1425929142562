// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import HomePage from "./page/home/HomePage";
// import AboutPage from "./page/about/AboutPage";
// import LoginPage from "./page/auth/LoginPage";
// import RegisterPage from "./page/auth/RegisterPage";
// import Layout from "./component/layout/Layout";
// import CategoryPage from "./page/category/CategoryPage";
// import EmployeePage from "./page/employee/EmployeePage";
// import CustomerPage from "./page/customer/CustomerPage";
// import UserPage from "./page/user/user.controller";
// import LayoutLogin from "./component/layout/LayoutLogin";
// import DepartmentPage from "./page/department/DepartmentPage";
// import PositionPage from "./page/position/PositionPage";
// import LeavePage from "./page/leavereport/LeavePage";
// import SettingPage from "./page/setting/SettingPage";
// import RolePage from "./page/role/RolePage";
// import LeaveTypeMaster from "./page/leavtype/LeavTypePage";
// import SkillPage from "./page/skill/SkillPage";
// import LettersPage from "./page/letter/LettersPage";
// import AttendanceQRCode from "./page/attendance/AttendanceQRcodePage";
// import CarActivityPage from "./page/caractivity/CarActivityPage";
// import RightManager from "./page/rightmanager/RightManagerPage";
// import StaffSchedule from "./page/schedule/StaffSchedule";
// import TelegramBotPage from "./page/telegrambot/TelegramBotPage";
// import Case from "./page/case/case";
// import CaseGraphic from "./page/CaseGraphic/CaseGraphic";
// import BankingReport from "./page/accounting/BankingReport";
// import Page404 from "./page/about/page404";
// import WeekendCaseReport from "./page/weekendreport/WeekendCaseReport";
// import { getPermission } from "./share/getProfile";
// function App() {
//   const userPermission = getPermission() || []; // Assuming getPermission() returns an array of user permissions
//   const staff_Value_page = userPermission.find(
//     (permission) => permission.pagename === "staff"
//   ) || { pageshow: 0 };
//   const { pageshow: staffValuePage } = staff_Value_page;
//   const departmentValue = userPermission.find(
//     (permission) => permission.pagename === "department"
//   ) || { pageshow: 0 };
//   const { pageshow: DepartmentValue } = departmentValue;
//   const positionValue = userPermission.find(
//     (permission) => permission.pagename === "position"
//   ) || { pageshow: 0 };
//   const { pageshow: PositionValue } = positionValue;
//   const leave_Value = userPermission.find(
//     (permission) => permission.pagename === "leave"
//   ) || { pageshow: 0 };
//   const { pageshow: SetupValue } = leave_Value;
//   const leavereportValue = userPermission.find(
//     (permission) => permission.pagename === "leavereport"
//   ) || { pageshow: 0 };
//   const { pageshow: LeaveReportValue } = leavereportValue;
//   const staffreportValue = userPermission.find(
//     (permission) => permission.pagename === "staffreport"
//   ) || { pageshow: 0 };
//   const { pageshow: StaffReportValue } = staffreportValue;
//   const letterspageValue = userPermission.find(
//     (permission) => permission.pagename === "letterspage"
//   ) || { pageshow: 0 };
//   const { pageshow: LettersPageValue } = letterspageValue;
//   const skillpageValue = userPermission.find(
//     (permission) => permission.pagename === "skillpage"
//   ) || { pageshow: 0 };
//   const { pageshow: SkillValue } = skillpageValue;

//   const attendance = userPermission.find(
//     (permission) => permission.pagename === "attendance"
//   ) || { pageshow: 0 };
//   const { pageshow: AttendanceValue } = attendance;
//   const caractivity = userPermission.find(
//     (permission) => permission.pagename === "caractivity"
//   ) || { pageshow: 0 };
//   const { pageshow: CarActivityValue } = caractivity;
//   const rightmanager = userPermission.find(
//     (permission) => permission.pagename === "rightmanager"
//   ) || { pageshow: 0 };
//   const { pageshow: RightManagerValue } = rightmanager;

//   const role_Value = userPermission.find(
//     (permission) => permission.pagename === "role"
//   ) || { pageshow: 0 };
//   const { pageshow: RoleValue } = role_Value;

//   const caseValue = userPermission.find(
//     (permission) => permission.pagename === "case"
//   ) || { pageshow: 0 };
//   const { pageshow: CaseValue } = caseValue;

//   const bankValue = userPermission.find(
//     (permission) => permission.pagename === "bankreport"
//   ) || { pageshow: 0 };
//   const { pageshow: BankValue } = bankValue;

//   const CaseGraphic_Values = userPermission.find(
//     (permission) => permission.pagename === "casegraphic"
//   ) || { pageshow: 0 };
//   const { pageshow: CaseGraphicValue } = CaseGraphic_Values;

//   const CaseWeekendReport_Values = userPermission.find(
//     (permission) => permission.pagename === "caseweekendreport"
//   ) || { pageshow: 0 };
//   const { pageshow: CaseWeekendReportValues } = CaseWeekendReport_Values;

//   const Telegrambotsetting_Values = userPermission.find(
//     (permission) => permission.pagename === "telegrambotsetting"
//   ) || { pageshow: 0 };
//   const { pageshow: TelegramBotSettingValues } = Telegrambotsetting_Values;
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/" element={<Layout />}>
//           <Route path="" element={<HomePage />} />
//           <Route path="category" element={<CategoryPage />} />
//           <Route path="customer" element={<CustomerPage />} />

//           {StaffReportValue ? (
//             <Route path="employee" element={<EmployeePage />} />
//           ) : (
//             <Route path="*" element={<Page404 />} />
//           )}
//           {DepartmentValue ? (
//             <Route path="department" element={<DepartmentPage />} />
//           ) : (
//             <Route path="*" element={<Page404 />} />
//           )}
//           {PositionValue ? (
//             <Route path="position" element={<PositionPage />} />
//           ) : (
//             <Route path="*" element={<Page404 />} />
//           )}
//           {LeaveReportValue ? (
//             <Route path="leave" element={<LeavePage />} />
//           ) : (
//             <Route path="*" element={<Page404 />} />
//           )}

//           {staffValuePage ? (
//             <Route path="user" element={<UserPage />} />
//           ) : (
//             <Route path="*" element={<Page404 />} />
//           )}
//           {RoleValue ? (
//             <Route path="rolepage" element={<RolePage />} />
//           ) : (
//             <Route path="*" element={<Page404 />} />
//           )}
//           <Route path="setting" element={<SettingPage />} />
//           {SetupValue ? (
//             <Route path="leavetype" element={<LeaveTypeMaster />} />
//           ) : (
//             <Route path="*" element={<Page404 />} />
//           )}
//           {SkillValue ? (
//             <Route path="skillpage" element={<SkillPage />} />
//           ) : (
//             <Route path="*" element={<Page404 />} />
//           )}

//           {LettersPageValue ? (
//             <Route path="letterspage" element={<LettersPage />} />
//           ) : (
//             <Route path="*" element={<Page404 />} />
//           )}
//           {AttendanceValue ? (
//             <Route path="attendanceqrpage" element={<AttendanceQRCode />} />
//           ) : (
//             <Route path="*" element={<Page404 />} />
//           )}
//           {CarActivityValue ? (
//             <Route path="caractivity" element={<CarActivityPage />} />
//           ) : (
//             <Route path="*" element={<Page404 />} />
//           )}
//           {RightManagerValue ? (
//             <Route path="rightmanager" element={<RightManager />} />
//           ) : (
//             <Route path="*" element={<Page404 />} />
//           )}
//           {StaffReportValue ? (
//             <Route path="staffschedule" element={<StaffSchedule />} />
//           ) : (
//             <Route path="*" element={<Page404 />} />
//           )}
//           {TelegramBotSettingValues ? (
//             <Route path="telegrambot" element={<TelegramBotPage />} />
//           ) : (
//             <Route path="*" element={<Page404 />} />
//           )}
//           {CaseValue ? (
//             <Route path="case" element={<Case />} />
//           ) : (
//             <Route path="*" element={<Page404 />} />
//           )}
//           {CaseGraphicValue ? (
//             <Route path="casegraphic" element={<CaseGraphic />} />
//           ) : (
//             <Route path="*" element={<Page404 />} />
//           )}
//           {BankValue ? (
//             <Route path="bankingreport" element={<BankingReport />} />
//           ) : (
//             <Route path="*" element={<Page404 />} />
//           )}
//           {CaseWeekendReportValues ? (
//             <Route path="weekendreport" element={<WeekendCaseReport />} />
//           ) : (
//             <Route path="*" element={<Page404 />} />
//           )}

//           <Route path="about" element={<AboutPage />} />
//           <Route path="*" element={<Page404 />} />
//         </Route>
//         <Route path="/" element={<LayoutLogin />}>
//           <Route path="login" element={<LoginPage />} />
//           <Route path="register" element={<RegisterPage />} />
//         </Route>
//       </Routes>
//     </BrowserRouter>
//   );
// }

// export default App;
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import HomePage from "./page/home/HomePage";
import AboutPage from "./page/about/AboutPage";
import LoginPage from "./page/auth/LoginPage";
import RegisterPage from "./page/auth/RegisterPage";
import Layout from "./component/layout/Layout";
import CategoryPage from "./page/category/CategoryPage";
import EmployeePage from "./page/employee/EmployeePage";
import CustomerPage from "./page/customer/CustomerPage";
import UserPage from "./page/user/user.controller";
import LayoutLogin from "./component/layout/LayoutLogin";
import DepartmentPage from "./page/department/DepartmentPage";
import PositionPage from "./page/position/PositionPage";
import LeavePage from "./page/leavereport/LeavePage";
import SettingPage from "./page/setting/SettingPage";
import RolePage from "./page/role/RolePage";
import SkillPage from "./page/skill/SkillPage";
import LettersPage from "./page/letter/LettersPage";
import AttendanceQRCode from "./page/attendance/AttendanceQRcodePage";
import CarActivityPage from "./page/caractivity/CarActivityPage";
import RightManager from "./page/rightmanager/RightManagerPage";
import StaffSchedule from "./page/schedule/StaffSchedule";
import TelegramBotPage from "./page/telegrambot/TelegramBotPage";
import Case from "./page/case/case";
import CaseGraphic from "./page/CaseGraphic/CaseGraphic";
import BankingReport from "./page/accounting/BankingReport";
import Page404 from "./page/about/page404";
import WeekendCaseReport from "./page/weekendreport/WeekendCaseReport";
import { getPermission } from "./share/getProfile";
import ForgotPassword from "./component/OTPhone/ForgotPassword";
import ProfileUser from "./page/profileuser/ProfileUser";
import UserLeave from "./page/profileuser/UserLeave";
import SetupType from "./page/leavtype/SetupType";

function App() {
  const userPermission = getPermission() || []; // Assuming getPermission() returns an array of user permissions

  const findPermission = (pageName) => {
    return (
      userPermission.find((permission) => permission.pagename === pageName) || {
        pageshow: 0,
      }
    );
  };

  const permissions = {
    staffValuePage: findPermission("staff").pageshow,
    DepartmentValue: findPermission("department").pageshow,
    PositionValue: findPermission("position").pageshow,
    SetupValue: findPermission("leave").pageshow,
    LeaveReportValue: findPermission("leavereport").pageshow,
    StaffReportValue: findPermission("staffreport").pageshow,
    StaffScheduleValue: findPermission("staffschedule").pageshow,

    LettersPageValue: findPermission("letterspage").pageshow,
    SkillValue: findPermission("skillpage").pageshow,
    AttendanceValue: findPermission("attendance").pageshow,
    CarActivityValue: findPermission("caractivity").pageshow,
    RightManagerValue: findPermission("rightmanager").pageshow,
    RoleValue: findPermission("role").pageshow,
    CaseValue: findPermission("case").pageshow,
    BankValue: findPermission("bankreport").pageshow,
    CaseGraphicValue: findPermission("casegraphic").pageshow,
    CaseWeekendReportValues: findPermission("caseweekendreport").pageshow,
    TelegramBotSettingValues: findPermission("telegrambotsetting").pageshow,
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<HomePage />} />
          <Route path="category" element={<CategoryPage />} />
          <Route path="customer" element={<CustomerPage />} />
          <Route path="setting" element={<SettingPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="profile" element={<ProfileUser />} />
          <Route path="leaveuserprofile" element={<UserLeave />} />
          <Route path="*" element={<Page404 />} />
          {permissions.StaffReportValue ? (
            <Route path="employee" element={<EmployeePage />} />
          ) : (
            <Route path="*" element={<Page404 />} />
          )}
          {permissions.DepartmentValue ? (
            <Route path="department" element={<DepartmentPage />} />
          ) : (
            <Route path="*" element={<Page404 />} />
          )}
          {permissions.PositionValue ? (
            <Route path="position" element={<PositionPage />} />
          ) : (
            <Route path="*" element={<Page404 />} />
          )}
          {permissions.LeaveReportValue ? (
            <Route path="leave" element={<LeavePage />} />
          ) : (
            <Route path="*" element={<Page404 />} />
          )}
          {permissions.staffValuePage ? (
            <Route path="user" element={<UserPage />} />
          ) : (
            <Route path="*" element={<Page404 />} />
          )}
          {permissions.RoleValue ? (
            <Route path="rolepage" element={<RolePage />} />
          ) : (
            <Route path="*" element={<Page404 />} />
          )}

          {permissions.SetupValue ? (
            <Route path="setuptype" element={<SetupType />} />
          ) : (
            <Route path="*" element={<Page404 />} />
          )}
          {permissions.SkillValue ? (
            <Route path="skillpage" element={<SkillPage />} />
          ) : (
            <Route path="*" element={<Page404 />} />
          )}
          {permissions.LettersPageValue ? (
            <Route path="letterspage" element={<LettersPage />} />
          ) : (
            <Route path="*" element={<Page404 />} />
          )}
          {permissions.AttendanceValue ? (
            <Route path="attendanceqrpage" element={<AttendanceQRCode />} />
          ) : (
            <Route path="*" element={<Page404 />} />
          )}
          {permissions.CarActivityValue ? (
            <Route path="caractivity" element={<CarActivityPage />} />
          ) : (
            <Route path="*" element={<Page404 />} />
          )}
          {permissions.RightManagerValue ? (
            <Route path="rightmanager" element={<RightManager />} />
          ) : (
            <Route path="*" element={<Page404 />} />
          )}
          {permissions.StaffScheduleValue ? (
            <Route path="staffschedule" element={<StaffSchedule />} />
          ) : (
            <Route path="*" element={<Page404 />} />
          )}
          {permissions.TelegramBotSettingValues ? (
            <Route path="telegrambot" element={<TelegramBotPage />} />
          ) : (
            <Route path="*" element={<Page404 />} />
          )}
          {permissions.CaseValue ? (
            <Route path="case" element={<Case />} />
          ) : (
            <Route path="*" element={<Page404 />} />
          )}
          {permissions.CaseGraphicValue ? (
            <Route path="casegraphic" element={<CaseGraphic />} />
          ) : (
            <Route path="*" element={<Page404 />} />
          )}
          {permissions.BankValue ? (
            <Route path="bankingreport" element={<BankingReport />} />
          ) : (
            <Route path="*" element={<Page404 />} />
          )}
          {permissions.CaseWeekendReportValues ? (
            <Route path="weekendreport" element={<WeekendCaseReport />} />
          ) : (
            <Route path="*" element={<Page404 />} />
          )}
        </Route>
        <Route path="/" element={<LayoutLogin />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
