import React, { useEffect, useState } from "react";
import {
  Modal,
  Select,
  Table,
  Button,
  Space,
  DatePicker,
  Popconfirm,
  Tag,
  Input,
} from "antd";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { request } from "../../share/request";
import { MdOutlineEdit, MdOutlineDelete } from "react-icons/md";
import { PlusCircleOutlined } from "@ant-design/icons";
import { IoDocumentTextOutline } from "react-icons/io5";
import moment from "moment";
import { openNotification } from "../../share/message";
import { formateDateSystem, khmerMonthsData } from "../../share/helper";
import queryString from "query-string";
//  for export data to excel
import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import { Circles } from "react-loader-spinner";
import "../../styles/Home-module.css";
import { getPermission } from "../../share/getProfile";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";

const { Option } = Select;
const Case = () => {
  const [openNewCase, setopenNewCase] = useState(false);
  const [ListReportExcel, setListReportExcel] = useState([]);
  const [months_start, setMothStartReport] = useState();
  const [months_end, setMothEndReport] = useState();
  const [Year_Report, setYearReport] = useState();
  const [totalPage, setTotalPage] = useState(null);
  const [listJoiner, setListJoiner] = useState([]);
  const [ValueCaseName, setValueCaseName] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [isAddingRow, setIsAddingRow] = useState(false); // State variable to track if adding row
  const showAddButton = !dataSource || dataSource.length === 0;

  const [loadingSpin, setLoadingSpin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [DownloadReport, setDownloadReport] = useState(false);

  const [selectedDateStart, setSelectedDate_start] =
    useState(/* initialize with a valid date object */);
  const [selectedDateEnd, setSelectedDate_end] =
    useState(/* initialize with a valid date object */);
  const [UserStatus_Search, setUserStatus_Search] = useState("");

  // -----------------  permisstion page --------------------
  const userPermission = getPermission();
  const caseValue = userPermission.find(
    (permission) => permission.pagename === "case"
  ) || { add: 0, edit: 0, deleted: 0, print: 0 };
  const {
    add: addvalue,
    edit: Editvalue,
    deleted: deletevalue,
    print: printValue,
  } = caseValue;
  // -----------------  end permisstion page --------------------

  const [objFilter, setObjFilter] = useState({
    page: 1,
    txtSearch: "",
    txtStatus: "",
  });
  const pagesizeIndex = 10;
  useEffect(() => {
    console.log(
      "Year_Report has been updated: " + Year_Report + months_start + months_end
    );
  }, [Year_Report, months_start, months_end]);
  useEffect(() => {
    getlist(objFilter);
    // setPageNumber(1);
    // getlist();
    // Fetch data from MySQL when component mounts
  }, []);
  const isEditing = (record, dataIndex) =>
    record.id === editingKey && dataIndex !== editingKey;

  const handleAdd = () => {
    // Create a new row object with default values or prompt the user for input
    const newRow = {
      id: "", // Generate a unique ID for the new row
      date: "",
      head1: null,
      head2: null,
      head3: null,
      head4: null,
      head5: null,
      head6: null,
      head7: null,
      head8: null,
      head9: null,
      head10: null,
      head11: null,
      head12: null,
      head13: null,
      head14: null,
      head15: null,
      head16: null,
      head17: null,
      head18: null,
      head19: null,
      head20: null,
    };

    // Update the dataSource state by adding the new row
    setDataSource([...dataSource, newRow]);

    // Optionally, update the editingKey state if necessary
    setEditingKey(newRow.id);

    // Optionally, send the new data to your backend API to persist it
    // You can use your request function here to send a POST request with the new row data
  };
  const actionTitle = showAddButton ? (
    <span>
      Action
      <Button
        type="primary"
        onClick={handleAdd}
        className="buttonstylekhmer"
        icon={<PlusCircleOutlined />}
        style={{ marginLeft: "8px" }}
      />
    </span>
  ) : (
    "Action"
  );
  const getlist = async (ParamObjFilter) => {
    try {
      const params = new URLSearchParams({
        page: ParamObjFilter.page,
        txtSearch: ParamObjFilter.txtSearch,
        txtStatus: ParamObjFilter.txtStatus,
      });

      await new Promise((resolve) => setTimeout(resolve, 400));
      const res = await request("case?" + params.toString(), "get", {});
      console.log("API response:", res);

      if (res) {
        if (ParamObjFilter.txtSearch) {
          console.log("Filtered Data:", res.API_Server_ListCase);
          setDataSource(res.API_Server_ListCase);
          setListJoiner(res.API_Server_ListCaseDetail);
        } else {
          console.log("Non-Filtered Data:", res.API_Server_ListCase);
          setDataSource(res.API_Server_ListCase);
          setListJoiner(res.API_Server_ListCaseDetail);
        }

        if (ParamObjFilter.page === 1) {
          setTotalPage(res.pagecount[0].total);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsAddingRow(true);
    }
    setLoading(false);
  };
  const itemsPerPage = 10;
  const columns = [
    {
      title: "No",
      dataIndex: "id",
      editable: false,
      fixed: "left",
      width: 100,
      render: (value, item, index) =>
        index + 1 + itemsPerPage * (objFilter.page - 1),
    },
    {
      title: "ឈ្មោះជំងឺ",
      dataIndex: "case_kh",
      editable: true,
      fixed: "left",
      render: (text, record) => renderEditableCell(text, record, "case_kh"),
      width: 200,
    },
    {
      title: "ឆ្នាំ",
      dataIndex: "year",
      // editable: true,
      render: (text, record) => renderEditableCell_Year(text, record, "head1"),
    },
    {
      title: "ខែ១",
      dataIndex: "month01",
      editable: true,

      render: (text, record) => {
        // Format the number using toLocaleString
        const formattedNumber = text ? Number(text).toLocaleString() : "";

        // Return the formatted number inside the editable cell
        return renderEditableCell_Number(formattedNumber, record, "month01");
      },
    },
    {
      title: "ខែ២",
      dataIndex: "month02",
      editable: true,
      render: (text, record) => {
        // Format the number using toLocaleString
        const formattedNumber = text ? Number(text).toLocaleString() : "";

        // Return the formatted number inside the editable cell
        return renderEditableCell_Number(formattedNumber, record, "month02");
      },
    },
    {
      title: "ខែ៣",
      dataIndex: "month03",
      editable: true,
      render: (text, record) => {
        // Format the number using toLocaleString
        const formattedNumber = text ? Number(text).toLocaleString() : "";
        // Return the formatted number inside the editable cell
        return renderEditableCell_Number(formattedNumber, record, "month03");
      },
    },
    {
      title: "ខែ៤",
      dataIndex: "month04",
      editable: true,
      render: (text, record) => {
        // Format the number using toLocaleString
        const formattedNumber = text ? Number(text).toLocaleString() : "";

        // Return the formatted number inside the editable cell
        return renderEditableCell_Number(formattedNumber, record, "month04");
      },
    },
    {
      title: "ខែ៥",
      dataIndex: "month05",
      editable: true,
      render: (text, record) => {
        // Format the number using toLocaleString
        const formattedNumber = text ? Number(text).toLocaleString() : "";

        // Return the formatted number inside the editable cell
        return renderEditableCell_Number(formattedNumber, record, "month05");
      },
    },
    {
      title: "ខែ៦",
      dataIndex: "month06",
      editable: true,
      render: (text, record) => {
        // Format the number using toLocaleString
        const formattedNumber = text ? Number(text).toLocaleString() : "";

        // Return the formatted number inside the editable cell
        return renderEditableCell_Number(formattedNumber, record, "month06");
      },
    },
    {
      title: "ខែ៧",
      dataIndex: "month07",
      editable: true,
      render: (text, record) => {
        // Format the number using toLocaleString
        const formattedNumber = text ? Number(text).toLocaleString() : "";

        // Return the formatted number inside the editable cell
        return renderEditableCell_Number(formattedNumber, record, "month07");
      },
    },

    {
      title: "ខែ៨",
      dataIndex: "month08",
      editable: true,
      render: (text, record) => {
        // Format the number using toLocaleString
        const formattedNumber = text ? Number(text).toLocaleString() : "";

        // Return the formatted number inside the editable cell
        return renderEditableCell_Number(formattedNumber, record, "month08");
      },
    },
    {
      title: "ខែ៩",
      dataIndex: "month09",
      editable: true,
      render: (text, record) => {
        // Format the number using toLocaleString
        const formattedNumber = text ? Number(text).toLocaleString() : "";

        // Return the formatted number inside the editable cell
        return renderEditableCell_Number(formattedNumber, record, "month09");
      },
    },
    {
      title: "ខែ១០",
      dataIndex: "month10",
      editable: true,
      render: (text, record) => {
        // Format the number using toLocaleString
        const formattedNumber = text ? Number(text).toLocaleString() : "";

        // Return the formatted number inside the editable cell
        return renderEditableCell_Number(formattedNumber, record, "month10");
      },
    },
    {
      title: "ខែ១១",
      dataIndex: "month11",
      editable: true,

      render: (text, record) => {
        // Format the number using toLocaleString
        const formattedNumber = text ? Number(text).toLocaleString() : "";

        // Return the formatted number inside the editable cell
        return renderEditableCell_Number(formattedNumber, record, "month11");
      },
    },
    {
      title: "ខែ១២",
      dataIndex: "month12",
      editable: true,

      render: (text, record) => {
        // Format the number using toLocaleString
        const formattedNumber = text ? Number(text).toLocaleString() : "";

        // Return the formatted number inside the editable cell
        return renderEditableCell_Number(formattedNumber, record, "month12");
      },
    },
    {
      title: "សរុប",
      dataIndex: "total",
      editable: true,
      render: (text, record) => {
        // Format the number using toLocaleString
        const formattedNumber = text ? Number(text).toLocaleString() : "0";

        // Return the formatted number inside the editable cell
        return renderEditableCell_total(formattedNumber, record, "total");
      },
    },
    // Add similar render function for other editable columns
    ...(Editvalue == 1 || deletevalue == 1
      ? [
          {
            title: actionTitle,
            dataIndex: "action",
            fixed: "right",
            width: 100,
            render: (_, record, index) => {
              const editable = isEditing(record);

              return (
                <div>
                  {editable ? (
                    <Space size="middle">
                      <Button type="primary" onClick={() => handleSave(record)}>
                        Save
                      </Button>
                      <Button onClick={handleCancel} type="primary" danger>
                        Cancel
                      </Button>
                    </Space>
                  ) : (
                    <>
                      <Space>
                        <Button
                          disabled={editingKey !== ""}
                          style={{ display: !Editvalue ? "none" : "block" }}
                          onClick={() => handleEdit(record)}
                          type="primary"
                          icon={<MdOutlineEdit />}
                        />

                        <Button
                          disabled={editingKey !== ""}
                          style={{ display: !deletevalue ? "none" : "block" }}
                          type="primary"
                          danger
                          icon={<MdOutlineDelete />}
                        />

                        {(PageNumber - 1) * pagesizeIndex + index + 1 ==
                          dataSource.length && (
                          <Button
                            type="primary"
                            onClick={handleAdd}
                            className="buttonstylekhmer"
                            icon={<PlusCircleOutlined />}
                          ></Button>
                        )}
                      </Space>
                    </>
                  )}
                </div>
              );
            },
          },
        ]
      : []),
  ];

  const handleSave = async (record) => {
    try {
      setLoadingSpin(true);
      const records = {
        id: record.id,
        month1: record.month01,
        month2: record.month02,
        month3: record.month03,
        month4: record.month04,
        month5: record.month05,
        month6: record.month06,
        month7: record.month07,
        month8: record.month08,
        month9: record.month09,
        month10: record.month10,
        month11: record.month11,
        month12: record.month12,
      };

      let method = record.id ? "put" : "post";
      const res = await request("case", method, records);

      if (res) {
        openNotification(
          "success",
          "Success",
          record.id ? "Data updated successfully " : "Insert  successfully "
        );
        getlist(objFilter);
      }
      setEditingKey("");
    } catch (error) {
      openNotification("error", "Error", error);
    } finally {
      setLoadingSpin(false);
    }
  };
  const handleEdit = (record) => {
    setEditingKey(record.id);
  };
  const handleCancel = () => {
    setEditingKey("");
    var objTmp = {
      ...objFilter,
    };
    getlist(objTmp);
  };
  const onChangeYearReport = (value) => {
    setYearReport(value);
  };
  const renderEditableCell_Number = (text, record, dataIndex) => {
    const editable = isEditing(record);

    return (
      <div>
        {editable ? (
          <div>
            <input
              type="number"
              style={{
                borderRadius: 5,
                width: 100,
                padding: 10,
                backgroundColor: "#ebf5fb",
                border: "1px solid #108ee9",
                fontWeight: "bold",
                fontSize: 16,
              }}
              value={record[dataIndex]}
              onChange={(e) => {
                const newData = [...dataSource];
                const index = newData.findIndex(
                  (item) => record.id === item.id
                );
                if (index > -1) {
                  newData[index][dataIndex] = e.target.value;
                  setDataSource(newData);
                }
              }}
            />
          </div>
        ) : (
          <div
            style={{
              fontWeight: "bold",
            }}
          >
            {text}
          </div>
        )}
      </div>
    );
  };
  const renderEditableCell_total = (text, record, dataIndex) => {
    return (
      <div>
        <div style={{ fontWeight: "bold" }}>
          <Tag color="#108ee9" style={{ borderRadius: 50 }}>
            <p style={{ margin: 3 }}>{text}</p>
          </Tag>
        </div>
      </div>
    );
  };
  const renderEditableCell_Year = (text, record, dataIndex) => {
    return (
      <div>
        <div style={{ fontWeight: "bold" }}>
          <Tag color="#f50" style={{ borderRadius: 50 }}>
            <p style={{ margin: 3 }}>{text}</p>
          </Tag>
        </div>
      </div>
    );
  };

  const tagColors = ["#108ee9", "#87d068", "#f50"];
  const renderEditableCell = (text, record, dataIndex) => {
    const editable = isEditing(record);
    // Check if record[dataIndex] is a string before splitting it
    const arrayValueForSelect =
      typeof record[dataIndex] === "string"
        ? record[dataIndex].split(",").map((value) => value.trim())
        : [];

    return editable ? (
      <Select
        // mode="multiple"
        allowClear
        // style={{ width: "150px" }}
        placeholder="Please select"
        value={arrayValueForSelect} // Use the value from the record object
        onChange={(selectedValues) => {
          const newData = [...dataSource];
          const index = newData.findIndex((item) => record.id === item.id);
        }}
        options={DataJoiner}
        showSearch // Enable search functionality
        filterOption={(inputValue, option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        } // Custom
      />
    ) : (
      <div>
        {arrayValueForSelect.map((value, index) => (
          <Tag
            key={index}
            color={tagColors[index % tagColors.length]}
            style={{
              marginTop: 2,
              padding: 3,
              fontFamily: "Khmer OS Battambang",
              fontWeight: "bold",
              fontSize: 14,
              borderRadius: 50,
            }}
          >
            <p style={{ margin: 3 }}>{value}</p>
          </Tag>
        ))}
      </div>
    );
  };

  const DataJoiner = listJoiner.map((cased, index) => ({
    label: cased.case_kh,
    value: cased.case_kh, // Using idnumber as the value
  }));
  const [PageNumber, setPageNumber] = useState();

  const onChangePage = async (page) => {
    try {
      setLoadingSpin(true);
      var objTmp = {
        ...objFilter,
        page: page,
      };
      await new Promise((resolve) => setTimeout(resolve, 400));
      await setObjFilter(objTmp);
      getlist(objTmp);
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };

  const onExporttoExcelTotal = async () => {
    if (!selectedDateStart || !selectedDateEnd) {
      console.error("Selected dates are undefined");
      return;
    }
    const param = {
      date_start: selectedDateStart,
      date_end: selectedDateEnd,
    };

    const queryParams = queryString.stringify(param);
    console.log("date start:", formateDateSystem(selectedDateStart));
    console.log("date end:", formateDateSystem(selectedDateEnd));
    const res = await request(`schedulereport?${queryParams}`, "get", {});
    if (res) {
      // Ensure res.dataRequestLeave is an array
      const dataArray = Array.isArray(res.listTotalStaff)
        ? res.listTotalStaff[0]
        : [];

      console.log("res", dataArray);

      // Return the array for further use if needed
      return dataArray;
    }

    // Return an empty array or handle the case when 'res' is falsy
    return [];
  };

  //  for download leave
  const handleDateChange_Start = (date) => {
    setSelectedDate_start(date);
  };
  const handleDateChange_End = (date) => {
    setSelectedDate_end(date);
  };

  const onExporttoExcel = async () => {
    const params = new URLSearchParams({
      Yearly: Year_Report,
      MonthStart: months_start,
      MonthEnd: months_end, // Corrected typo
    });

    await new Promise((resolve) => setTimeout(resolve, 400));
    const res = await request(
      "casereportexcell?" + params.toString(),
      "get",
      {}
    );

    if (res) {
      // Ensure res.dataRequestLeave is an array
      const dataArray = Array.isArray(res.data) ? res.data[0] : [];

      console.log("res", dataArray);

      // Return the array for further use if needed
      return dataArray;
    }

    // Return an empty array if 'res' is falsy
    return [];
  };

  const downloadExcel_FromData = async () => {
    try {
      setLoadingSpin(true);
      await new Promise((resolve) => setTimeout(resolve, 400));

      // Usage
      const data = await onExporttoExcel();

      // Load the template Excel file from the public folder
      const templatePath =
        process.env.PUBLIC_URL + "/template/casereports.xlsx";
      const response = await fetch(templatePath);
      const templateArrayBuffer = await response.arrayBuffer();

      // Create a workbook from the template file
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(templateArrayBuffer);

      // Get the 'detail' sheet from the workbook
      const dataSheet = workbook.getWorksheet("detail");

      if (!dataSheet) {
        console.error('Sheet with name "detail" not found in the template.');
        return;
      }

      // Convert the new data to worksheet format
      const newData = data.map((item) => Object.values(item));

      // Ensure data is not empty and contains valid column names
      if (newData.length > 0 && Object.keys(data[0]).length > 0) {
        // Define columns with valid names
        const columns = Object.keys(data[0]).map((key) => ({ name: key }));

        // Append the new data to the existing 'detail' sheet starting from cell A6
        dataSheet.addTable({
          name: "dataTable",
          ref: "A6",
          headerRow: true,
          columns: columns,
          rows: newData,
        });
      } else {
        console.error("Data is empty or does not contain valid column names.");
        return;
      }

      // Save the modified workbook as a Blob with XLSX format
      const blob = await workbook.xlsx.writeBuffer();
      const name_report = `Report-${
        Year_Report + "-" + months_start + "-to-" + months_end
      }`;
      FileSaver.saveAs(new Blob([blob]), `${name_report}.xlsx`);
      setLoadingSpin(false);
    } catch (error) {
      openNotification("error", "error", "file is can't download!");
    }
  };

  const downloadExcel = async () => {
    switch (true) {
      case Year_Report === undefined:
        openNotification("error", "error", "Please Select Year Report");
        break;
      case months_start === undefined:
        openNotification("error", "error", "Please Select Month Start");
        break;
      case months_end === undefined:
        openNotification("error", "error", "Please Select Month End");
        break;
      case months_start > months_end:
        openNotification(
          "error",
          "error",
          "Month Start cannot be greater than Month End"
        );
        break;
      default:
        downloadExcel_FromData();

        break;
    }
  };

  // const GetReport_to_Excel = async () => {
  //   const params = new URLSearchParams({
  //     Yearly: Year_Report,
  //     MonthStart: months_start,
  //     MonthEend: months_end,
  //   });

  //   await new Promise((resolve) => setTimeout(resolve, 400));
  //   const res = await request(
  //     "casereportexcell?" + params.toString(),
  //     "get",
  //     {}
  //   );
  //   if (res) {
  //     console.log("casereportexcell", res.data);
  //     setListReportExcel(res.data); //
  //   }
  // };
  const [UserSearchKeyWord, setUserSearchKeyword] = useState("");
  const onChangeTextScarch = async (event) => {
    const inputValue = event.target.value;
    setUserSearchKeyword(inputValue);
    try {
      setLoadingSpin(true);
      await new Promise((resolve) => setTimeout(resolve, 400));
      onSearch(inputValue, UserStatus_Search);
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };
  const onChangeYearSearch = async (value) => {
    setUserStatus_Search(value);

    try {
      setLoadingSpin(true);
      await new Promise((resolve) => setTimeout(resolve, 400));
      if (value) {
        var objTmp = {
          ...objFilter,
          txtStatus: UserSearchKeyWord,
          txtStatus: value,
          page: value === "" ? 1 : objFilter.page,
        };
        setObjFilter(objTmp);
        getlist(objTmp);
      }
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };
  const onSearch = (value, statu) => {
    var objTmp = {
      ...objFilter,
      txtSearch: value,
      txtStatus: statu,
      page: value === "" ? 1 : objFilter.page,
    };
    setObjFilter(objTmp);
    getlist(objTmp);
  };
  const onChangeMonthStart = (value) => {
    setMothStartReport(value);
  };
  const onChangeMonthEnd = (value) => {
    setMothEndReport(value);
  };
  const onClickopenNewCase = () => {
    setValueCaseName(null);
    setYearReport(null);
    setopenNewCase(true);
  };
  const onChangeCaseName = (event) => {
    setValueCaseName(event.target.value);
    console.log("onChangeCaseName", ValueCaseName);
  };
  const onSaveNewCase = async () => {
    if (ValueCaseName === null || Year_Report === null) {
      console.log("onSaveNewCase", ValueCaseName);
      openNotification("error", "Error", "Case Name or Year can't Null.");
    } else {
      try {
        setopenNewCase(false);
        setLoadingSpin(true);
        const params = new URLSearchParams({
          caseName: ValueCaseName,
          year: Year_Report,
        });
        const res = await request(
          "addnewcasename?" + params.toString(),
          "get",
          {}
        );
        // Log the response to check its structure
        console.log("Response:", res);
        // Check if res is defined and has the expected properties
        if (res && res.isok === true) {
          openNotification("success", "Success", res.message);
        } else if (res && res.isok === false) {
          openNotification(
            "error",
            "Error",
            res.message || "Duplicate case name and year."
          );
        } else if (res) {
          openNotification("error", "Error", "An unexpected error occurred.");
        } else {
          openNotification("error", "Error", "No response from server.");
        }
      } catch (error) {
        openNotification(
          "error",
          "Error",
          error.message || "An error occurred."
        );
      } finally {
        setLoadingSpin(false); // Ensure spinner stops in all cases
      }
    }
  };

  return (
    <div>
      <>
        <div style={boxHeader}>
          <div style={{ display: "flex" }}>
            <Space>
              {boxHeaderTagText("  ក. តារាងប្រភេទជំងឺ")}
              <div>
                <Space>
                  <Input
                    allowClear
                    onChange={onChangeTextScarch}
                    style={{ width: 200 }}
                    placeholder="ស្វែងរកចំនួនក្នុងការប្រភេទជំងឺ"
                  />
                  <Select
                    style={{ width: 150 }}
                    onChange={onChangeYearSearch}
                    placeholder="Select Year"
                    name={UserStatus_Search}
                    value={UserStatus_Search}
                    allowClear
                  >
                    <Option value="2022">2022</Option>
                    <Option value="2023">2023</Option>
                    <Option value="2024">2024</Option>
                    <Option value="2025">2025</Option>
                    <Option value="2026">2026</Option>
                    <Option value="2027">2027</Option>
                  </Select>
                </Space>
              </div>
            </Space>
          </div>
          <div style={{ display: "flex" }}>
            <Space>
              <Button
                disabled={!printValue}
                className="buttonstylekhmer"
                type="primary"
                onClick={() => setDownloadReport(true)}
                icon={
                  <IoDocumentTextOutline style={{ width: 20, height: 20 }} />
                }
              >
                ទាញយករបាយការណ៍
              </Button>
              <Button
                disabled={!addvalue}
                className="buttonstylekhmer"
                type="primary"
                onClick={onClickopenNewCase}
              >
                បង្កើតឈ្មោះជំងឺថ្មី
              </Button>
            </Space>
          </div>
        </div>
        <div className="sprinSave-container">
          {/* Your other components */}
          {loadingSpin && (
            <div className="sprinSave">
              <Circles
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}
        </div>
        <div style={{ overflowX: "auto" }}>
          <div>
            {loading ? (
              <div className="sprinCustome">
                <Circles
                  height="80"
                  width="80"
                  color="#4fa94d"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <Table
                rowKey="id"
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={{
                  pageSize: pagesizeIndex,
                  total: totalPage,
                  onChange: onChangePage,
                  position: ["bottomLeft"], // Set pagination to bottom left
                }}
                scroll={{ x: "max-content" }} // Enable horizontal scrolling
                //sticky // Enable sticky header (pagination will not move)
                rowClassName={(record) =>
                  isEditing(record) ? "editing-row" : ""
                } // Apply custom class to editing rows
              />
            )}
          </div>
        </div>
        <Modal
          title="ទាញរបាយការណ៍"
          className="custom-modal" // Use the custom-modal class for responsive styles
          open={DownloadReport}
          onCancel={() => {
            setDownloadReport(false);
          }}
          footer={[
            <div>
              <Button
                className="custom-form-item"
                type="primary"
                onClick={downloadExcel}
              >
                ទាញយក (Excel)
              </Button>
            </div>,
          ]}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ width: 65 }}>
                  <p style={{ paddingTop: 10, paddingRight: 10 }}>ឆ្នាំ ៖</p>
                </div>
                <Select
                  style={{ width: 150 }}
                  onChange={onChangeYearReport}
                  placeholder="Select Year"
                  value={Year_Report}
                  allowClear
                >
                  <Option value="2022">2022</Option>
                  <Option value="2023">2023</Option>
                  <Option value="2024">2024</Option>
                  <Option value="2025">2025</Option>
                  <Option value="2026">2026</Option>
                  <Option value="2027">2027</Option>
                </Select>
              </div>
            </div>

            <div>
              <Space>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Space style={{ width: "100%" }}>
                    ចាប់ពី ខែ :
                    <Select
                      value={months_start}
                      onChange={onChangeMonthStart}
                      style={{ width: 150 }}
                    >
                      {khmerMonthsData.map((month, index) => (
                        <option key={index} value={index + 1}>
                          {month}
                        </option>
                      ))}
                    </Select>
                  </Space>
                </div>
                <div>
                  <Space>
                    ដល់ ខែ :
                    <Select
                      value={months_end}
                      onChange={onChangeMonthEnd}
                      style={{ width: 150 }}
                    >
                      {khmerMonthsData.map((month, index) => (
                        <option key={index} value={index + 1}>
                          {month}
                        </option>
                      ))}
                    </Select>
                  </Space>
                </div>
              </Space>
            </div>
          </div>
        </Modal>
        <Modal
          title={
            <span style={{ color: "#4a90e2", fontSize: "16px" }}>
              បង្កើតថ្មី
            </span>
          }
          open={openNewCase}
          onCancel={() => setopenNewCase(false)}
          onOk={onSaveNewCase}
        >
          <div>
            <div style={{ marginTop: 20 }}>
              <div style={{ display: "flex" }}>
                <p style={{ width: 200, paddingTop: 10, paddingRight: 10 }}>
                  ឈ្មោះជំងឺ /Case Name :
                </p>
                <Input.TextArea
                  rows={10}
                  styles={{ width: "100%" }}
                  onChange={onChangeCaseName}
                  type="text"
                  style={{ fontSize: "18px" }}
                  value={ValueCaseName}
                  placeholder="ឈ្មោះជំងឺ /Case Name "
                />
              </div>

              <div style={{ display: "flex", marginTop: 20 }}>
                <p style={{ width: 200, paddingTop: 10, paddingRight: 10 }}>
                  ឆ្នាំ ៖
                </p>

                <Select
                  style={{ width: "100%", height: 40 }}
                  onChange={onChangeYearReport}
                  placeholder="Select Year"
                  value={Year_Report}
                  allowClear
                >
                  <Option value="2022">2022</Option>
                  <Option value="2023">2023</Option>
                  <Option value="2024">2024</Option>
                  <Option value="2025">2025</Option>
                  <Option value="2026">2026</Option>
                  <Option value="2027">2027</Option>
                </Select>
              </div>
            </div>
          </div>
        </Modal>
      </>
    </div>
  );
};

export default Case;
