import React, { useImperativeHandle, forwardRef } from "react";
import { message } from "antd";

const SuccessRequest = forwardRef((props, ref) => {
  const [messageApi, contextHolder] = message.useMessage();

  // Expose the success and destroy functions to the parent component through the ref
  useImperativeHandle(ref, () => ({
    success: () => {
      messageApi.open({
        type: "loading",
        content: "Data Processing...",
        duration: 0,
      });
    },
    destroy: () => {
      messageApi.destroy(); // Close the loading message
    },
  }));

  return <>{contextHolder}</>;
});

export default SuccessRequest;
