import React, { useEffect, useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input ,Spin} from 'antd';
 import styels from '../../page/auth/LoginPage.module.css'
 import { request } from '../../share/request';
 import '../../styles/Home-module.css'
 import {openNotification} from '../../share/message'
 import {Avatar} from 'antd';
 import { getCurrentUser } from '../../share/getProfile';
function SettingPage(){
  const userProfile=getCurrentUser()
  const [saving, setSaving] = useState(false);
    const onFinish = async (values) => {
  

        try {
          // Set the saving state to true when the save operation starts
         
          var data = {
            username: userProfile.username,
            password: values.password,
            confirmPassword:values.passwordConfirm,
          };
          const res = await request("user/set-password", "post", data);
          // Simulate an asynchronous save operation (replace with your actual save logic)
      
          if (res.isSuccess) { 
            setSaving(true);
            await new Promise((resolve) => setTimeout(resolve, 2000));
          //  openNotification("success"," Password Update Complete !")
            console.log("user Name : " + userProfile.username +" password is set")
             // Simulate a successful save
              console.log('Password saved successfully!');
              localStorage.setItem("isLogin",null);
              window.location.href="/login"
           
          }else if (values.password != values.confirmPassword){
            openNotification("error"," password is not match !")
          }
        
        } catch (error) {
          openNotification("error"," password is not match !")
        } finally {
          // Set the saving state to false when the save operation completes
          setSaving(false);
        }
    }

 
      return(
      <div className={styels.container1} >
              <div style={{textAlign:'center'}}>
              <h5> Update Your Profile</h5>
             <div style={{height:100}}>
                     <Avatar  size="large"  />
             </div>
              </div>
              <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                >

                 
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Password!',
                      },
                    ]}
                  >
                    <Input className={styels.textStyle}
                    
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Password"
                    />
                  </Form.Item>
                  <Form.Item
                    name="passwordConfirm"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Password!',
                      },
                    ]}
                  >
                    <Input className={styels.textStyle}
                     
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Comfirm Password"
                    />
                  </Form.Item>
                
                  <Form.Item>
                  <div className={styels.textStyle} >
                  <Button style={{width:'100%'}} type="primary" htmlType="submit" className="login-form-button">
                    Update 
                    </Button>
                  </div>
                 
                  </Form.Item>
               </Form>
               {saving ? (
               <div
                className="sprinCustome"
             >
               <Spin size="large" />
             </div>
                ) : (
                      <div></div> 
                )}
              
             
          <div>
            
          </div>

      </div>
     
    );
 }

 export default SettingPage;