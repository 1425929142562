import React from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Select,
  DatePicker,
  Radio,
  Row,
  Col,
} from "antd";

const { Option } = Select;

const AgeGroup = () => {
  return (
    <Form.Item
      label={"អាយុ"}
      style={{
        marginBottom: 0,
        backgroundColor: "#e5e7e9",
        padding: 10,
        paddingBottom: 20,
        borderRadius: 10,
      }}
    >
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item
            label="អាយុ ០ ទៅ ២៨ ថ្ងៃ"
            name="age_0_28_days"
            style={{ marginBottom: 0 }}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="អាយុ ២៩ថ្ងៃ ទៅ ១១ខែ"
            name="age_29_days_11_months"
            style={{ marginBottom: 0 }}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="អាយុ ១ ទៅ ៤ ឆ្នាំ"
            name="age_1_4_years"
            style={{ marginBottom: 0 }}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="អាយុ ៥ ទៅ ១៤ឆ្នាំ"
            name="age_5_14_years"
            style={{ marginBottom: 0 }}
          >
            <InputNumber />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item
            label="អាយុ ១៥ ទៅ ២៤ឆ្នាំ"
            name="age_15_24_years"
            style={{ marginBottom: 0 }}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="អាយុ ២៥ ទៅ ៤៩ឆ្នាំ"
            name="age_25_49_years"
            style={{ marginBottom: 0 }}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="អាយុ ៥០ ទៅ ៦៤ ឆ្នាំ"
            name="age_50_64_years"
            style={{ marginBottom: 0 }}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="អាយុ ធំជាង ៦៥ឆ្នាំ"
            name="age_65_plus"
            style={{ marginBottom: 0 }}
          >
            <InputNumber />
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};

const PatientForm = () => {
  const onFinish = (values) => {
    console.log("Received values:", values);
    // Here you can handle the form submission, e.g., send the data to your backend
  };

  return (
    <Form onFinish={onFinish} layout="vertical">
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item
            label="លេខកូដអ្នកជំងឺ"
            name="patient_code"
            rules={[
              { required: true, message: "Please input the patient code!" },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="ឈ្មោះអ្នកជំងឺ"
            name="patient_name"
            rules={[
              { required: true, message: "Please input the patient name!" },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="ប្រភេទអ្នកជំងឺ" name="patient_type">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="ឈ្មោះអាណាព្យាបាល" name="guardian_name">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label="ភេទ" name="gender">
            <Radio.Group>
              <Radio value="Male">ប្រុស</Radio>
              <Radio value="Female">ស្រី</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="អាស័យដ្ឋានបច្ចុប្បន្ន" name="current_address">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="លេខទូស័ព្ទ" name="phone_number">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="មានផ្ទៃពោះ" name="is_pregnant">
            <Select placeholder="សូមជ្រើសរើស">
              <Option value="Yes">មាន</Option>
              <Option value="No">អត់មាន</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <AgeGroup />

      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label="រោគសញ្ញា" name="symptoms">
            <Input.TextArea />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="អមវេជ្ជសាស្រ្ត" name="medical_history">
            <Input.TextArea />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="រោគវិនិច្ឆ័យ" name="diagnosis">
            <Input.TextArea />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="ការព្យាបាល" name="treatment">
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label="កំពស់" name="height">
            <InputNumber step={0.01} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="បញ្ជូនទៅសម្រាកពេទ្យ" name="referred_to_hospital">
            <Select placeholder="សូមជ្រើសរើស">
              <Option value="Yes">មាន</Option>
              <Option value="No">អត់មាន</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="ប្រភេទនៃការបង់ថ្លៃសេវា" name="payment_type">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="សម្គាល់" name="notes">
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label="ថ្ងៃចេញរោគសញ្ញា" name="symptom_start_date">
            <DatePicker />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="ថ្ងៃចូលពេទ្យ" name="hospital_admission_date">
            <DatePicker />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="ថ្ងៃចេញពេទ្យ" name="hospital_discharge_date">
            <DatePicker />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          បញ្ជូន
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PatientForm;
