import moment from "moment";

const formateDateClient = (date, pattern = "DD/MM/YYYY") => {
  return moment(date).format(pattern);
};
const formateDateSystem = (date, pattern = "YYYY-MM-DD") => {
  return moment(date).format(pattern);
};
const formateDateClientTime = (date, pattern = "YYYY-MM-DD HH:mm:SS") => {
  return moment(date).format(pattern);
};
const timeGenerator = (date, pattern = "HH:mm:SS") => {
  return moment(date).format(pattern);
};
const timeGeneratorScan = (date, pattern = "HHmm") => {
  return moment(date).format(pattern);
};

//  for macbook
// const linkPath = "http://localhost/photo/hmis_staff/";
// const linkPathReport = "http://localhost/photo/report/";
// const base_url = "http://localhost:8083/api/";
// const linkPathPhotoDocument = "http://localhost/photo/document/";

//  for Server NameChape
const linkPath = "https://reportapi.devcomputing.pro/photo/hmis_staff/";
const base_url = "https://reportapi.devcomputing.pro/api/";
const linkPathReport = "https://reportapi.devcomputing.pro/photo/report/";
const linkPathIDCard = "https://reportapi.devcomputing.pro/photo/hmis_staff/";

const linkPathPhotoDocument =
  "https://reportapi.devcomputing.pro/photo/document/";

const toKhmerDay = (day) => {
  const khmerDigits = ["០", "១", "២", "៣", "៤", "៥", "៦", "៧", "៨", "៩"];
  return day
    .split("")
    .map((digit) => khmerDigits[digit])
    .join("");
};
const toKhmerMonth = (month) => {
  const khmerMonths = [
    "មករា",
    "កុម្ភៈ",
    "មិនា",
    "មេសា",
    "ឧសភា",
    "មិថុនា",
    "កក្កដា",
    "សីហា",
    "កញ្ញា",
    "តុលា",
    "វិច្ឆិកា",
    "ធ្នូ",
  ];
  return khmerMonths[month - 1];
};

const toKhmerYear = (year) => {
  const khmerDigits = ["០", "១", "២", "៣", "៤", "៥", "៦", "៧", "៨", "៩"];
  return year
    .split("")
    .map((digit) => khmerDigits[digit])
    .join("");
};
const khmerMonthsData = [
  "មករា",
  "កុម្ភៈ",
  "មិនា",
  "មេសា",
  "ឧសភា",
  "មិថុនា",
  "កក្កដា",
  "សីហា",
  "កញ្ញា",
  "តុលា",
  "វិច្ឆិកា",
  "ធ្នូ",
];
const toKhmerDayWeekend = (day) => {
  switch (day) {
    case "Monday":
      return "ថ្ងៃចន្ទ";
    case "Tuesday":
      return "ថ្ងៃអង្គារ";
    case "Wednesday":
      return "ថ្ងៃពុធ";
    case "Thursday":
      return "ថ្ងៃព្រហស្បតិ៍";
    case "Friday":
      return "ថ្ងៃសុក្រ";
    case "Saturday":
      return "ថ្ងៃសៅរ៍";
    case "Sunday":
      return "ថ្ងៃអាទិត្យ";
    default:
      return day;
  }
};

export {
  formateDateClient,
  linkPath,
  linkPathReport,
  base_url,
  formateDateClientTime,
  formateDateSystem,
  linkPathPhotoDocument,
  timeGenerator,
  timeGeneratorScan,
  toKhmerDay,
  toKhmerMonth,
  toKhmerYear,
  toKhmerDayWeekend,
  khmerMonthsData,
  linkPathIDCard,
};
