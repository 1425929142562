// export default Layout;

import React, { useState, useEffect } from "react";

import { LockOutlined } from "@ant-design/icons";
import { Input, Form, Button, Modal } from "antd";

import "../../styles/Home-module.css";
import "../../styles/FontKhmer.css";
import styels from "../../page/auth/LoginPage.module.css";
import { openNotification } from "../../share/message";
import { request } from "../../share/request";
import GetLoading from "../../share/getLoading";
import { getCurrentUser } from "../../share/getProfile";
import { Circles } from "react-loader-spinner";

const ChangePassword = (user) => {
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [changeComplete, setChangeComplete] = useState(false);
  const userProfile = getCurrentUser();
  const onFinish = async (values) => {
    try {
      var data = {
        username: userProfile.username,
        password: values.password,
        confirmPassword: values.passwordConfirm,
      };
      const res = await request("user/set-password", "post", data);
      // Simulate an asynchronous save operation (replace with your actual save logic)

      if (res.isSuccess) {
        setLoadingSpin(true);
        await new Promise((resolve) => setTimeout(resolve, 4000));
        setChangeComplete(true);
      } else if (values.password != values.confirmPassword) {
        openNotification("error", " password is not match !");
      }
    } catch (error) {
      openNotification("error", " password is not match !");
    } finally {
      setLoadingSpin(false);
    }
  };
  const onLogout = () => {
    if (changeComplete) {
      localStorage.setItem("isLogin", null || "");
      window.location.href = "/login";
    }
  };
  return (
    <div
      style={{
        color: "black",
        marginTop: 50,
        width: "100%",
        fontWeight: "bold",
      }}
    >
      <Modal open={changeComplete} title={"Success"} footer={false}>
        <div
          style={{
            backgroundColor: "#a9cce3",
            padding: 10,
            borderRadius: 5,
            color: "#186a3b",
            fontWeight: "bold",
          }}
        >
          លេខសង្ងាត់ត្រូវបានផ្លាល់ប្តូរជោគជ័យ​​ / Password has been Change!
        </div>
        <Button
          style={{ padding: 10, marginTop: 20, width: 100, fontWeight: "bold" }}
          type="primary"
          onClick={onLogout}
        >
          Login
        </Button>
      </Modal>

      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>

      <Form
        name="normal_login"
        onFinish={onFinish}
        initialValues={{
          remember: true,
        }}
      >
        <h3 style={{ padding: 10 }}>Change New Password</h3>
        <Form.Item
          className={styels.textStyle}
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input
            className={styels.textStyle}
            prefix={<LockOutlined />}
            type="password"
            placeholder="New Password"
          />
        </Form.Item>
        <Form.Item
          name="passwordConfirm"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input
            className={styels.textStyle}
            prefix={<LockOutlined />}
            type="password"
            placeholder="Comfirm New Password"
          />
        </Form.Item>

        <Form.Item>
          <div>
            <Button
              className={styels.textStyle}
              style={{ width: "100%" }}
              type="primary"
              htmlType="submit"
            >
              Update
            </Button>
          </div>
        </Form.Item>
      </Form>

      <div></div>
    </div>
  );
};

export default ChangePassword;
