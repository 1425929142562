import dataUser from './data.js'
function RegisterPage(){
    var x=10.343;
    var y=10;
    var name="Raksmey";
    var isActive =false;
    var arrPrice=[10,20,30,40,50];
    var arrName=["rak","smey","chan","tra"];
    var arrName1=["rak","smey",20,30,"chan","tra"];
    var arrLoop=[[11,22,33,44],20,30,40]
    var objPerson ={
        id: 1,
        name: "smey",
        gender: "Male",
        age: 32,
        children: [  "Bora", "Jon"  ]
    }

    var a=10;
    var b;
    var c=20;
    var grade="";
    var avg=20.9;
    if(a>100){
        b ="A more then 1000"
    }

    if (a>100){
        b ="A more then 1000"
    }else{
        b ="A small then 1000"
    }
        if (avg>100){
            grade="Grad A"
        }else if (avg>90){
            grade="Grad B"
        }else if (avg>70){
            grade="Grad C"
        }else if (avg>60){
            grade="Grad D"
        }else if (avg >50){
            grade="Grad E"
        }else{
            grade="Grad F"
        }
    return(



        <div>
               
            <h3>b={b}</h3>
            <h3>b={b}</h3>
            <h3>b={grade}</h3>
            <h2>{objPerson.children[0] + " "+objPerson.children[1]}</h2>
            <h1>{arrLoop[0][2]}</h1>
            <h1>{x}</h1>
            <h1>{y}</h1>
            <h1>{name}</h1>
            <h1>{isActive+""}</h1>
            <h1>{arrPrice[0] }</h1>
            <h1>{arrPrice.length }</h1>
            <h1>{arrPrice[arrPrice.length-1] }</h1>
            <h2>{objPerson.id} - {objPerson.name} -{objPerson.gender} -{objPerson.age}</h2>
            <h2>{objPerson.id +"-  "+objPerson.name+"-"+objPerson.gender + " "+objPerson.age}</h2>
            <h1> RegisterPage Page</h1>
        </div>
    );
 }

 export default RegisterPage;