
import axios from "axios"
import {useEffect, useState} from "react"
import Table from 'react-bootstrap/Table';
import { request } from "../../share/request";
import { Button,Modal,Stack,FloatingLabel, Form } from "react-bootstrap";

const CategryPage = () => {

    const [list,setList] = useState([])
    const [visible,setVisible] = useState(false)
    const [visabledelete,setvisabledelete] = useState(false)
    
    const [id,setId] = useState("")
    const [name,setName] = useState("")
    const [des,setDescriptoin] = useState("")
    const [status,setStatus] = useState("")

    useEffect(()=>{
        // block form load
        getList();
    },[]);

    const getList = async () => { // create function success
    
        const res = await request("category","get",{})
        if(res){
            setList(res.list)
        }
    }

    const onOpenModal = () => {
        setVisible(true)
    }

    const onCloseModal = () => {
        setVisible(false)
        setId("")
        setName("")
        setDescriptoin("")
        setStatus("")
    }

    const onSave = () => {
        if(id == ""){
            var data = {
                name : name, 
                des : des, 
                parent : null, 
                status : status
            }
            const res = request("category","post",data)
            onCloseModal()
            if(res){
                getList(); // re call function list 
            }else{
                alert("Error!")
            }
        }else{
            // update
            var data = {
                id : id,
                name : name, 
                des : des, 
                parent : null, 
                status : status
            }
            const res = request("category","put",data)
            onCloseModal()
            if(res){
                getList(); // re call function list 
            }else{
                alert("Error!")
            }
        }
        
    }

    const onChangeName = (event) => {
        setName(event.target.value)
    }

    const onChangeDes = (event) => {
        setDescriptoin(event.target.value)
    }

    const onChangeStatus = (event) => {
        setStatus(event.target.value)
    }

    const onDelete =  (item) => {
            setId(item.id);
            setvisabledelete(true);
       
    }

    const onClickEdit = (item) => {
        setId(item.id);
        setName(item.name);
        setDescriptoin(item.des);
        setStatus(item.status)
        setVisible(true) // just opent
    }

    const onclickDeleteNo=()=>{
        setvisabledelete(false);
    }
    const onClickDeleteYes= async  ()=>{
          console.log("ID delete is : "+id);
         const res =  await  request("category/"+id,"delete",{})
        if(res){
            getList();
        }else{
            alert("Error!")
        }
        setvisabledelete(false)
    }
    return (
        <div>
             
            <div style={{display:'flex',justifyContent:'space-between',padding:5}}>
                <div><h2>CategryPage</h2></div>
                <Button onClick={onOpenModal}>New Category</Button>
            </div>
            {/* Table Bootstarp */}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((item,index)=>(
                        <tr key={index}>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.des}</td>
                            <td>{item.status}</td>
                            <td style={{width:100}}>
                                  <Stack gap={1} direction="horizontal">
                                    <Button onClick={()=>onClickEdit(item)}>Edit</Button>{' '}
                                    <Button variant="danger" onClick={()=>onDelete(item)}>Delete</Button>
                                </Stack>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal show={visible} >
                <Modal.Header>
                    <Modal.Title>{(id == "") ? "New" : "Update"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FloatingLabel
                        controlId="name"
                        label="Category name"
                        className="mb-3"
                    >
                        <Form.Control onChange={onChangeName} value={name} type="text" placeholder="name" />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="des"
                        label="Description"
                        className="mb-3"
                    >
                        <Form.Control onChange={onChangeDes} value={des} type="text" placeholder="description" />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="status"
                        label="Status"
                        className="mb-3"
                    >
                        <Form.Control onChange={onChangeStatus} value={status} type="text" placeholder="status" />
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onCloseModal} variant="secondary">Cancel</Button>
                    <Button onClick={onSave} variant="primary">{id == "" ? "Save" : "Update"}</Button>
                </Modal.Footer>
            </Modal>  
            <Modal show={visabledelete}>
                <Modal.Header>
                    <div>
                        <h3>Delete Category</h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div>Do you want delete this Category?<p value={id}> {id}</p></div>
                </Modal.Body>
                <Modal.Footer>
                
                     
                        <Button onClick={onclickDeleteNo} variant="secondary">No</Button>
                        <Button onClick={()=>onClickDeleteYes() }  variant="primary">Yes</Button>
                    
                    
                  
                        </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CategryPage;