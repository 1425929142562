import React, { useEffect } from "react";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Card, Col, Row, Statistic } from "antd";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS

import StaffChart from "../chart/dashboard/staffChart";
import ProfileCard from "./ProfileCard";
const divStyle = {
  width: "100%",
};

const mediaQueryStyle = {
  "@media (min-width: 768px)": {
    width: "50%", // Adjust the width for larger screens
  },
};

const phoneStyle = {
  "@media (max-width: 767px)": {
    width: "100%", // Adjust the width for smaller screens (phones)
  },
};

const HomePage = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <div data-aos="zoom-in">
        <div>
          <div>
            <ProfileCard />
          </div>
        </div>
        {/* <div style={{ display: "flex" }}>
          <div style={{ ...divStyle, ...mediaQueryStyle, ...phoneStyle }}>
            <Card>
              <Statistic
                title="Staff Active"
                value={11.28}
                precision={2}
                valueStyle={{
                  color: "#3f8600",
                }}
                prefix={<ArrowUpOutlined />}
                suffix="%"
              />
            </Card>
          </div>

          <div style={{ ...divStyle, ...mediaQueryStyle, ...phoneStyle }}>
            <Card bordered={false}>
              <Statistic
                title="Staff Inactive"
                value={9.3}
                precision={2}
                valueStyle={{
                  color: "#cf1322",
                }}
                prefix={<ArrowDownOutlined />}
                suffix="%"
              />
            </Card>
          </div>

          <div style={{ ...divStyle, ...mediaQueryStyle, ...phoneStyle }}>
            <Card bordered={false}>
              <Statistic
                title="Staff Inactive"
                value={9.3}
                precision={2}
                valueStyle={{
                  color: "#cf1322",
                }}
                prefix={<ArrowDownOutlined />}
                suffix="%"
              />
            </Card>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ ...divStyle, ...mediaQueryStyle, ...phoneStyle }}>
            <Card>
              <Statistic
                title="Staff Active"
                value={11.28}
                precision={2}
                valueStyle={{
                  color: "#3f8600",
                }}
                prefix={<ArrowUpOutlined />}
                suffix="%"
              />
            </Card>
          </div>

          <div style={{ ...divStyle, ...mediaQueryStyle, ...phoneStyle }}>
            <Card bordered={false}>
              <Statistic
                title="Staff Inactive"
                value={9.3}
                precision={2}
                valueStyle={{
                  color: "#cf1322",
                }}
                prefix={<ArrowDownOutlined />}
                suffix="%"
              />
            </Card>
          </div>

          <div style={{ ...divStyle, ...mediaQueryStyle, ...phoneStyle }}>
            <Card bordered={false}>
              <Statistic
                title="Staff Inactive"
                value={9.3}
                precision={2}
                valueStyle={{
                  color: "#cf1322",
                }}
                prefix={<ArrowDownOutlined />}
                suffix="%"
              />
            </Card>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default HomePage;
