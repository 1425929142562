import { useEffect, useState } from "react";

import { request } from "../../share/request";

import {
  ApiTwoTone,
  CheckCircleOutlined,
  ClockCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Tabs } from "antd";

import "../../styles/Home-module.css";
import { openNotification } from "../../share/message";
import { Circles } from "react-loader-spinner";
import { getPermission } from "../../share/getProfile";
import BotTele from "./BotPage";
import SettingPage from "./SettingPage";

const TelegramBotPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);

  const [visableNewTelegramBot, setVisableNewTelegramBot] = useState(false);
  const [visableDeleteModel, setVisableDeleteModel] = useState(false);

  const [id, setID] = useState();
  const [type, setType] = useState("");
  const [tele_group_name, setTeleGroupName] = useState("");
  const [tele_token_id, setTelegramToken] = useState("");
  const [tele_grouid, setTelegramGroupID] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingSpin, setLoadingSpin] = useState(false);

  // -----------------  permisstion page --------------------
  const userPermission = getPermission();
  const deapartmentValue = userPermission.find(
    (permission) => permission.pagename === "department"
  ) || { add: 0, edit: 0, deleted: 0 };
  const {
    add: addvalue,
    edit: Editvalue,
    deleted: deletevalue,
  } = deapartmentValue;
  // -----------------  end permisstion page --------------------

  useEffect(() => {
    // block form load
    getList();
  }, []);

  const getList = async () => {
    // create function success
    try {
      await new Promise((resolve) => setTimeout(resolve, 400));
      const res = await request("telegrambot", "get", {});
      if (res) {
        setList(res.list_API_Server);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = list.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const buttonStyle = {
    fontFamily: "khmer OS Battambang ,sans-serif", // Replace 'YourFontFamily' with your desired font family
  };
  const custom_model = {
    fontFamily: "khmer OS Battambang ,sans-serif", // Replace 'YourFontFamily' with your desired font family
  };
  const onOpenNewTelegramBot = () => {
    //  setVisableNewTelegramBot(true);
  };
  const onCloseTelegramBot = () => {
    setVisableNewTelegramBot(false);
  };

  const onChangeType = (event) => {
    setType(event.target.value);
  };
  const onChangeTele_token_id = (event) => {
    setTelegramToken(event.target.value);
  };
  const onChangeTele_grouid = (event) => {
    setTelegramGroupID(event.target.value);
  };
  const onChageTeleGroupName = (event) => {
    setTeleGroupName(event.target.value);
  };

  const onSaveTelegramBot = async () => {
    //   if (id === "") {
    //       var data = {

    //           status: enable == true ? 1 : disable == true ? 0 : 1
    //       };

    //       try {
    //           const res = await request("department", "post", data);
    //           setVisableNewTelegramBot(false);

    //           if (res && res.success) {
    //               console.log(res.success);
    //               getList(); // re call function list
    //               setLoadingSpin(true)
    //               await new Promise((resolve) => setTimeout(resolve, 400));
    //               openNotification('success', 'Success', res.message);

    //           } else {
    //               openNotification('error', 'Error', res.message || 'Something went wrong');
    //           }
    //       } catch (error) {
    //           console.error('Error:', error);
    //           openNotification('error', 'Error', 'Something went wrong');
    //       }finally{
    //         setLoadingSpin(false)
    //       }
    //   } else {
    var data = {
      id: id,
      type: type,
      group_name: tele_group_name,
      group_id: tele_grouid,
      bot_name: tele_token_id,
      status: enable == true ? 1 : disable == true ? 0 : 1,
    };

    try {
      const res = await request("telegrambot", "put", data);
      setVisableNewTelegramBot(false);

      if (res && res.success) {
        getList();
        setLoadingSpin(true);
        await new Promise((resolve) => setTimeout(resolve, 400));
        openNotification("success", "Success", "Update Success");
        // re call function list
      } else {
        openNotification(
          "error",
          "Error",
          res.message || "Something went wrong"
        );
      }
    } catch (error) {
      console.error("Error:", error);
      openNotification("error", "Error", "Something went wrong");
    } finally {
      setLoadingSpin(false);
    }
    //   }
  };

  const onOpenEditModel = (item) => {
    setVisableNewTelegramBot(true);
    setID(item.id);
    setType(item.type);
    setTeleGroupName(item.group_name);
    setTelegramToken(item.bot_name);
    setTelegramGroupID(item.group_id);

    if (item.status === 1) {
      return setEnable(true), setDisable(false);
    } else {
      return setEnable(false), setDisable(true);
    }
  };
  const onclickDeleteNo = () => {
    setVisableDeleteModel(false);
  };

  const onOpenModelDelete = (item) => {
    setVisableDeleteModel(true);
  };

  const getStatusContent = (status) => {
    switch (status) {
      case 1:
        return (
          <Button
            type="primary"
            ghost
            shape="round"
            icon={<CheckCircleOutlined />}
          >
            {" "}
            Enable{" "}
          </Button>
        );
      case 0:
        return (
          <Button
            type="primary"
            danger
            ghost
            shape="round"
            icon={<ClockCircleOutlined />}
          >
            {" "}
            Disable{" "}
          </Button>
        );

      default:
        return null;
    }
  };

  //-------------   enable and disable ---------------

  const [enable, setEnable] = useState(false);
  const [disable, setDisable] = useState(false);
  const handleCheckboxChange_Enable = (e) => {
    setEnable(e.target.checked);
    setDisable(false);
  };
  const handleCheckboxChange_Diable = (e) => {
    setDisable(e.target.checked);
    setEnable(false);
  };
  //--------------------------------------------
  const onCancel = () => {
    setVisableNewTelegramBot(false);
  };
  const onCancelDelete = () => {
    setVisableDeleteModel(false);
  };

  const onChangeTab = (key) => {
    console.log("onChangeTab ", key);
  };
  const items = [
    // {
    //   key: "1",
    //   label: "Telegram API Bot",
    //   children: <BotTele />,
    //   icon: <ApiTwoTone />,
    // },
    {
      key: "2",
      label: "ការកំណត់ / Setting ",
      children: <SettingPage />,
      icon: <SettingOutlined />,
    },
  ];
  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>
      <Tabs items={items} />
    </div>
  );
};

export default TelegramBotPage;
