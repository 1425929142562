import { useEffect, useState, useRef } from "react";
import { openNotification } from "../../share/message";
import {
  PlusCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  UsergroupAddOutlined,
  SearchOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  Table,
  Modal,
  Button,
  Form,
  Input,
  Item,
  UserOutlined,
  Select,
  Space,
  Tag,
  Checkbox,
  Spin,
  Pagination,
  List,
  Row,
  Col,
} from "antd";
import "../../styles/Home-module.css";

import { Circles } from "react-loader-spinner";
import { request } from "../../share/request";
import {
  getStatusCheckbox,
  getStatusContent,
  getTageStyle,
} from "../../share/getStatusContent";
import {
  CiSquareCheck,
  CiStop1,
  CiSquareMinus,
  CiSquarePlus,
} from "react-icons/ci";
import { CgClose } from "react-icons/cg";
import { Option } from "antd/es/mentions";
import { getCurrentUser, getPermission } from "../../share/getProfile";
import { formateDateClientTime } from "../../share/helper";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";
const RolePage = () => {
  const [enable, setEnable] = useState(false);
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [List, setListRole] = useState([]);
  const [visableModelDelete, setVisableModelDelete] = useState(false);
  const [visableModelEdit, SetVisableModelEdit] = useState(false);
  const [roleid, setRoleID] = useState(null);
  const [getListPage, SetGetListPage] = useState([]);
  const [pagename_form, SetPagename_form] = useState(null);
  const [visableModelAddNew, SetVisableModelAddNew] = useState(false);

  const [formRef] = Form.useForm();

  const userProfile = getCurrentUser();

  const [objUpdateRole, setObjUpdateRole] = useState({
    id: 1,
    columnsname: "",
    valuerole: 1,
  });

  // -----------------  permisstion page --------------------
  const userPermission = getPermission();
  const RoleValue = userPermission.find(
    (permission) => permission.pagename === "role"
  ) || { add: 0, edit: 0, deleted: 0 };
  const { add: addvalue, edit: Editvalue, deleted: deletevalue } = RoleValue;

  // -----------------  end permisstion page --------------------

  const getList = async () => {
    const res = await request("role", "get", {});
    try {
      await new Promise((resolve) => setTimeout(resolve, 400));
      if (res) {
        setListRole(res.getlist);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const getData = async () => {
    const res = await request("role", "get", {});
    if (res) {
      setListRole(res.getlist);
    }
  };

  const columns = [
    {
      key: "role_id",
      title: "លេខកូដ",
      dataIndex: "role_id",
      render: (value, item, index) => getTageStyle(index + 1),
    },
    {
      key: "name",
      title: "ឈ្មោះសិទ្ធ",
      dataIndex: "name",
    },

    {
      key: "code",
      title: "កូដសិទ្ធ",
      dataIndex: "code",
    },
    {
      key: "usercreate",
      title: "អ្នកបង្កើត",
      dataIndex: "usercreate",
      render: (text, record, index) => {
        return (
          <div>
            <Tag color="#2471a3" className="khmer-font">
              {record.usercreate}
            </Tag>
          </div>
        );
      },
    },
    {
      key: "status",
      title: "ស្ថានភាព",
      dataIndex: "status",
      render: getStatusContent,
    },
    {
      key: "datecreate",
      title: "ថ្ងៃបង្កើត",
      dataIndex: "datecreate",
      render: getTageStyle,
    },
    {
      key: "action",
      title: "សកម្មភាព",
      dataIndex: "action",
      render: (value, item, index) => {
        return (
          <div>
            <Space key={index}>
              <Button
                type="primary"
                disabled={!Editvalue}
                onClick={() => onClickEditModel(item)}
              >
                Edit
              </Button>
              <Button
                danger
                type="primary"
                disabled={!deletevalue}
                onClick={() => onDelete(item)}
                icon={<CloseOutlined />}
              />
            </Space>
          </div>
        );
      },
    },
  ];

  const onDelete = (item) => {
    setVisableModelDelete(true);
    setRoleID(item.role_id);
  };
  const onCancelDelete = () => {
    setVisableModelDelete(false);
  };
  const onClickNo = () => {
    setVisableModelDelete(false);
  };
  const onComfirmDelete = async () => {
    const res = await request("role/" + roleid, "delete", {});

    try {
      if (res.success) {
        setVisableModelDelete(false);
        setLoadingSpin(true);
        await new Promise((resolve) => setTimeout(resolve, 400));
        openNotification("success", "ជោគជ័យ / Success", res.message);
        getData();
      } else {
        setVisableModelDelete(false);
        openNotification("error", "មិនអាចលុបបាន / Error", res.message);
      }
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };
  const onCancelEdit = () => {
    SetVisableModelEdit(false);
  };
  const [getListUserManagment, setGetListUserManagment] = useState([]);
  const [pageshowvalue, setPageShowValue] = useState(null);
  const [id, setID] = useState();
  const onClickEditModel = async (item) => {
    setLoadingSpin(true);
    setID(item.id);
    setRoleID(item.role_id);
    setPageShowValue(item.pageshow);
    console.log(item.role_id);
    const res = await request(`role/${item.role_id}`, "get", {});
    console.log(res);
    if (res) {
      setGetListUserManagment(res.listusermanagment);
      SetGetListPage(res.listpgaenamedata);
    }
    SetVisableModelEdit(true);
    setLoadingSpin(false);
  };
  const onClickNoPageEdit = () => {
    SetVisableModelEdit(false);
  };
  const onComfirmDeletePageEdit = () => {};
  const ColumnsUserManagement = [
    {
      key: "id",
      title: "លេខកូដ",
      dataIndex: "id",
      render: (value, item, index) => {
        return (
          <dvi>
            <span
              onClick={() => onClickDeteUserRole(item)}
              style={{ cursor: "pointer" }}
            >
              <CiSquareMinus
                style={{ height: 30, width: 30, color: "rgb(162, 14, 41)" }}
              />
            </span>
            {index + 1}
          </dvi>
        );
      },
    },

    {
      key: "pagename",
      title: "ឈ្មោះ Page",
      dataIndex: "pagename",
    },
    {
      key: "Page Show",
      title: "Page Show",
      dataIndex: "pageshow",
      render: (index, item) => {
        return (
          <div>
            <span
              onClick={() => onCLickChange(item, "pageshow")}
              style={{ cursor: "pointer" }}
            >
              {getStatusIcon(item, "pageshow")}
            </span>
          </div>
        );
      },
    },
    {
      key: "add",
      title: "Add",
      dataIndex: "add",
      render: (index, item) => {
        return (
          <div>
            <span
              onClick={() => onCLickChange(item, "add")}
              style={{ cursor: "pointer" }}
            >
              {getStatusIcon(item, "add")}
            </span>
          </div>
        );
      },
    },
    {
      key: "edit",
      title: "Edit",
      dataIndex: "edit",
      render: (index, item) => {
        return (
          <div>
            <span
              onClick={() => onCLickChange(item, "edit")}
              style={{ cursor: "pointer" }}
            >
              {getStatusIcon(item, "edit")}
            </span>
          </div>
        );
      },
    },
    {
      key: "deleted",
      title: "Deleted",
      dataIndex: "deleted",
      render: (index, item) => {
        return (
          <div>
            <span
              onClick={() => onCLickChange(item, "deleted")}
              style={{ cursor: "pointer" }}
            >
              {getStatusIcon(item, "deleted")}
            </span>
          </div>
        );
      },
    },
    {
      key: "print",
      title: "Print",
      dataIndex: "print",
      render: (index, item) => {
        return (
          <div>
            <span
              onClick={() => onCLickChange(item, "print")}
              style={{ cursor: "pointer" }}
            >
              {getStatusIcon(item, "print")}
            </span>
          </div>
        );
      },
    },
  ];
  const [pageshowStatusMap, setPageshowStatusMap] = useState({});

  const [addStatusMap, setAddStatusMap] = useState({});
  // const onCLickChangePageShow = async (item) => {
  //     const valuerole_form = item.pageshow;
  //     console.log("this change:", valuerole_form === 1 ? 0 : 1);

  //     const data = {
  //       id: item.id,
  //       columnname: "pageshow",
  //       valuerole: valuerole_form === 1 ? 0 : 1,
  //     };

  //     console.log(data);

  //     const res = await request("role/updaterole", "put", data);

  //     if (res) {
  //         // Update the local state for the specific row based on the response
  //         setPageshowStatusMap((prevMap) => ({
  //           ...prevMap,
  //           [item.id]: valuerole_form === 1 ? 0 : 1,
  //         }));

  //         // Update the specific item in the list immediately
  //         setGetListUserManagment((prevList) =>
  //           prevList.map((prevItem) =>
  //             prevItem.id === item.id ? { ...prevItem, pageshow: valuerole_form === 1 ? 0 : 1 } : prevItem
  //           )
  //         );
  //       }
  //   };

  // const onCLickChange = async (item, columnName) => {
  //     const value = item[columnName];
  //     console.log("column name " + columnName)
  //     const data = {
  //       id: item.id,
  //       columnname: columnName,
  //       valuerole: value === 1 ? 0 : 1,
  //     };

  //     const res = await request('role/updaterole', 'put', data);

  //     if (res) {
  //       // Update the local state for the specific row based on the response
  //       if (columnName === 'pageshow') {
  //         setPageshowStatusMap((prevMap) => ({
  //           ...prevMap,
  //           [item.id]: value === 1 ? 0 : 1,
  //         }));
  //       } else if (columnName === 'add') {
  //         setAddStatusMap((prevMap) => ({
  //           ...prevMap,
  //           [item.id]: value === 1 ? 0 : 1,
  //         }));
  //       }

  //       // Update the specific item in the list immediately
  //       setGetListUserManagment((prevList) =>
  //       prevList.map((prevItem) =>
  //         prevItem.id === item.id ? { ...prevItem, [columnName]: value === 1 ? 0 : 1 } : prevItem
  //       )
  //     );
  //     }
  //   };
  const [buttonStatusMap, setButtonStatusMap] = useState({});
  const onCLickChange = async (item, columnName) => {
    const value = item[columnName];
    const data = {
      id: item.id,
      columnname: columnName,
      valuerole: value === 1 ? 0 : 1,
    };

    const res = await request("role/updaterole", "put", data);

    if (res) {
      setButtonStatusMap((prevMap) => ({
        ...prevMap,
        [`${columnName}_${item.id}`]: value === 1 ? 0 : 1,
      }));

      setGetListUserManagment((prevList) =>
        prevList.map((prevItem) =>
          prevItem.id === item.id
            ? { ...prevItem, [columnName]: value === 1 ? 0 : 1 }
            : prevItem
        )
      );
    }
  };

  const getStatusIcon = (item, columnName) => {
    const value = buttonStatusMap[`${columnName}_${item.id}`];
    return value === 1 ? (
      <CiSquareCheck style={{ width: 30, height: 30 }} />
    ) : (
      <CiStop1 style={{ width: 30, height: 30 }} />
    );
  };

  useEffect(() => {
    const initialButtonStatusMap = {};
    getListUserManagment.forEach((item) => {
      initialButtonStatusMap[`pageshow_${item.id}`] = item.pageshow;
      initialButtonStatusMap[`add_${item.id}`] = item.add;
      initialButtonStatusMap[`edit_${item.id}`] = item.edit;
      initialButtonStatusMap[`deleted_${item.id}`] = item.deleted;
      initialButtonStatusMap[`print_${item.id}`] = item.print;
      // Add more buttons as needed
    });
    setButtonStatusMap(initialButtonStatusMap);
  }, [getListUserManagment]);
  const onClickDeteUserRole = async (item) => {
    const res = await request("rolemanagment/" + item.id, "delete", {});
    if (res.success) {
      openNotification("success", "ជោគជ័យ / Success", res.message);
      setGetListUserManagment((prevList) =>
        prevList.filter((role) => role.id !== item.id)
      );
      const updateSelectPage = await request(`rolepage/${roleid}`, "get", {});
      if (updateSelectPage) {
        SetPagename_form(null);
        SetGetListPage(updateSelectPage.listpagename);
      }
    } else {
      openNotification("error", "មិនអាចលុបបាន / Error", res.message);
    }
  };

  const onClickAddUserRole = async () => {
    if (pagename_form == null) {
      openNotification("error", "Error", "Page is not null!");
    } else {
      const data = {
        role_id: roleid,
        pagename: pagename_form,
        pageshow: 0,
        add: 0,
        edit: 0,
        deleted: 0,
        print: 0,
      };
      const res = await request("role", "post", data);
      if (res.success) {
        openNotification("success", "ជោគជ័យ / Success", res.message);
        const updatedRes = await request(`role/${roleid}`, "get", {});

        if (updatedRes) {
          setGetListUserManagment(updatedRes.listusermanagment);
          const updateSelectPage = await request(
            `rolepage/${roleid}`,
            "get",
            {}
          );
          if (updateSelectPage) {
            SetPagename_form(null);
            SetGetListPage(updateSelectPage.listpagename);
          }
        }
      }
    }
  };
  const onChagePagename = (value) => {
    SetPagename_form(value);
  };
  const onCancelAdd = () => {
    SetVisableModelAddNew(false);
  };
  const onOpenAddNew = () => {
    setNameMaster("");
    setCodeMaster("");
    SetVisableModelAddNew(true);
  };

  const handleCheckboxChange_Enable = (e) => {
    setEnable(e.target.checked);
    setDisable(false);
  };
  const handleCheckboxChange_Diable = (e) => {
    setDisable(e.target.checked);
    setEnable(false);
  };
  //--------------------------------------------
  const [namemaster, setNameMaster] = useState("");
  const [codemaster, setCodeMaster] = useState("");
  const onSaveRoleMaster = async () => {
    var currentDate = new Date();
    var data = {
      name: namemaster,
      code: codemaster,
      usercreate: userProfile.fullname,
      status: enable,
      datecreate: formateDateClientTime(currentDate),
    };

    var method = "post";
    const res = await request("rolemaster", method, data);
    if (res) {
      console.log(res.message);
      SetVisableModelAddNew(false);
      openNotification("success", "ជោគជ័យ / Success", res.message);
      getList();
    }
  };
  const onChangeName = (value) => {
    setNameMaster(value.target.value);
  };
  const onChangeCode = (value) => {
    setCodeMaster(value.target.value);
  };
  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>

      <div style={boxHeader}>
        <div style={{ display: "flex" }}>
          <Space>
            {boxHeaderTagText("ការគ្រប់គ្រង់ ព៏តមានសិទ្ធ")}
            <div>
              <Input.Search></Input.Search>
            </div>
          </Space>
        </div>
        <div style={{ display: "flex" }}>
          <Space>
            <Button
              className="buttonstylekhmer"
              type="primary"
              disabled={!addvalue}
              onClick={onOpenAddNew}
              icon={<PlusCircleOutlined />}
            >
              បង្កើតអ្នកផ្តល់សិទ្ធថ្មី
            </Button>
          </Space>
        </div>
      </div>
      <div>
        {loading ? (
          <div className="sprinCustome">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Table
            pagination={{
              pageSize: 10,
            }}
            columns={columns}
            size="small"
            dataSource={List}
            rowClassName={(record) =>
              record.name == "DBMA" && userProfile.username !== "0885290240"
                ? "disabled-row"
                : ""
            }
          />
        )}
      </div>

      <Modal
        title="លុបសិទ្ធ ចេញពីប្រព័ន្ធ "
        className="custom-form-item"
        open={visableModelDelete}
        onCancel={onCancelDelete}
        footer={[
          <div>
            <Button onClick={onClickNo}>No</Button>
            <Button onClick={onComfirmDelete} type="primary">
              Yes
            </Button>
          </div>,
        ]}
      >
        <p className="custom-form-item" style={{ color: "red" }}>
          តើអ្នកចង់លុប សិទ្ធនេះចេញពីប្រព័ន្ធ ?
        </p>
      </Modal>

      <Modal
        title=" កែប្រែការផ្តល់សិទ្ធ  "
        className="custom-form-item"
        open={visableModelEdit}
        onCancel={onCancelEdit}
        width={1000}
        footer={[
          <div>
            <Button
              onClick={onClickNoPageEdit}
              className="buttonstylekhmer"
              type="primary"
            >
              Close
            </Button>
          </div>,
        ]}
      >
        <div>
          <Space>
            <span
              onClick={() => onClickAddUserRole()}
              style={{ cursor: "pointer" }}
            >
              <CiSquarePlus
                style={{ height: 35, width: 35, color: "rgb(11, 145, 112)" }}
              />
            </span>
            <span>
              <Select
                value={pagename_form}
                onChange={onChagePagename}
                style={{ width: 200 }}
              >
                {getListPage
                  .filter(
                    (item) =>
                      item.pagename !== "System Managments" ||
                      userProfile.username === "0885290240"
                  )
                  .map((item) => (
                    <Option value={item.pagecode}>{item.pagename}</Option>
                  ))}
              </Select>
            </span>
          </Space>
        </div>

        <Table
          pagination={{
            pageSize: 10,
          }}
          columns={ColumnsUserManagement}
          size="small"
          dataSource={getListUserManagment}
        />
        <div style={{ display: "flex" }}></div>
      </Modal>

      <div>
        <Modal
          title=" បង្កើតថ្មី / New  "
          className="custom-form-item"
          open={visableModelAddNew}
          onCancel={onCancelAdd}
          width={600}
          footer={[
            <div>
              <Space>
                <Button onClick={onCancelAdd}>Close</Button>
                <Button type="primary" onClick={onSaveRoleMaster}>
                  {formRef.getFieldValue("Id") ? "Update" : "Save"}
                </Button>
              </Space>
            </div>,
          ]}
        >
          <div>
            <Form className="ant-table-content" form={formRef}>
              <div style={{ display: "flex" }}>
                <div style={{ paddingLeft: 20 }}>
                  <Form.Item name="name">
                    <p
                      className="ant-table-content"
                      style={{ marginBottom: 5 }}
                    >
                      ឈ្មោះ / Name *{" "}
                    </p>
                    <Input
                      className="ant-table-content"
                      onChange={onChangeName}
                      placeholder="ឈ្មោះ / Name"
                      style={{ width: 250 }}
                    />
                  </Form.Item>

                  <Form.Item name="code">
                    <p
                      className="ant-table-content"
                      style={{ marginBottom: 5 }}
                    >
                      កូដសិទ្ធ / Code *{" "}
                    </p>
                    <Input
                      className="ant-table-content"
                      onChange={onChangeCode}
                      placeholder="កូដសិទ្ធ / Code"
                      style={{ width: 250 }}
                    />
                  </Form.Item>
                </div>

                <div style={{ paddingLeft: 20 }}>
                  <p style={{ marginBottom: 0 }}>ស្ថានភាព / Status</p>

                  <Tag style={{ marginTop: 10 }}>
                    <div style={{ padding: 10, width: 235 }}>
                      <Checkbox
                        checked={enable}
                        onChange={handleCheckboxChange_Enable}
                      >
                        Enable
                      </Checkbox>
                      <Checkbox
                        style={{ color: "red" }}
                        checked={disable}
                        onChange={handleCheckboxChange_Diable}
                      >
                        Disable
                      </Checkbox>
                    </div>
                  </Tag>
                </div>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default RolePage;
