import React, { useEffect, useState, useRef } from "react";
import { openNotification } from "../../share/message";
import {
  PlusCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  UsergroupAddOutlined,
  SearchOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  CloseOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Table,
  Modal,
  Button,
  Form,
  Input,
  Item,
  UserOutlined,
  Select,
  Space,
  Tag,
  Checkbox,
  Spin,
  Pagination,
  DatePicker,
} from "antd";
import "../../styles/Home-module.css";
import "../../styles/FontKhmer.css";
import "../../styles/model-style.css";
import "./Model-Setting-Grid.css";

import style from "../../styles/style.module.css";
import { request } from "../../share/request";
import { formateDateClient, formateDateSystem } from "../../share/helper";
import { Circles } from "react-loader-spinner";
import ComponentToPrint from "./PrintComponent";
import { useReactToPrint } from "react-to-print";
import { getPermission, getCurrentUser } from "../../share/getProfile";
import { getTageStyle } from "../../share/getStatusContent";
import { BsDownload } from "react-icons/bs";
import queryString from "query-string";
import dayjs from "dayjs";
//  for export data to excel
import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import MyDatePicker from "../../share/MyDatePicker";
import moment from "moment";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";
import { render } from "react-dom";
// -----------------  end import --------------------
const LeavePage = ({
  getUser,
  txtSearchVisable,
  getIDnumber,
  monthlyReport,
}) => {
  const [excelClicked, setExcelClicked] = useState(false);
  const [monthlyReportClicked, setMonthlyReportClicked] = useState(false);
  // -----------------  permisstion page --------------------
  const userPermission = getPermission();
  const leaveReportValue = userPermission.find(
    (permission) => permission.pagename === "leavereport"
  ) || { add: 0, edit: 0, deleted: 0, print: 0 };
  const {
    add: Addvalue,
    edit: Editvalue,
    deleted: Deletevalue,
    print: Printvalue,
  } = leaveReportValue;
  // -----------------  end permisstion page --------------------
  const getProfile = getCurrentUser();
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [id, setid] = useState("");
  const [name, setName] = useState("");
  const [date_request, setDateRequest] = useState("");
  const [detestart, setDateSate] = useState("");
  const [dateend, setDateEnd] = useState("");

  const [UserDateRequest, setUserDateRequest] = useState(dayjs());
  const [UserDateStart, setUserDateStart] = useState(dayjs());
  const [UserDateEnd, setUserDateEnd] = useState(dayjs());
  const [UserDateJoinWork, setUserDateJoinWork] = useState(dayjs());
  const [UserStatus, setUserStatus] = useState("Pending");
  const [UserLeaveType, setUserLeaveType] = useState("");
  const [UserNumberLeave, setUserNumberLeave] = useState("");
  const [UserStatus_Search, setUserStatus_Search] = useState("");
  const [objFilter, setObjFilter] = useState({
    page: 1,
    txtSearch: getUser === undefined ? "" : getUser,
    txtStatus: "",

    org_code: getProfile.org_code,
  });
  const componentRef = useRef(null);
  const [itemPrint, setItemPrint] = useState(null);
  const [shouldPrint, setShouldPrint] = useState(false);
  const [dataCount, setDataCount] = useState([]);
  const [dataCountLeaveShort, setDataCountLeaveShort] = useState([]);
  const [dataCountLeavePerson, SetDataCountLeavePerson] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [totalPage, setTotalPage] = useState(null);
  const [DownloadReport, setDownloadReport] = useState(false);
  const [ListLeaveType, setListLeaveType] = useState([]);
  const [ListNumberLeave, setListNumberLeave] = useState([]);
  const [list_staff, setList_staff] = useState([]);
  const [imagePre_LeaveTemplate_v1, setImagePreLeaveTemplate_v1] =
    useState(null);
  // ------------------ update model -----------
  const [visableModelUpdate, setVisableModelUpdate] = useState(false);
  const [listApprov, setListApprov] = useState([]);
  const [approvedby, setApproved] = useState("");
  const [username, setUserName] = useState("");
  const { Option } = Select;
  const [txt_reason, setReason] = useState("មានធុរះផ្ទាល់ខ្លួន ។");
  useEffect(() => {
    if (getProfile.org_code) {
      getImageLinkOrganization();
      getListEmployee();
    }
    console.log("Image link", getProfile.org_code);
  }, [getProfile.org_code]);

  useEffect(() => {
    if (dataFetched && shouldPrint) {
      printFunction();
      setDataFetched(false); // Reset dataFetched after printing
      setShouldPrint(false); // Reset shouldPrint after printing
    }
  }, [dataFetched, shouldPrint]);

  useEffect(() => {
    console.log("Formatted Date:", dayjs(UserDateRequest).format("DD.MM.YYYY"));
    getList(objFilter);

    const fetchDataAndPrint = async () => {
      try {
        setDataFetched(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataAndPrint();
  }, [itemPrint]);

  const getList = async (ParamObjFilter) => {
    try {
      const params = new URLSearchParams({
        page: ParamObjFilter.page,
        txtSearch: ParamObjFilter.txtSearch,
        txtStatus: ParamObjFilter.txtStatus,
        org_code: ParamObjFilter.org_code,
      });

      await new Promise((resolve) => setTimeout(resolve, 400));
      const res = await request("leave?" + params.toString(), "get", {});
      console.log("leave name : ", getUser);
      if (res) {
        if (ParamObjFilter.txtSearch) {
          console.log("Filtered Data:", res.dataRequestLeave);
          setFilteredDataSource(res.dataRequestLeave);
        } else {
          console.log("Non-Filtered Data:", res.dataRequestLeave);
          setFilteredDataSource(res.dataRequestLeave);
        }

        setListApprov(res.dataApproved);
        setListLeaveType(res.ListLeaveType);
        setListNumberLeave(res.ListNumberLeave);

        if (ParamObjFilter.page === 1) {
          setTotalPage(res.pagecount[0].total);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false);
    }
  };
  const getImageLinkOrganization = async () => {
    const param = new URLSearchParams({ org_code: getProfile.org_code });
    try {
      const res = await request(
        "settingorgidcard?" + param.toString(),
        "get",
        {}
      );
      if (res && res.list_api) {
        const leaveTemplateV1 = res.list_api.find(
          (item) => item.param === "leave_v1"
        );
        if (leaveTemplateV1) {
          setImagePreLeaveTemplate_v1(leaveTemplateV1.imagelink);
        }
      }
    } catch (error) {
      console.error("Failed to fetch organization images:", error);
    }
  };

  // const getList=async(ParamObjFilter)=>{

  //   try {
  //     var param="?page="  + ParamObjFilter.page
  //     param +="&txtSearch="  +ParamObjFilter.txtSearch

  //     await new Promise((resolve) => setTimeout(resolve, 400));
  //     const res= await request("leave"+param,"get",{});
  //     const currentSearchText = searchTextRef.current;
  //         if(res){
  //           setList(res.dataRequestLeave);
  //           setFilteredDataSource(res.dataRequestLeave)
  //           setListApprov(res.dataApproved);

  //             if(ParamObjFilter.page==1){
  //              setTotalPage(res.pagecount[0].total)
  //             }

  //       }
  //  } catch (error) {
  //    console.error('Error fetching data:', error.message);
  //  } finally {
  //    setLoading(false);
  //  }

  // }
  const itemsPerPage = 10;
  const columns = [
    {
      key: "id",
      title: "No",
      // dataIndex: 'id',
      render: (value, item, index) =>
        index + 1 + itemsPerPage * (objFilter.page - 1),
    },

    {
      key: "staff_name",
      title: "ឈ្មោះបុគ្គលិក",
      dataIndex: "fullname",
      sorter: (a, b) => a.fullname.localeCompare(b.fullname),
      render: (text, record, index) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ color: "#186a3b" }}>{record.fullname}</span>
            <span style={{ fontSize: 8, fontWeight: "bold", color: "#BFBFBF" }}>
              ID:{record.idnumber}
            </span>
          </div>
        );
      },
    },
    {
      key: "department",
      title: "ផ្នែក",
      dataIndex: "department_name",
      render: (text, record, index) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ color: "#186a3b" }}>{record.department_name}</span>
            <span style={{ fontSize: 8, fontWeight: "bold", color: "#BFBFBF" }}>
              {record.position_kh}
            </span>
          </div>
        );
      },
    },
    {
      key: "leave_type",
      title: "ប្រភេទច្បាប់",
      dataIndex: "type_leave",
      onCell: () => {
        return {
          style: {
            whiteSpace: "nowrap",
          },
        };
      },
    },
    {
      key: "date_request",
      title: "ថ្ងៃស្នើរច្បាប់",
      dataIndex: "date_request",
      render: (value) => {
        return <div>{dayjs(value).format("DD/MM/YYYY")}</div>;
      },
    },
    {
      key: "date_start",
      title: "ចាប់ផ្តើម",
      dataIndex: "date_start",
      render: (value) => {
        return <div>{dayjs(value).format("DD/MM/YYYY")}</div>;
      },
    },
    {
      key: "date_end",
      title: "ដល់ថ្ងៃទី",
      dataIndex: "date_end",
      render: (value) => {
        return <div>{dayjs(value).format("DD/MM/YYYY")}</div>;
      },
    },
    {
      key: "date_inwork",
      title: "ថ្ងៃចូលធ្វើរការ",
      dataIndex: "date_inwork",
      render: (value) => {
        return <div>{dayjs(value).format("DD/MM/YYYY")}</div>;
      },
    },
    {
      key: "num_leave",
      title: "ចំនួនច្បាប់",
      dataIndex: "num_leave",
      render: getTageStyle,
      onCell: () => {
        return {
          style: {
            whiteSpace: "nowrap",
          },
        };
      },
    },
    {
      key: "status",
      title: "ស្ថានភាព",
      dataIndex: "status",
      render: getStatusContent,
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      key: "approved_by",
      title: "អនុញ្ញាច្បាប់ដោយ",
      dataIndex: "approvd_admin",
      render: (text, record, index) => {
        return (
          <Tag
            color={record.status === "Approved" ? "success" : "error"}
            style={styleTageStatus}
            icon={
              record.status === "Approved" ? (
                <CheckCircleOutlined />
              ) : (
                <CloseCircleOutlined />
              )
            }
          >
            {record.approvd_admin}
          </Tag>
        );
      },
    },
    {
      key: "action",
      title: "សកម្មភាព",
      dataIndex: "action",
      render: (value, item, index) => {
        return (
          <div>
            <Space key={index}>
              <Button
                type="primary"
                //  disabled={!Editvalue}
                onClick={() => onOpenModelEdit(item)}
                icon={<EditOutlined />}
              />
              <Button
                danger
                type="primary"
                //    disabled={!Deletevalue}
                onClick={() => onOpenModelDelete(item)}
                icon={<CloseOutlined />}
              />
              <div className="print-container">
                <MyComponentToPrint
                  ref={componentRef}
                  key={index}
                  item={itemPrint}
                />
                <Button
                  danger
                  type="primary"
                  //  disabled={!Printvalue}
                  onClick={() => handlePrint(item)}
                  icon={<DownloadOutlined />}
                />
              </div>
            </Space>
          </div>
        );
      },
    },
  ];

  const handlePrint = async (item) => {
    try {
      setLoadingSpin(true);
      const response = await request("user/" + item.id, "get", {});

      setDataCount(response.GetOneRecord[0][0]);
      setDataCountLeaveShort(response.GetOneRecord[1][0]);
      SetDataCountLeavePerson(response.GetOneRecord[2][0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingSpin(false);
      setItemPrint(item);
      console.log("list print : " + JSON.stringify(itemPrint));
      setShouldPrint(true);
    }
  };

  const printFunction = useReactToPrint({
    content: () => componentRef.current,
  });

  class MyComponentToPrint extends React.Component {
    render() {
      return (
        <div className="print-content">
          {itemPrint && (
            <ComponentToPrint
              name={itemPrint.fullname}
              gender={itemPrint.gender}
              idnumber={itemPrint.idnumber}
              position_p={itemPrint.position_kh}
              phone_p={itemPrint.username}
              date_request_p={
                itemPrint.date_request
                  ? dayjs(itemPrint.date_request).format("DD/MM/YYYY")
                  : ""
              }
              date_start_p={
                itemPrint.date_start
                  ? dayjs(itemPrint.date_start).format("DD/MM/YYYY")
                  : ""
              }
              date_end_p={
                itemPrint.date_end
                  ? dayjs(itemPrint.date_end).format("DD/MM/YYYY")
                  : ""
              }
              date_work_p={
                itemPrint.date_inwork
                  ? dayjs(itemPrint.date_inwork).format("DD/MM/YYYY")
                  : ""
              }
              reason_p={itemPrint.reason}
              Num_using={dataCount.Num_using}
              Num_Remaining={dataCount.Num_Remaining}
              Num_Request={dataCount.Num_Request}
              Num_using_short={dataCountLeaveShort.Num_using_short}
              Num_Remaining_short={dataCountLeaveShort.Num_Remaining_short}
              Num_Request_short={dataCountLeaveShort.Num_Request_short}
              Num_using_person={dataCountLeavePerson.Num_using_person}
              Num_Remaining_person={dataCountLeavePerson.Num_Remaining_person}
              Num_Request_person={dataCountLeavePerson.Num_Request_person}
              template_org={imagePre_LeaveTemplate_v1}
            />
          )}
        </div>
      );
    }
  }

  function getStatusContent(status) {
    switch (status) {
      case "Approved":
        return (
          <Tag
            color="success"
            style={styleTageStatus}
            icon={<CheckCircleOutlined />}
          >
            Approved
          </Tag>
        );
      case "Pending":
        return (
          <Tag
            color="error"
            style={styleTageStatus}
            icon={<ClockCircleOutlined />}
          >
            Pending
          </Tag>
        );
      default:
        return null;
    }
  }

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };
  const [sortedInfo, setSortedInfo] = useState({});
  const styleTageStatus = {
    fontSize: "14px",
    height: "30px",
    borderRadius: "30px",
    paddingTop: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
  };
  //----------------  open Model Delete ---------
  const [visableModelDelete, setVisableModelDelete] = useState(false);
  const onOpenModelDelete = (item) => {
    setVisableModelDelete(true);
    setid(item.id);
    setName(item.fullname);
    setDateSate(item.date_start);
    setDateEnd(item.date_end);
  };
  const onCloseModelDelete = () => {
    setVisableModelDelete(false);
  };
  const onConfirmDeleteModel = async () => {
    try {
      const res = await request("leave/" + id, "delete", {});
      if (res) {
        setVisableModelDelete(false);
        setLoadingSpin(true);
        await new Promise((resolve) => setTimeout(resolve, 400));

        openNotification("success", "Success", "Remove Success!");
      } else {
        openNotification("error", "Error", "Something Wrong!");
      }
    } catch (error) {
      openNotification("error", "Error", error);
    } finally {
      setLoadingSpin(false);
      var objTmp = {
        ...objFilter,
      };
      await setObjFilter(objTmp);
      getList(objTmp);
    }
  };
  //----------------  end  Model Delete ---------
  const [staffname, setStaffName] = useState("");
  const onOpenModelEdit = (item) => {
    setReason(item.reason);
    setUserName(item.username);
    setStaffName(item.fullname);
    setid(item.id);
    setApproved(item.adminid);
    setUserStatus(item.status);
    setUserDateRequest(dayjs(item.date_request, "YYYY-MM-DD"));
    setUserDateStart(dayjs(item.date_start, "YYYY-MM-DD"));
    setUserDateEnd(dayjs(item.date_end, "YYYY-MM-DD"));
    setUserDateJoinWork(dayjs(item.date_inwork, "YYYY-MM-DD"));
    setUserLeaveType(item.type_leave);
    setUserNumberLeave(item.num_leave);
    setVisableModelUpdate(true);
  };
  const onModelEditClose = () => {
    setVisableModelUpdate(false);
  };
  const formattedDate = (date) => {
    // Ensure the date is valid before formatting
    if (dayjs(date).isValid()) {
      return dayjs(date).format("YYYY-MM-DD");
    } else {
      console.error("Invalid date format", date);
      return null;
    }
  };

  const onConfrimUpdate = async () => {
    if (approvedby !== "") {
      const data = {
        id: id,
        username: username,
        UserDate_Request: formattedDate(UserDateRequest), // Format UserDate_Request
        UserDate_Start: formattedDate(UserDateStart), // Format UserDate_Start
        UserDate_End: formattedDate(UserDateEnd), // Format UserDate_End
        UserDate_JoinWork: formattedDate(UserDateJoinWork), // Format UserDate_JoinWork
        UserNumber_Leave: UserNumberLeave,
        UserLeave_type: UserLeaveType,
        User_Status: UserStatus,
        admin_id: approvedby,
        reason: txt_reason,
      };

      console.table(data);

      const method = id === null ? "post" : "put";

      try {
        // Ensure `request` is awaited correctly
        const res = await request("leave", method, data);

        if (res) {
          setVisableModelUpdate(false);
          setLoadingSpin(true);
          await new Promise((resolve) => setTimeout(resolve, 400));
          openNotification(
            "success",
            id === "" ? "Save Data Successful" : "Update Successful"
          );
          getList(objFilter);
        }
      } catch (error) {
        // Log the error message for debugging
        console.error("Error in onConfirmUpdate:", error);
      } finally {
        setLoadingSpin(false);
      }
    } else {
      openNotification("error", "Please select Approver");
    }
  };

  const onChangeApprover = (value) => {
    setApproved(value);
    console.log("Approved", value);
  };
  // ------------------end  update model -----------
  // ------------------ search ---------------------

  const [UserSearchKeyWord, setUserSearchKeyword] = useState("");
  const handleSearchInputChange = async (event) => {
    const inputValue = event.target.value;
    setUserSearchKeyword(inputValue);
    try {
      setLoadingSpin(true);
      await new Promise((resolve) => setTimeout(resolve, 400));

      onSearch(inputValue, UserStatus_Search);
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };
  const onSearch = (value, statu) => {
    var objTmp = {
      ...objFilter,
      txtSearch: value,
      txtStatus: statu,
      page: value === "" ? 1 : objFilter.page,
    };
    setObjFilter(objTmp);
    getList(objTmp);
  };

  //------------------ end search  --------------------

  const onChangePage = async (page) => {
    try {
      setLoadingSpin(true);
      var objTmp = {
        ...objFilter,
        page: page,
      };
      await new Promise((resolve) => setTimeout(resolve, 400));
      await setObjFilter(objTmp);
      getList(objTmp);
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };

  const onCancelDelete = () => {
    setVisableModelDelete(false);
  };
  const onCancelModelNew_Update = () => {
    setVisableModelUpdate(false);
  };
  const [status_request, setStatusRequest] = useState("");
  const onChangeStatusRequest = (value) => {
    setStatusRequest(value);
  };

  const onClickDownloadReport = () => {
    setDownloadReport(true);
  };
  const onCancelDownload = () => {
    setDownloadReport(false);
  };

  const [selectedDateStart, setSelectedDate_start] =
    useState(/* initialize with a valid date object */);
  const [selectedDateEnd, setSelectedDate_end] =
    useState(/* initialize with a valid date object */);
  const [ListExcelData, setListExcelData] = useState([]);

  const onExporttoExcel = async () => {
    try {
      if (!selectedDateStart || !selectedDateEnd) {
        console.error("Selected dates are undefined");
        return;
      }

      const param = {
        org_code: getProfile.org_code,
        date_start: dayjs(selectedDateStart).format("YYYY-MM-DD"), // Consistent date format
        date_end: dayjs(selectedDateEnd).format("YYYY-MM-DD"),
        idnumber: getIDnumber,
      };

      const queryParams = queryString.stringify(param);
      console.log("Query Parameters:", param);

      const res = await request(`leavereport?${queryParams}`, "get", {});
      if (res && Array.isArray(res.dataRequestLeave)) {
        const dataArray = res.dataRequestLeave; // Assuming response is an array of records
        setListExcelData(dataArray);
        console.log("Exported Data:", dataArray);
        return dataArray;
      } else {
        console.error("Error: Expected data array not found in response");
        return [];
      }
    } catch (error) {}
  };

  const onExporttoExcel_monthlyreport = async (sqltable) => {
    if (!selectedDateStart || !selectedDateEnd) {
      console.error("Selected dates are undefined");
      return;
    }
    const param = {
      org_code: getProfile.org_code,
      date_start: dayjs(selectedDateStart).format("YYYY-MM-DD"), // Consistent date format
      date_end: dayjs(selectedDateEnd).format("YYYY-MM-DD"),
    };

    const queryParams = queryString.stringify(param);
    console.log("Query parameters", param);
    const res = await request(`leavereportmonthly?${queryParams}`, "get", {});
    if (res) {
      // Ensure res.dataRequestLeave is an array
      const dataArray = Array.isArray(res.dataRequestLeave[sqltable])
        ? res.dataRequestLeave[sqltable]
        : [];
      setListExcelData(dataArray);
      console.log("res", dataArray);

      // Return the array for further use if needed
      return dataArray;
    }

    // Return an empty array or handle the case when 'res' is falsy
    return [];
  };
  useEffect(() => {
    // Call the onExporttoExcel function when selectedDateStart or selectedDateEnd changes
    onExporttoExcel();
  }, [selectedDateStart, selectedDateEnd]);

  // //  export data from report to excel file
  // const downloadExcel = async () => {
  //   try {
  //     setLoadingSpin(true);
  //     setExcelClicked(true);
  //     setMonthlyReportClicked(true);
  //     await new Promise((resolve) => setTimeout(resolve, 400));
  //     // Usage
  //     const data = await onExporttoExcel();

  //     // Load the template Excel file from the public folder
  //     const templatePath =
  //       process.env.PUBLIC_URL + "/template/leavebydate.xlsx";
  //     const response = await fetch(templatePath);
  //     const templateArrayBuffer = await response.arrayBuffer();

  //     // Create a workbook from the template file
  //     const workbook = new ExcelJS.Workbook();
  //     await workbook.xlsx.load(templateArrayBuffer);

  //     // Get the 'data' sheet from the workbook
  //     const dataSheet = workbook.getWorksheet("data");
  //     // add Organization Name to A2
  //     dataSheet.getCell("A2").value = getProfile.org_name_kh;
  //     if (!dataSheet) {
  //       console.error('Sheet with name "data" not found in the template.');
  //       return;
  //     }

  //     // Convert the new data to worksheet format
  //     const newData = data.map((item) => Object.values(item));

  //     // Append the new data to the existing 'data' sheet starting from cell A3
  //     dataSheet.addTable({
  //       name: "dataTable",
  //       ref: "A6",
  //       columns: Object.keys(data[0]).map((key) => ({ name: key })),
  //       rows: newData,
  //     });

  //     // Add report date range on cell A4

  //     dataSheet.getCell(
  //       "A4"
  //     ).value = ` របាយការណ៍ចាប់ពីថ្ងៃទី៖ ${selectedDateStart.format(
  //       "MM-DD-YYYY"
  //     )} ដល់ថ្ងៃទី៖  ${selectedDateEnd.format("MM-DD-YYYY")}`;

  //     // Iterate through each row starting from row 4
  //     for (let row = 7; row <= data.length + 3; row++) {
  //       // Check if cell in column A has a non-null value
  //       const cellValueA = dataSheet.getCell(row, 1).value;
  //       if (cellValueA !== null) {
  //         // Set background color for cells in columns B to F
  //         for (let col = 1; col <= 7; col++) {
  //           dataSheet.getCell(row, col).fill = {
  //             type: "pattern",
  //             pattern: "solid",
  //             fgColor: { argb: "CCFFFF" }, // Set your desired background color (here, red)
  //           };
  //         }
  //       }
  //     }
  //     // delete row
  //     const lastRowToDelete = Math.min(data.length + 3, 1000);
  //     // Delete the last row from data.length + 3 up to row number 1000
  //     dataSheet.spliceRows(lastRowToDelete, 1);
  //     // Save the modified workbook as a Blob with XLSX format
  //     const blob = await workbook.xlsx.writeBuffer();
  //     const name_report = `Report-${selectedDateStart.format(
  //       "MM-DD-YYYY"
  //     )} to ${selectedDateEnd.format("MM-DD-YYYY")}`;
  //     FileSaver.saveAs(new Blob([blob]), `${name_report}.xlsx`);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoadingSpin(false);
  //     setExcelClicked(false);
  //     setMonthlyReportClicked(false);
  //   }
  // };
  const downloadExcel = async () => {
    try {
      setLoadingSpin(true);
      setExcelClicked(true);
      setMonthlyReportClicked(true);
      await new Promise((resolve) => setTimeout(resolve, 400));

      // Fetch data
      const data = await onExporttoExcel();

      // Load template file
      const templatePath =
        process.env.PUBLIC_URL + "/template/leavebydate.xlsx";
      const response = await fetch(templatePath);
      const templateArrayBuffer = await response.arrayBuffer();

      // Create workbook
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(templateArrayBuffer);
      const dataSheet = workbook.getWorksheet("data");

      if (!dataSheet) {
        console.error('Sheet with name "data" not found in the template.');
        return;
      }

      // Set organization name in A2
      dataSheet.getCell("A2").value = getProfile.org_name_kh;

      // Set report date range in A4
      dataSheet.getCell(
        "A4"
      ).value = ` របាយការណ៍ចាប់ពីថ្ងៃទី៖ ${selectedDateStart.format(
        "MM-DD-YYYY"
      )} ដល់ថ្ងៃទី៖  ${selectedDateEnd.format("MM-DD-YYYY")}`;

      // Prepare data rows
      const newData = data.map((item) => Object.values(item));

      // Add table starting at A6
      dataSheet.addTable({
        name: "dataTable",
        ref: "A6",
        columns: Object.keys(data[0]).map((key) => ({ name: key })),
        rows: newData,
      });

      // Apply background color from row 7 to rows with data

      // Iterate through each row starting from row 7 up to the length of data + 6
      for (let row = 7; row <= data.length + 6; row++) {
        // Check if cell in column A has a non-null value
        const cellValueA = dataSheet.getCell(`A${row}`).value; // Using template literal for cell reference
        if (cellValueA !== null) {
          // Apply background color to cells in columns A to G (1 to 7)
          for (let col = 1; col <= 7; col++) {
            dataSheet.getCell(row, col).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "CCFFFF" }, // Set background color
            };
          }
        }
      }

      // Delete rows beyond data length to avoid excess rows
      const lastRowToDelete = newData.length + 7; // Starts after data
      if (lastRowToDelete < 1000) {
        dataSheet.spliceRows(lastRowToDelete, 1000 - lastRowToDelete);
      }

      // Generate Blob for download
      const blob = await workbook.xlsx.writeBuffer();
      const name_report = `Report-${selectedDateStart.format(
        "MM-DD-YYYY"
      )} to ${selectedDateEnd.format("MM-DD-YYYY")}`;
      FileSaver.saveAs(new Blob([blob]), `${name_report}.xlsx`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingSpin(false);
      setExcelClicked(false);
      setMonthlyReportClicked(false);
    }
  };
  // Format extracted_date to yyyymmdd
  const formatDate = (date) => {
    return dayjs(date).format("YYYYMMDD");
  };
  const downloadExcel_monthylyreport = async () => {
    try {
      setLoadingSpin(true);
      setExcelClicked(true);
      setMonthlyReportClicked(true);
      await new Promise((resolve) => setTimeout(resolve, 400));

      // Fetch data
      const data = await onExporttoExcel_monthlyreport(2);
      //   const data_month = await onExporttoExcel_monthlyreport(2);
      const data_staff = await onExporttoExcel_monthlyreport(1);

      // Load the template Excel file from the public folder
      const templatePath =
        process.env.PUBLIC_URL + "/template/template_monthlyreport.xlsx";
      const response = await fetch(templatePath);
      const templateArrayBuffer = await response.arrayBuffer();

      // Create a workbook from the template file
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(templateArrayBuffer);

      // Get the sheets
      const dataSheet = workbook.getWorksheet("print-sumary");
      //  const dataSheetMonth = workbook.getWorksheet("op");
      const dataSheetStaff = workbook.getWorksheet("print-sumary");

      if (!dataSheetStaff) {
        console.error(
          "One or more required sheets are missing in the template."
        );
        return;
      }

      // Convert data to worksheet format
      const newData = data.map((item) => Object.values(item));
      //  const newDataMonth = data_month.map((item) => Object.values(item));
      const newDataStaff = data_staff.map((item) => Object.values(item));
      // add organizations to excel
      dataSheetStaff.getCell("A3").value = getProfile.org_name_kh;
      // Add data tables to each sheet
      dataSheet.addTable({
        name: "dataTable1",
        ref: "I9",
        columns: Object.keys(data[0]).map((key) => ({ name: key })),
        rows: newData,
      });

      // dataSheetMonth.addTable({
      //   name: "dataTable2",
      //   ref: "A2",
      //   columns: Object.keys(data_month[0]).map((key) => ({ name: key })),
      //   rows: newDataMonth,
      // });

      dataSheetStaff.addTable({
        name: "dataTable3",
        ref: "B9",
        columns: Object.keys(data_staff[0]).map((key) => ({ name: key })),
        rows: newDataStaff,
      });

      // Handle subtotal formulas
      const applySubtotal = (sheet, data) => {
        for (let row = 10; row <= data.length + 9; row++) {
          const cellValue = sheet.getCell(row, 2).value;
          if (cellValue === 1) {
            const subtotalFormula = {
              formula: `SUBTOTAL(109, B$9:B${row})`,
            };
            sheet.getCell(row, 1).value = subtotalFormula;
          }
        }
      };
      //  applySubtotal(dataSheetStaff, newData);

      // Hide unnecessary rows in each sheet
      const hideRows = (sheet, length) => {
        const startRowToDelete = length + 11;
        const endRowToHide = 1000;

        for (let row = startRowToDelete; row <= endRowToHide; row++) {
          sheet.getRow(row).height = 0.1; // Hide the row
        }
      };
      hideRows(dataSheetStaff, newDataStaff.length);

      const applyBackgroundColor = (sheet, data) => {
        for (let row = 10; row <= data.length + 9; row++) {
          const cellValue = sheet.getCell(row, 2).value;
          if (cellValue === null) {
            for (let col = 1; col <= 8; col++) {
              sheet.getCell(row, col).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "abebc6" },
              };
            }
          }
        }
      };
      applyBackgroundColor(dataSheetStaff, newDataStaff);
      // Save workbook as an XLSX file
      const blob = await workbook.xlsx.writeBuffer();
      const name_report = `Report-${selectedDateStart.format(
        "MM-DD-YYYY"
      )} to ${selectedDateEnd.format("MM-DD-YYYY")} ${moment().format(
        "HH:mm:ss"
      )}`;
      FileSaver.saveAs(new Blob([blob]), `${name_report}.xlsx`);
    } catch (error) {
      console.error("Error during Excel generation:", error);
    } finally {
      setLoadingSpin(false);
      setExcelClicked(false);
      setMonthlyReportClicked(false);
    }
  };

  // const downloadExcel_monthylyreport = async () => {
  //   try {
  //     setLoadingSpin(true);
  //     setExcelClicked(true);
  //     setMonthlyReportClicked(true);
  //     await new Promise((resolve) => setTimeout(resolve, 400));
  //     // Usage
  //     const data = await onExporttoExcel_monthlyreport(1);
  //     const data_month = await onExporttoExcel_monthlyreport(2);
  //     const data_staff = await onExporttoExcel_monthlyreport(3);
  //     // Load the template Excel file from the public folder
  //     const templatePath =
  //       process.env.PUBLIC_URL + "/template/template_monthlyreport.xlsx";
  //     const response = await fetch(templatePath);
  //     const templateArrayBuffer = await response.arrayBuffer();

  //     // Create a workbook from the template file
  //     const workbook = new ExcelJS.Workbook();
  //     await workbook.xlsx.load(templateArrayBuffer);

  //     // Get the 'data' sheet from the workbook
  //     const dataSheet = workbook.getWorksheet("data");
  //     const dataSheetMonth = workbook.getWorksheet("op");
  //     const dataSheetStaff = workbook.getWorksheet("print-sumary");
  //     if (!dataSheet) {
  //       console.error('Sheet with name "data" not found in the template.');
  //       return;
  //     }

  //     // Convert the new data to worksheet format
  //     const newData = data.map((item) => Object.values(item));
  //     const newDataMonth = data_month.map((item) => Object.values(item));
  //     const newDataStaff = data_staff.map((item) => Object.values(item));
  //     // Append the new data to the existing 'data' sheet starting from cell A3
  //     dataSheet.addTable({
  //       name: "dataTable",
  //       ref: "A1",
  //       // headerRow: true,
  //       columns: Object.keys(data[0]).map((key) => ({ name: key })),
  //       rows: newData,
  //     });

  //     dataSheetMonth.addTable({
  //       name: "dataTable",
  //       ref: "A2",
  //       // headerRow: true,
  //       columns: Object.keys(data[0]).map((key) => ({ name: key })),
  //       rows: newDataMonth,
  //     });
  //     // add staff to excell : "print-sumary"
  //     dataSheetStaff.addTable({
  //       name: "dataTable1",
  //       ref: "A9",
  //       // headerRow: true,
  //       columns: Object.keys(data[0]).map((key) => ({ name: key })),
  //       rows: newDataStaff,
  //     });

  //     // delete row
  //     const lastRowToDelete = Math.min(data.length + 3, 1000);
  //     // Delete the last row from data.length + 3 up to row number 1000
  //     dataSheet.spliceRows(lastRowToDelete, 1);
  //     // Save the modified workbook as a Blob with XLSX format
  //     const blob = await workbook.xlsx.writeBuffer();
  //     const name_report = `Report-${selectedDateStart.format(
  //       "MM-DD-YYYY"
  //     )} to ${selectedDateEnd.format("MM-DD-YYYY")} ${moment().format(
  //       "HH:mm:ss"
  //     )}`;
  //     FileSaver.saveAs(new Blob([blob]), `${name_report}.xlsx`);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoadingSpin(false);
  //     setExcelClicked(false);
  //     setMonthlyReportClicked(false);
  //   }
  // };

  //  for download leave
  const handleDateChange_Start = (date) => {
    setSelectedDate_start(date);
  };
  const handleDateChange_End = (date) => {
    setSelectedDate_end(date);
  };

  //  --- for update form
  const onChangeDate_Request = (date) => {
    setUserDateRequest(dayjs(date, "DD-MM-YYYY"));
  };
  const OnChangeDate_Start = (date) => {
    setUserDateStart(dayjs(date, "DD-MM-YYYY"));
  };

  const OnChangeDate_End = (date) => {
    setUserDateEnd(dayjs(date, "DD-MM-YYYY"));
  };
  const OnChangeDate_JoinWork = (date) => {
    setUserDateJoinWork(dayjs(date, "DD-MM-YYYY"));
  };

  const onChangeLeaveType = (value) => {
    setUserLeaveType(value);
  };
  const onChangeUserNumberLeave = (value) => {
    setUserNumberLeave(value);
  };
  const OnChangeDate_Status = (value) => {
    setUserStatus(value);
  };
  const OnChangeDate_StatusSearch = async (value) => {
    setUserStatus_Search(value);
    console.log("Values select change : ", value);
    try {
      setLoadingSpin(true);
      await new Promise((resolve) => setTimeout(resolve, 400));

      var objTmp = {
        ...objFilter,
        //  txtStatus: UserSearchKeyWord,
        txtStatus: value === undefined ? "" : value,

        page: value === "" ? 1 : objFilter.page,
      };
      setObjFilter(objTmp);
      getList(objTmp);
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };

  const getListEmployee = async () => {
    if (!getProfile?.org_code) {
      console.error("Organization code is missing");
      return;
    }

    const param = new URLSearchParams({
      org_code: getProfile.org_code,
    });

    try {
      const res = await request(`leavestaff?${param.toString()}`, "get", {});
      if (res && res.list_staff) {
        setList_staff(res.list_staff);
      } else {
        console.warn("No staff list returned from the response.");
      }
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  };

  const onHandleAddNewLeave = () => {
    setid(null);
    console.log("onHandleAddNewLeave", id);
    setVisableModelUpdate(true);
  };
  const onChangeStaff = (value) => {
    setUserName(value);
    setStaffName(value);
    console.log("onChangeStaff', value:", username);
  };
  const onChangeReason = (value) => {
    setReason(value.target.value);
    console.log("onChangeReason", value.target.value);
  };
  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>

      <div style={boxHeader}>
        <div className="Header-Setting-Grid">
          <div className="Header-Setting-Grid-R">
            {boxHeaderTagText("ការគ្រប់គ្រងសំណើរច្បាប់ :  " + totalPage)}

            <Space>
              <Input
                style={{ display: txtSearchVisable ? "none" : "block" }}
                className="styleTageStatusForm"
                placeholder="Search..."
                onChange={handleSearchInputChange}
                allowClear
              />
              <Select
                name={UserStatus_Search}
                value={UserStatus_Search}
                onChange={OnChangeDate_StatusSearch}
                className="styleTageStatusForm"
                placeholder="Select an Status"
                style={{ width: 150 }}
                allowClear
              >
                <Option value="Approved">Approved</Option>
                <Option value="Pending">Pending</Option>
              </Select>
            </Space>
          </div>

          <div>
            <Space>
              <Button
                className="buttonstylekhmer"
                onClick={onClickDownloadReport}
                type="primary"
                icon={<BsDownload />}
              >
                ទាយយករបាយការណ៍
              </Button>
              <Button
                className="custom-form-item"
                onClick={onHandleAddNewLeave}
                type="primary"
                icon={<PlusCircleOutlined />}
              >
                បន្ថែមសំណើរច្បាប់ថ្មី
              </Button>
            </Space>
          </div>
        </div>
      </div>

      <div style={{ width: "auto" }}>
        {loading ? (
          <div className="sprinCustome">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Table
            pagination={{
              total: totalPage, // total record data from database server
              pageSize: 10,
              onChange: onChangePage,
            }}
            columns={columns}
            //  sortDirections={['ascend', 'descend', 'ascend']}
            size="small"
            dataSource={filteredDataSource}
          />
        )}
      </div>
      <Modal
        title={id == "" ? "Save" : "Update"}
        className="custom-form-item"
        open={visableModelUpdate}
        onCancel={onCancelModelNew_Update}
        width={600}
        footer={[
          <Space>
            <Button onClick={onModelEditClose}>Cancel</Button>
            <Button onClick={onConfrimUpdate} type="primary">
              {id === null ? "Save" : "Update"}
            </Button>
          </Space>,
        ]}
      >
        <div className="Model-Setting-Grid">
          <div>
            <div>
              <div>
                <p
                  style={{
                    margin: 5,
                    color: "darkgray",
                    fontWeight: "bold",
                    fontFamily: "Khmer OS Battambang ,sans-serif",
                  }}
                >
                  ឈ្មោះបុគ្គលិក :
                </p>

                <Select
                  style={{ width: "100%", height: 40 }}
                  showSearch
                  value={staffname}
                  className="styleTageStatusForm"
                  placeholder="Select a person"
                  optionFilterProp="label"
                  onChange={onChangeStaff}
                >
                  {list_staff
                    .filter((item) => !getUser || item.fullname === getUser)
                    .map((item) => (
                      <Option key={item.id} value={item.username}>
                        {item.fullname}
                      </Option>
                    ))}
                </Select>
              </div>

              <div>
                <div
                  style={{
                    paddingTop: 5,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    style={{
                      marginTop: 10,
                      margin: 5,
                      color: "darkgray",
                      fontWeight: "bold",
                      fontFamily: "Khmer OS Battambang ,sans-serif",
                    }}
                  >
                    សំណើរច្បាប់ថ្ងៃទី :
                  </p>
                  <DatePicker
                    style={{ width: "100%", height: 40 }}
                    format="DD/MM/YYYY" // Set the display format
                    name={UserDateRequest}
                    value={dayjs(UserDateRequest, "DD/MM/YYYY")}
                    onChange={onChangeDate_Request}
                    className="styleDatePicker"
                    allowClear
                  />
                  <p
                    style={{
                      margin: 5,
                      color: "Red",
                      marginTop: 10,
                      fontWeight: "bold",
                      fontFamily: "Khmer OS Battambang ,sans-serif",
                    }}
                  >
                    ច្បាប់ ចាប់ផ្តើមថ្ងៃទី :
                  </p>
                  <DatePicker
                    style={{ width: "100%", height: 40 }}
                    format="DD/MM/YYYY" // Set the display format
                    name={UserDateStart}
                    value={dayjs(UserDateStart, "DD/MM/YYYY")}
                    onChange={OnChangeDate_Start}
                    className="styleDatePicker"
                    allowClear
                  />
                  <p
                    style={{
                      margin: 5,
                      color: "Red",
                      marginTop: 10,
                      fontWeight: "bold",
                      fontFamily: "Khmer OS Battambang ,sans-serif",
                    }}
                  >
                    ច្បាប់ដល់ ថ្ងៃទី :
                  </p>

                  <DatePicker
                    format="DD/MM/YYYY" // Set the display format
                    name={UserDateEnd}
                    style={{ width: "100%", height: 40 }}
                    value={dayjs(UserDateEnd, "DD/MM/YYYY")}
                    onChange={OnChangeDate_End}
                    className="styleDatePicker"
                    allowClear
                  />
                  <p
                    style={{
                      margin: 5,
                      marginTop: 10,
                      color: "darkgray",
                      fontWeight: "bold",
                      fontFamily: "Khmer OS Battambang ,sans-serif",
                    }}
                  >
                    ចូលធ្វើការថ្ងៃទី :
                  </p>
                  <DatePicker
                    style={{ width: "100%", height: 40 }}
                    format="DD/MM/YYYY" // Set the display format
                    name={UserDateJoinWork}
                    value={dayjs(UserDateJoinWork, "DD/MM/YYYY")}
                    onChange={OnChangeDate_JoinWork}
                    className="styleDatePicker"
                    allowClear
                  />
                  <p
                    style={{
                      margin: 5,
                      marginTop: 10,
                      color: "darkgray",
                      fontWeight: "bold",
                      fontFamily: "Khmer OS Battambang ,sans-serif",
                    }}
                  >
                    ចំនួនច្បាប់ ៖
                  </p>
                  <Select
                    name={UserNumberLeave}
                    value={UserNumberLeave}
                    className="styleTageStatusForm"
                    placeholder="Select an Approved"
                    style={{ width: "100%", height: 40 }}
                    onChange={onChangeUserNumberLeave}
                  >
                    {ListNumberLeave.map((option) => (
                      <Option
                        className="styleTageStatusForm"
                        key={option.numleave_id}
                        value={option.numleave_khmer}
                      >
                        {option.numleave_khmer}{" "}
                        {/* Replace with the field from your MySQL table */}
                      </Option>
                    ))}
                  </Select>
                  <p
                    style={{
                      margin: 5,
                      color: "darkgray",
                      paddingTop: 10,
                      fontWeight: "bold",
                      fontFamily: "Khmer OS Battambang ,sans-serif",
                    }}
                  >
                    ប្រភេទច្បាប់ ៖
                  </p>
                  <Select
                    name={UserLeaveType}
                    value={UserLeaveType}
                    className="styleTageStatusForm"
                    placeholder="Select an Approved"
                    style={{ width: "100%", height: 40 }}
                    onChange={onChangeLeaveType}
                  >
                    {ListLeaveType.map((option) => (
                      <Option
                        className="styleTageStatusForm"
                        key={option.type_Leave}
                        value={option.type_Leave}
                      >
                        {option.type_Leave}{" "}
                        {/* Replace with the field from your MySQL table */}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <div>
                <div>
                  <p
                    style={{
                      margin: 5,
                      color: "darkgray",

                      fontWeight: "bold",
                      fontFamily: "Khmer OS Battambang ,sans-serif",
                    }}
                  >
                    ស្ថានភាពច្បាប់ ៖
                  </p>
                  <Select
                    name={UserStatus}
                    value={UserStatus}
                    onChange={OnChangeDate_Status}
                    className="styleTageStatusForm"
                    placeholder="Select an Status"
                    style={{ width: "100%", height: 40 }}
                  >
                    <Option value="Approved">Approved</Option>
                    <Option value="Pending">Pending</Option>
                  </Select>

                  <p
                    style={{
                      margin: 5,
                      color: "darkgray",
                      paddingTop: 10,
                      fontWeight: "bold",
                      fontFamily: "Khmer OS Battambang ,sans-serif",
                    }}
                  >
                    អនុញ្ញាច្បាប់ដោយ ប្រធាន ឬអនុប្រធាន ៖
                  </p>
                  <Select
                    className="styleTageStatusForm"
                    placeholder="Select an Approved"
                    style={{ width: "100%", height: 40 }}
                    onChange={onChangeApprover}
                    value={approvedby}
                  >
                    {listApprov.map((option) => (
                      <Option
                        className="styleTageStatusForm"
                        key={option.id}
                        value={option.username}
                      >
                        {option.fullname}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,

                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                មូលហេតុ៖
              </p>
              <Input.TextArea
                rows={6}
                style={{ width: "100%" }}
                value={txt_reason}
                onChange={onChangeReason}
                placeholder="បញ្ជូលមូលហេតុដែលត្រូវច្បាប់...."
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title="លុបសំណើរច្បាប់ ចេញពីប្រព័ន្ធ "
        className="custom-form-item"
        open={visableModelDelete}
        onCancel={onCancelDelete}
        footer={[
          <div>
            <Space>
              <Button onClick={onCloseModelDelete}>No</Button>
              <Button type="primary" onClick={onConfirmDeleteModel}>
                Yes
              </Button>
            </Space>
          </div>,
        ]}
      >
        <p className="custom-form-item" style={{ color: "red" }}>
          តើអ្នកចង់លុបសំណើរច្បាប់ របស់បុគ្គលិក
          <br /> - ឈ្មោះ {name} <br /> នៅថ្ងៃទី {formateDateClient(detestart)}{" "}
          ដល់ថ្ងៃទី {formateDateClient(dateend)} នេះចេញពីប្រព័ន្ធ ?
        </p>
      </Modal>
      <Modal
        title="ទាញរបាយការណ៍"
        className="custom-modal" // Use the custom-modal class for responsive styles
        open={DownloadReport}
        onCancel={onCancelDownload}
        footer={[
          <div>
            <Space>
              <Button
                className="custom-form-item"
                type="primary"
                onClick={downloadExcel}
                disabled={excelClicked}
              >
                ទាញយក (Excel)
              </Button>

              <Button
                style={{ display: monthlyReport === 1 ? "none" : "block" }}
                disabled={monthlyReportClicked}
                className="custom-form-item"
                type="primary"
                onClick={downloadExcel_monthylyreport}
              >
                ទាញយក Monthly Reports
              </Button>
            </Space>
          </div>,
        ]}
      >
        <div style={{ width: "520px" }}>
          <Space>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Space style={{ width: "100%" }}>
                ចាប់ពីថ្ងៃទីខែ :
                <DatePicker
                  format="DD/MM/YYYY"
                  onChange={handleDateChange_Start}
                />
              </Space>
            </div>
            <div>
              <Space>
                ដល់ថ្ងៃទីខែ :
                <DatePicker
                  format="DD/MM/YYYY"
                  onChange={handleDateChange_End}
                />
              </Space>
            </div>
          </Space>
        </div>
      </Modal>
    </div>
  );
};
export default LeavePage;
