export const getCurrentUser = () => {
  var profileGetData = localStorage.getItem("profile");
  if (profileGetData != "" && profileGetData != null) {
    profileGetData = JSON.parse(profileGetData);
    return profileGetData;
  }
  return null;
};
// export const getPermission = () => {
//   var permissionGetData = localStorage.getItem("permission");
//   if (permissionGetData != "" && permissionGetData != null) {
//     permissionGetData = JSON.parse(permissionGetData);
//     return permissionGetData;
//   }
//   return null;
// };
export const getPermission = () => {
  let permissionGetData = localStorage.getItem("per_option");

  if (permissionGetData !== "" && permissionGetData !== null) {
    try {
      // Decode the Base64 encoded string and then parse it as JSON
      permissionGetData = JSON.parse(atob(permissionGetData));
      return permissionGetData;
    } catch (error) {
      console.error("Error decoding permission data:", error);
    }
  }

  return null;
};

export const isGetLogin = () => {
  const isUserLogin = localStorage.getItem("isLogin");
  if (
    isUserLogin == null ||
    isUserLogin == "null" ||
    isUserLogin == "" ||
    isUserLogin == 0
  ) {
    return false;
  }
  return true;
};
