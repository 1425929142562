import {
  Table,
  Pagination,
  Space,
  Button,
  Modal,
  Input,
  DatePicker,
} from "antd";

import { useState, useEffect } from "react";
import { request } from "../../share/request";
import "../../styles/Home-module.css";
import { BsDownload } from "react-icons/bs";
import style from "../../styles/style.module.css";
import { Circles } from "react-loader-spinner";
import { MdOutlineEdit, MdOutlineDelete } from "react-icons/md";
import { formateDateClient, formateDateSystem } from "../../share/helper";
import { getJobStatus } from "../../share/getStatusContent";
//  for export data to excel
import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import moment from "moment";
import { openNotification } from "../../share/message";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";
import { getCurrentUser } from "../../share/getProfile";
import "./Model-Setting-Grid.css";

// -----------------  end import --------------------

const EmployeePage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const getProfile = getCurrentUser();
  const [ListExcelData, setListExcelData] = useState([]);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [DownloadReport, setDownloadReport] = useState(false);
  const [dataSours, setDataSoure] = useState([]);
  const [totalpage, setTotalPage] = useState(1);

  const [size, setSize] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const [objFilter, setObjFilter] = useState({
    page: 1,
    org_code: getProfile.org_code,
  });
  useEffect(() => {
    // block form load
    getList(objFilter);
  }, []);

  const getList = async (objFilter) => {
    const param = new URLSearchParams({
      page: objFilter.page,
      org_code: getProfile.org_code,
    });

    // create function success

    try {
      const res = await request(
        "employeereport?" + param.toString(),
        "get",
        {}
      );
      await new Promise((resolve) => setTimeout(resolve, 400));
      if (res) {
        setDataSoure(res.ListEmployeeData[0]);
        setTotalPage(res.totalRecord);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text, record, index) => {
        // Assuming `page` and `pageSize` are available in your component scope
        return <div>{(pageNumber - 1) * pagesize + index + 1}</div>; // Proper formula for page-based indexing
      },
    },

    {
      title: "លេខមន្ត្រីរាជការ",
      dataIndex: "idnumber",
    },
    {
      title: "ឈ្មោះមន្ត្រីរាជការ",
      dataIndex: "fullname",
      render: (text, record) => (
        <div>
          <div style={{ fontSize: "14px" }}>{record.fullname}</div>
          <div style={{ fontSize: "12px", marginTop: 0, color: "#BFBFBF" }}>
            {record.staffname_eng}
          </div>
        </div>
      ),
    },

    {
      title: "ភេទ",
      dataIndex: "gender",
    },

    {
      title: "ផ្នែក និងតួរនាទី",
      dataIndex: "department",
      render: (text, record) => (
        <div>
          <div style={{ fontSize: "14px" }}>{record.department}</div>
          <div style={{ fontSize: "12px", marginTop: 0, color: "#BFBFBF" }}>
            {record.position}
          </div>
        </div>
      ),
    },
    {
      title: "ជំនាញ",
      dataIndex: "skill",
    },
    {
      title: "ថ្ងៃចូលបម្រើការងារ",
      dataIndex: "joinwork",
      render: (text, record) => <div>{formateDateSystem(record.joinwork)}</div>,
    },
    {
      title: "កាំប្រាក់",
      dataIndex: "level",
    },
    {
      title: "ថ្ងៃឡើងកាំប្រាក់",
      dataIndex: "date_last_upgrad",
      render: (text, record) => (
        <div>{formateDateSystem(record.date_last_upgrad)}</div>
      ),
    },
    {
      title: "ប្រភេទបុគ្គលិក",
      dataIndex: "stafftype",
      render: (text, record) => <div>{getJobStatus(record.stafftype)}</div>,
    },
    {
      title: "ស្ថានភាព",
      dataIndex: "jobstatus",
      render: (text, record) => <div>{getJobStatus(record.jobstatus)}</div>,
    },

    {
      title: "សកម្មភាព",
      render: (_, record, index) => (
        <Space>
          <Button type="primary" icon={<MdOutlineEdit />} />
          <Button type="primary" icon={<MdOutlineDelete />} danger />
        </Space>
      ),
    },
  ];

  const onExporttoExcel = async () => {
    const idorg = getProfile.org_code;
    console.log("ID org : ", idorg);
    const param = new URLSearchParams({
      idorg: idorg,
    });
    const res = await request("employeeexcel?" + param.toString(), "get", {});
    if (res) {
      // Ensure res.dataRequestLeave is an array
      const dataArray = Array.isArray(res.ListEmployeeDownloadExcell_gov[0])
        ? res.ListEmployeeDownloadExcell_gov[0]
        : [];
      setListExcelData(dataArray);
      console.log("res", dataArray);

      // Return the array for further use if needed
      return dataArray;
    }

    // Return an empty array or handle the case when 'res' is falsy
    return [];
  };
  const onExporttoExcel_other = async () => {
    const idorg = getProfile.org_code;
    console.log("ID org : ", idorg);
    const param = new URLSearchParams({
      idorg: idorg,
    });
    const res = await request("employeeexcel?" + param.toString(), "get", {});
    if (res) {
      // Ensure res.dataRequestLeave is an array
      const dataArray = Array.isArray(res.ListEmployeeDownloadExcell_other[0])
        ? res.ListEmployeeDownloadExcell_other[0]
        : [];
      setListExcelData(dataArray);
      console.log("res", dataArray);

      // Return the array for further use if needed
      return dataArray;
    }

    // Return an empty array or handle the case when 'res' is falsy
    return [];
  };
  const onExporttoExcel_TotalAll = async () => {
    const idorg = getProfile.org_code;
    console.log("ID org : ", idorg);
    const param = new URLSearchParams({
      idorg: idorg,
    });
    const res = await request("employeeexcel?" + param.toString(), "get", {});
    if (res) {
      // Ensure res.dataRequestLeave is an array
      const dataArray = Array.isArray(
        res.ListEmployeeDownloadExcell_TotalAll[0]
      )
        ? res.ListEmployeeDownloadExcell_TotalAll[0]
        : [];
      setListExcelData(dataArray);
      console.log("res", dataArray);

      // Return the array for further use if needed
      return dataArray;
    }

    // Return an empty array or handle the case when 'res' is falsy
    return [];
  };
  // useEffect(() => {
  //   // Call the onExporttoExcel function when selectedDateStart or selectedDateEnd changes
  //   onExporttoExcel();
  //   onExporttoExcel_other();
  //   onExporttoExcel_TotalAll();
  // }, []);

  //  export data from report to excel file
  // const downloadExcel = async () => {
  //   const OrgName = getProfile.org_name_kh;

  //   // Usage
  //   try {
  //     setLoadingSpin(true);
  //     setDownloadReport(false);
  //     const data = (await onExporttoExcel()) || [];
  //     const data_other = (await onExporttoExcel_other()) || [];
  //     const data_TotalAll = (await onExporttoExcel_TotalAll()) || [];
  //     // Load the template Excel file from the public folder
  //     const templatePath =
  //       process.env.PUBLIC_URL + "/template/templatestaff.xlsx";
  //     const response = await fetch(templatePath);
  //     const templateArrayBuffer = await response.arrayBuffer();

  //     // Create a workbook from the template file
  //     const workbook = new ExcelJS.Workbook();
  //     await workbook.xlsx.load(templateArrayBuffer);

  //     // Get the 'data' sheet from the workbook
  //     const dataSheet = workbook.getWorksheet("staffgov");
  //     const otherSheet = workbook.getWorksheet("staffothers");
  //     const TotalAllSheet = workbook.getWorksheet("total-all");

  //     dataSheet.getCell("A2").value = OrgName;

  //     if (!dataSheet) {
  //       console.error('Sheet with name "data" not found in the template.');
  //       return;
  //     }

  //     // Convert the new data to worksheet format
  //     const newData = data.map((item) => Object.values(item));
  //     const newData_other = data_other.map((item) => Object.values(item));
  //     const newData_TotalAll = data_TotalAll.map((item) => Object.values(item));
  //     // Append the new data to the existing 'data' sheet starting from cell A3
  //     dataSheet.addTable({
  //       name: "dataTable",
  //       ref: "B6",
  //       headerRow: true,
  //       columns: Object.keys(data[0]).map((key) => ({ name: key })),
  //       rows: newData,
  //     });
  //     otherSheet.addTable({
  //       name: "dataTable1",
  //       ref: "B6",
  //       headerRow: true,
  //       columns: Object.keys(data_other[0]).map((key) => ({ name: key })),
  //       rows: newData_other,
  //     });
  //     TotalAllSheet.addTable({
  //       name: "dataTable2",
  //       ref: "A6",
  //       headerRow: true,
  //       columns: Object.keys(data_TotalAll[0]).map((key) => ({ name: key })),
  //       rows: newData_TotalAll,
  //     });

  //     // Delete the last row from data.length + 4 up to row number 1000 in 'dataSheet'

  //     const startRowToDelete = data.length + 7;
  //     const endRowToHide = 1000;

  //     for (let row = startRowToDelete; row <= endRowToHide; row++) {
  //       // Set the height of the row to a very small value to hide it
  //       dataSheet.getRow(row).height = 0.1;
  //     }
  //     const startRowToDelete_other = data_other.length + 7;
  //     const endRowToHide_other = 1000;

  //     for (let row = startRowToDelete_other; row <= endRowToHide_other; row++) {
  //       // Set the height of the row to a very small value to hide it
  //       otherSheet.getRow(row).height = 0.1;
  //     }
  //     const startRowToDelete_Totalall = data_TotalAll.length + 7;
  //     const endRowToHide_Total = 98;

  //     for (
  //       let row = startRowToDelete_Totalall;
  //       row <= endRowToHide_Total;
  //       row++
  //     ) {
  //       // Set the height of the row to a very small value to hide it
  //       TotalAllSheet.getRow(row).height = 0.1;
  //     }

  //     // Iterate through each row starting from row 4
  //     for (let row = 7; row <= data.length + 6; row++) {
  //       // Check if cell in column A has a non-null value
  //       const cellValueA = dataSheet.getCell(row, 2).value;
  //       if (cellValueA === null) {
  //         // Set background color for cells in columns B to F
  //         for (let col = 1; col <= 17; col++) {
  //           dataSheet.getCell(row, col).fill = {
  //             type: "pattern",
  //             pattern: "solid",
  //             fgColor: { argb: "FFCCCC" }, // Set your desired background color (here, red)
  //           };
  //         }
  //       }
  //     }

  //     for (let row = 7; row <= data.length + 6; row++) {
  //       // Check if cell in column A has a non-null value

  //       const cellValueB = dataSheet.getCell(row, 2).value;

  //       if (cellValueB === 1) {
  //         const subtotalFormula = {
  //           formula: `SUBTOTAL(109, B$7:B${row})`, // 109 is the function number for SUM
  //         };
  //         dataSheet.getCell(row, 1).value = subtotalFormula;
  //       }
  //     }

  //     // Iterate through each row starting from row 4
  //     for (let row = 7; row <= data_other.length + 6; row++) {
  //       // Check if cell in column A has a non-null value
  //       const cellValueA = otherSheet.getCell(row, 2).value;
  //       if (cellValueA === null) {
  //         // Set background color for cells in columns B to F
  //         for (let col = 1; col <= 17; col++) {
  //           otherSheet.getCell(row, col).fill = {
  //             type: "pattern",
  //             pattern: "solid",
  //             fgColor: { argb: "FFCCCC" }, // Set your desired background color (here, red)
  //           };
  //         }
  //       }
  //     }
  //     for (let row = 7; row <= data_other.length + 6; row++) {
  //       // Check if cell in column A has a non-null value
  //       const cellValueB = otherSheet.getCell(row, 2).value;
  //       if (cellValueB === 1) {
  //         const subtotalFormula = {
  //           formula: `SUBTOTAL(109, B$7:B${row})`, // 109 is the function number for SUM
  //         };
  //         otherSheet.getCell(row, 1).value = subtotalFormula;
  //       }
  //     }

  //     // Iterate through each row starting from row 4
  //     for (let row = 7; row <= data_TotalAll.length + 6; row++) {
  //       // Check if cell in column A has a non-null value
  //       const cellValueA = TotalAllSheet.getCell(row, 2).value;
  //       if (cellValueA != null) {
  //         for (let col = 1; col <= 5; col++) {
  //           const cell = TotalAllSheet.getCell(row, col);
  //           cell.fill = {
  //             type: "pattern",
  //             pattern: "solid",
  //             fgColor: { argb: "FFCCCC" }, // Set your desired background color (here, red)
  //           };
  //           cell.font = {
  //             bold: true, // Set the font to bold,
  //             name: "Khmer OS Battambang",
  //           };
  //         }
  //       }
  //     }

  //     // Save the modified workbook as a Blob with XLSX format
  //     const blob = await workbook.xlsx.writeBuffer();
  //     const name_report = `StaffSummaryReport-${moment().format(
  //       "MM-DD-YYYY-HH:mm:ss"
  //     )}`;
  //     FileSaver.saveAs(new Blob([blob]), `${name_report}.xlsx`);
  //   } catch (error) {
  //     openNotification("error", "Error", "Error while download report");
  //   } finally {
  //     setLoadingSpin(false);
  //   }
  // };
  const downloadExcel = async () => {
    const OrgName = getProfile.org_name_kh;

    try {
      setLoadingSpin(true);
      setDownloadReport(false);

      // Fetch data and ensure it's an array
      const data = (await onExporttoExcel()) || [];
      const data_other = (await onExporttoExcel_other()) || [];
      const data_TotalAll = (await onExporttoExcel_TotalAll()) || [];

      // Load the template Excel file from the public folder
      const templatePath =
        process.env.PUBLIC_URL + "/template/templatestaff.xlsx";
      const response = await fetch(templatePath);
      const templateArrayBuffer = await response.arrayBuffer();

      // Create a workbook from the template file
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(templateArrayBuffer);

      // Get the sheets from the workbook
      const dataSheet = workbook.getWorksheet("staffgov");
      const otherSheet = workbook.getWorksheet("staffothers");
      const TotalAllSheet = workbook.getWorksheet("total-all");

      if (!dataSheet || !otherSheet || !TotalAllSheet) {
        console.error("One or more sheets not found in the template.");
        return;
      }

      dataSheet.getCell("A2").value = OrgName;

      // Convert the new data to worksheet format
      const newData = data.map((item) => Object.values(item));
      const newData_other = data_other.map((item) => Object.values(item));
      const newData_TotalAll = data_TotalAll.map((item) => Object.values(item));

      // Check if the data arrays are valid
      if (newData.length === 0) {
        console.warn("No data available for the main data sheet.");
      }
      if (newData_other.length === 0) {
        console.warn("No data available for the other data sheet.");
      }
      if (newData_TotalAll.length === 0) {
        console.warn("No data available for the total all data sheet.");
      }

      // Append the new data to the existing sheets
      if (newData.length > 0) {
        dataSheet.addTable({
          name: "dataTable",
          ref: "B6",
          headerRow: true,
          columns: Object.keys(data[0]).map((key) => ({ name: key })),
          rows: newData,
        });
      }

      if (newData_other.length > 0) {
        otherSheet.addTable({
          name: "dataTable1",
          ref: "B6",
          headerRow: true,
          columns: Object.keys(data_other[0]).map((key) => ({ name: key })),
          rows: newData_other,
        });
      }

      if (newData_TotalAll.length > 0) {
        TotalAllSheet.addTable({
          name: "dataTable2",
          ref: "A6",
          headerRow: true,
          columns: Object.keys(data_TotalAll[0]).map((key) => ({ name: key })),
          rows: newData_TotalAll,
        });
      }

      // Hide unnecessary rows in each sheet
      const hideRows = (sheet, length) => {
        const startRowToDelete = length + 7;
        const endRowToHide = 1000;

        for (let row = startRowToDelete; row <= endRowToHide; row++) {
          sheet.getRow(row).height = 0.1; // Hide the row
        }
      };

      hideRows(dataSheet, newData.length);
      hideRows(otherSheet, newData_other.length);
      hideRows(TotalAllSheet, newData_TotalAll.length);

      // Background color logic for data sheet
      const applyBackgroundColor = (sheet, data) => {
        for (let row = 7; row <= data.length + 6; row++) {
          const cellValue = sheet.getCell(row, 2).value;
          if (cellValue === null) {
            for (let col = 1; col <= 17; col++) {
              sheet.getCell(row, col).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFCCCC" },
              };
            }
          }
        }
      };

      applyBackgroundColor(dataSheet, newData);
      applyBackgroundColor(otherSheet, newData_other);

      // Handle subtotal formulas
      const applySubtotal = (sheet, data) => {
        for (let row = 7; row <= data.length + 6; row++) {
          const cellValue = sheet.getCell(row, 2).value;
          if (cellValue === 1) {
            const subtotalFormula = {
              formula: `SUBTOTAL(109, B$7:B${row})`,
            };
            sheet.getCell(row, 1).value = subtotalFormula;
          }
        }
      };

      applySubtotal(dataSheet, newData);
      applySubtotal(otherSheet, newData_other);

      // Background color for TotalAll sheet
      for (let row = 7; row <= newData_TotalAll.length + 6; row++) {
        const cellValue = TotalAllSheet.getCell(row, 2).value;
        if (cellValue != null) {
          for (let col = 1; col <= 5; col++) {
            const cell = TotalAllSheet.getCell(row, col);
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFCCCC" },
            };
            cell.font = {
              bold: true,
              name: "Khmer OS Battambang",
            };
          }
        }
      }

      // Save the modified workbook as a Blob with XLSX format
      const blob = await workbook.xlsx.writeBuffer();
      const name_report = `StaffSummaryReport-${moment().format(
        "MM-DD-YYYY-HH:mm:ss"
      )}`;
      FileSaver.saveAs(new Blob([blob]), `${name_report}.xlsx`);
    } catch (error) {
      console.error("An error occurred while generating the report:", error); // Log detailed error
      openNotification("error", "Error", "Error while downloading report");
    } finally {
      setLoadingSpin(false);
    }
  };

  const onCancelDownload = () => {
    setDownloadReport(false);
  };
  const opOpenModelDownloadReport = () => {
    setDownloadReport(true);
  };

  const onShowSizeChange = async (current) => {
    console.log(current);
    setPageNumber(current);
    setCurrentPage(current);
    try {
      setLoadingSpin(false);
      var objTmp = {
        ...objFilter,
        page: current,
      };
      await new Promise((resolve) => setTimeout(resolve, 400));
      setObjFilter(objTmp);
      getList(objTmp);
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };

  return (
    <div>
      <div>
        <div className="sprinSave-container">
          {/* Your other components */}
          {loadingSpin && (
            <div className="sprinSave">
              <Circles
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}
        </div>

        {/* <div className={style.sarchPenel} style={boxHeader}>
          <div className={style.sarchPenel}>
            <Space>
              {boxHeaderTagText("មន្រ្តីរាជការ បុគ្គលិកជួល...")}

              <div style={{ display: "flex" }}>
                <Space>
                  <Input placeholder="Search..." allowClear />
                </Space>
              </div>
            </Space>
          </div>
          <div>
            <Space>
              <Button
                className="buttonstylekhmer"
                onClick={opOpenModelDownloadReport}
                type="primary"
                icon={<BsDownload />}
              >
                ទាយយករបាយការណ៍
              </Button>
            </Space>
          </div>
        </div> */}

        <div style={boxHeader}>
          <div className="Header-Setting-Grid">
            <div className="Header-Setting-Grid-R">
              {boxHeaderTagText("មន្រ្តីរាជការ បុគ្គលិកជួល...")}

              <Space>
                <Input
                  className="styleTageStatusForm"
                  placeholder="Search..."
                  allowClear
                />
              </Space>
            </div>

            <div>
              <Space>
                <Button
                  className="buttonstylekhmer"
                  onClick={opOpenModelDownloadReport}
                  type="primary"
                  icon={<BsDownload />}
                >
                  ទាយយករបាយការណ៍
                </Button>
              </Space>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="sprinCustome">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Table
            size="small"
            tableLayout="auto"
            columns={columns}
            dataSource={dataSours}
            pagination={false}
          />
        )}
        <div style={{ marginTop: 10 }}>
          <Pagination
            showSizeChanger
            onChange={onShowSizeChange}
            defaultCurrent={currentPage}
            total={totalpage}
          />
        </div>
      </div>
      <Modal
        title="ទាញរបាយការណ៍"
        className="custom-modal" // Use the custom-modal class for responsive styles
        open={DownloadReport}
        onCancel={onCancelDownload}
        footer={[
          <div>
            <Button
              className="custom-form-item"
              type="primary"
              onClick={downloadExcel}
            >
              ទាញយកសង្ខេបរបាយការណ៍បុគ្គលិក (Excel)
            </Button>
          </div>,
        ]}
      >
        <div style={{ width: "520px" }}>
          <Space>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Space style={{ width: "100%" }}>
                ចាប់ពីថ្ងៃទីខែ :
                <DatePicker />
              </Space>
            </div>
            <div>
              <Space>
                ដល់ថ្ងៃទីខែ :
                <DatePicker />
              </Space>
            </div>
          </Space>
        </div>
      </Modal>
    </div>
  );
};

export default EmployeePage;
