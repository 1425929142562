import { useEffect, useState } from "react";
import {
  PlusCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import {
  Pagination,
  Modal,
  Button,
  Form,
  Input,
  Item,
  UserOutlined,
  Select,
  Space,
  Tag,
  Checkbox,
  Spin,
} from "antd";
import "../../styles/Home-module.css";
import styles from "../../styles/Font.css";
import { request } from "../../share/request";
import Table from "react-bootstrap/Table";
import { Stack } from "react-bootstrap";

import { openNotification } from "../../share/message";
import { Circles } from "react-loader-spinner";
import { getCurrentUser, getPermission } from "../../share/getProfile";
import { getTageStyle } from "../../share/getStatusContent";
import { formateDateClientTime, formateDateSystem } from "../../share/helper";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";

const { Option } = Select;
const SkillPage = () => {
  const getProfileOrg = getCurrentUser();
  const [list, setList] = useState([]);
  const [UserID, setUserID] = useState("");
  const [UserSkill_kh, setUserSkillKhmer] = useState("");
  const [UserSkill_eng, setUserSkillEnglish] = useState("");
  const [UserOther, setUserOther] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingSpin, setLoadingSpin] = useState(false);

  const [TotalRecords, setTotalRecords] = useState();
  const [TotalPage, setTotalPages] = useState();
  const [PageNumber, setPageNumber] = useState(1);
  const [objFilter, setObjFilter] = useState({
    page: 1,
    search_skill_kh: "",
    org_code: getProfileOrg.org_code,
  });
  useEffect(() => {
    // block form load
    getList(objFilter);
  }, []);
  // -----------------  permisstion page --------------------
  const userPermission = getPermission();
  const SkillPageValue = userPermission.find(
    (permission) => permission.pagename === "skillpage"
  ) || { add: 0, edit: 0, deleted: 0 };
  const {
    add: addvalue,
    edit: Editvalue,
    deleted: deletevalue,
  } = SkillPageValue;
  // -----------------  end permisstion page --------------------

  const getList = async (objFilter) => {
    try {
      // Define query parameters, including page and search skill if provided
      const params = new URLSearchParams({
        page: objFilter.page,
        search_skill_kh: objFilter.search_skill_kh || "",
        org_code: objFilter.org_code,
      });
      console.log("Query", params.toString());

      await new Promise((resolve) => setTimeout(resolve, 400)); // Optional delay for loading effect

      // Send request with query parameters
      const res = await request(`skill?${params.toString()}`, "get", {});

      // Check and set response data
      console.log("Response Data:", res);
      if (res) {
        console.log("Skills Length:", res.API_Server_ListSkill.length);
        setList(res.API_Server_ListSkill);
        setTotalPages(res.total_pages);
        setTotalRecords(res.total_records);
        setCurrentPage(res.page);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  //-------------- page management ----------------------
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = list.slice(indexOfFirstItem, indexOfLastItem);

  //-------------------------------------------

  // ----------------- open modle ----------
  const [visableModelDelete, setVisableModeleDelete] = useState(false);
  const onDeleteModel = (item) => {
    setUserID(item.id);
    setUserSkillEnglish(item.skill_kh);
    setVisableModeleDelete(true);
  };
  const onCLoseModelDelete = () => {
    setVisableModeleDelete(false);
  };
  const onComfirmModelDelete = async () => {
    console.log("ID delete is : " + UserID);
    const res = await request("skill/" + UserID, "delete", {});
    setVisableModeleDelete(false);
    if (res) {
      getList(objFilter);
      setLoadingSpin(true);
      await new Promise((resolve) => setTimeout(resolve, 400));
      openNotification("success", "Success", "Delete Success !");
    } else {
      alert("Error!");
    }
    setLoadingSpin(false);
  };
  // --------------- end model delete--------

  //---------------- statuse --------------
  const getStatusStyle = (status) => {
    switch (status) {
      case 1:
        return (
          <Button
            type="primary"
            ghost
            shape="round"
            icon={<CheckCircleOutlined />}
          >
            {" "}
            Enable{" "}
          </Button>
        );
      case 0:
        return (
          <Button
            type="primary"
            danger
            ghost
            shape="round"
            icon={<ClockCircleOutlined />}
          >
            {" "}
            Disable{" "}
          </Button>
        );

      default:
        return null;
    }
  };
  // ------------- end status----------

  //---------------------------

  //------------- save ----------
  const [visableNewModel, setVisableNewModel] = useState(false);
  const onOpenSaveNew = () => {
    setUserID("");
    console.log("user id " + UserID);
    setUserSkillKhmer("");
    setUserSkillEnglish("");
    setUserOther("");
    setVisableNewModel(true);
    setEnable(true);
    setDisable(false);
  };
  const onCLoseNewDataModel = () => {
    setVisableNewModel(false);
  };
  //-------------   enable and disable ---------------

  const [enable, setEnable] = useState(false);
  const [disable, setDisable] = useState(false);
  const handleCheckboxChange_Enable = (e) => {
    setEnable(e.target.checked);
    setDisable(false);
  };
  const handleCheckboxChange_Diable = (e) => {
    setDisable(e.target.checked);
    setEnable(false);
  };
  //--------------------------------------------

  //------------------- save data to position--------

  //   const [status,setStatus]=useState(1);
  const onSaveData = async () => {
    if (UserID == "") {
      var data = {
        skill_kh: UserSkill_kh,
        skill_eng: UserSkill_eng,
        create_by: getProfileOrg.fullname,
        create_date: formateDateClientTime(Date.now()),
        status: enable == true ? 1 : disable == true ? 0 : 0,
        other: UserOther,
        org_code: getProfileOrg.org_code,
      };
      const res = request("skill", "post", data);
      setVisableNewModel(false);
      if (res) {
        setLoadingSpin(true);
        await new Promise((resolve) => setTimeout(resolve, 400));
        openNotification("success", "Success", "Save  Success");
      } else {
        alert("Error!");
      }
      getList(objFilter); // re call function list
      setLoadingSpin(false);
    } else {
      var data = {
        id: UserID,
        skill_kh: UserSkill_kh,
        skill_eng: UserSkill_eng,
        create_date: formateDateClientTime(Date.now()),
        create_by: getProfileOrg.fullname,
        status: enable == true ? 1 : disable == true ? 0 : 0,
        other: UserOther,
        org_code: getProfileOrg.org_code,
      };
      const res = request("skill", "put", data);
      setVisableNewModel(false);
      if (res) {
        setLoadingSpin(true);
        await new Promise((resolve) => setTimeout(resolve, 400));
        openNotification("success", "Success", "Update  Success");
      } else {
        alert("Error!");
      }
      getList(objFilter); // re call function list
      setLoadingSpin(false);
    }
  };

  const onChangeKH = (event) => {
    setUserSkillKhmer(event.target.value);
  };
  const onChangeEN = (event) => {
    setUserSkillEnglish(event.target.value);
  };
  const onChangeOther = (event) => {
    setUserOther(event.target.value);
  };

  //--------------------

  //--------------- Eidt data ----------
  const onOpenEdit = (item) => {
    setUserID(item.id);
    setUserSkillKhmer(item.skill_kh);
    setUserSkillEnglish(item.skill_eng);
    setUserOther(item.other);
    setVisableNewModel(true);
    if (item.status === 1) {
      return setEnable(true), setDisable(false);
    } else {
      return setEnable(false), setDisable(true);
    }
  };

  //-------------------------
  const onCancelModel = () => {
    setVisableNewModel(false);
  };
  const onCanceDelete = () => {
    setVisableModeleDelete(false);
  };

  const onShowSizeChange = (current) => {
    console.log(current);
    setPageNumber(current);

    var objTmp = {
      ...objFilter,
      page: current,
    };
    setObjFilter(objTmp);
    getList(objTmp);
  };
  const OnSearchText = (value) => {
    setCurrentPage(1); // Reset to page 1 on search
    try {
      var objTmp = {
        ...objFilter,
        search_skill_kh: value.target.value, // Set the department name
        page: value.target.value === "" ? 1 : objFilter.page, // Set page to 1 if input is empty, otherwise retain current page
      };
      setObjFilter(objTmp); // Update the filter state
      getList(objTmp); // Fetch the updated list
    } catch (error) {
      console.error("Error in OnSearchText:", error); // Log any errors
    }
  };
  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>

      <div style={boxHeader}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {boxHeaderTagText("ការគ្រប់គ្រង់ ជំនាញផ្សេងៗ")}
          <Input
            style={{ width: "auto" }}
            placeholder="Search..."
            onChange={OnSearchText}
            allowClear
          />
        </div>
        <Button
          onClick={onOpenSaveNew}
          disabled={!addvalue}
          className="custom-form-item"
          type="primary"
          icon={<PlusCircleOutlined />}
        >
          បន្ថែមថ្មី
        </Button>
      </div>

      <div>
        {loading ? (
          <div className="sprinCustome">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Table
            // striped
            // bordered
            // hover
            className="rounded-table"
            itemsPerPage={10}
          >
            <thead>
              <tr class="table-success">
                <th>NO</th>
                <th>ជំនាញផ្សេងៗ </th>

                <th>ជំនាញ English</th>

                <th>ថ្ងៃបង្កើត</th>
                <th>អ្នកបង្កើត</th>
                <th>ស្ថានភាព</th>
                <th>ផ្សេងៗ</th>
                <th>សកម្មភាព</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => (
                <tr key={index}>
                  <td>{10 * (PageNumber - 1) + (index + 1)}</td>
                  <td>{item.skill_kh}</td>
                  <td>{item.skill_eng}</td>
                  <td>{formateDateClientTime(item.create_date)}</td>
                  <td> {getTageStyle(item.create_by)}</td>
                  <td>{getStatusStyle(item.status)}</td>
                  <td>{item.other}</td>

                  <td style={{ width: 100 }}>
                    <Stack gap={1} direction="horizontal">
                      <Button
                        disabled={!Editvalue}
                        type="primary"
                        onClick={() => onOpenEdit(item)}
                      >
                        Edit
                      </Button>{" "}
                      <Button
                        disabled={!deletevalue}
                        type="primary"
                        onClick={() => onDeleteModel(item)}
                        danger
                      >
                        Delete
                      </Button>
                    </Stack>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        <Pagination
          //   showSizeChanger
          onChange={onShowSizeChange}
          defaultCurrent={PageNumber}
          total={TotalRecords}
        />

        <Modal
          title={UserID == "" ? "New Skill" : "Update Skill"}
          className="styleTageStatusForm"
          open={visableNewModel}
          onCancel={onCancelModel}
          footer={[
            <div>
              <Space>
                <Button onClick={onCLoseNewDataModel}>Cancel</Button>
                <Button type="primary" onClick={onSaveData}>
                  {UserID == "" ? "Save" : "Update"}
                </Button>
              </Space>
            </div>,
          ]}
        >
          <div style={{ display: "flex", justifyContent: "start" }}>
            <div>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ឈ្មោះជំនាញ / Skill in Khmer
              </p>
              <Input.TextArea
                className="styleTageStatusForm"
                rows={2}
                placeholder="input other"
                value={UserSkill_kh}
                onChange={onChangeKH}
                style={{ width: 250 }}
              />

              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ឈ្មោះជំនាញ / English
              </p>

              <Input.TextArea
                className="styleTageStatusForm"
                rows={2}
                placeholder="input other"
                value={UserSkill_eng}
                onChange={onChangeEN}
                style={{ width: 250 }}
              />

              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ផ្សេងៗ / Other
              </p>
              <Input.TextArea
                className="styleTageStatusForm"
                rows={4}
                placeholder="input other"
                value={UserOther}
                onChange={onChangeOther}
                style={{ width: 250 }}
              />
            </div>

            <div style={{ paddingLeft: 20 }}>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ស្ថានភាព / Status
              </p>

              <Tag>
                {" "}
                <div style={{ padding: 10 }}>
                  <Checkbox
                    checked={enable}
                    onChange={handleCheckboxChange_Enable}
                  >
                    Enable
                  </Checkbox>
                  <Checkbox
                    style={{ color: "red" }}
                    checked={disable}
                    onChange={handleCheckboxChange_Diable}
                  >
                    Disable
                  </Checkbox>
                </div>
              </Tag>
            </div>
          </div>
        </Modal>

        <Modal
          title="លុបជំនាញ "
          className="custom-form-item"
          open={visableModelDelete}
          onCancel={onCanceDelete}
          footer={[
            <div>
              <Button onClick={onCLoseModelDelete}>No</Button>
              <Button onClick={onComfirmModelDelete} type="primary">
                Yes
              </Button>
            </div>,
          ]}
        >
          <p className="custom-form-item" style={{ color: "red" }}>
            តើអ្នកចង់លុបជំនាញ ៖ {UserSkill_kh} នេះចេញពីប្រព័ន្ធ ?
          </p>
        </Modal>
      </div>
    </div>
  );
};

export default SkillPage;
