import { useState, useEffect } from "react";
import { getCurrentUser } from "./getProfile";
import { request } from "./request";

const GetListShiftWork = () => {
  const [list, setListShift] = useState([]);

  useEffect(() => {
    const fetchShiftWork = async () => {
      const getProfile = getCurrentUser();
      const param = new URLSearchParams({
        org_code: getProfile.org_code,
      });
      try {
        const res = await request("shifttype?" + param.toString(), "get", {});
        await new Promise((resolve) => setTimeout(resolve, 400)); // Delay for demonstration, if needed
        if (res) {
          setListShift(res.api_list_shift);
        }
      } catch (error) {
        console.error("Error fetching shift work list:", error);
      }
    };

    fetchShiftWork();
  }, []); // Empty dependency array to run only on mount

  return list;
};

export { GetListShiftWork };
