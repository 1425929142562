import React, { useEffect, useState, useRef } from "react";
import {
  DownloadOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import {
  Modal,
  message,
  Upload,
  Button,
  Form,
  Input,
  Item,
  UserOutlined,
  Select,
  Space,
  Tag,
  Checkbox,
  Spin,
  Popconfirm,
  DatePicker,
} from "antd";
import "../../styles/Home-module.css";
import styles from "../../styles/Font.css";
import { request } from "../../share/request";
import Table from "react-bootstrap/Table";
import { Stack } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { openNotification } from "../../share/message";
import { Circles } from "react-loader-spinner";
import { getPermission } from "../../share/getProfile";
import {
  getLettersContent,
  getStatusContent,
  getTageStyle,
} from "../../share/getStatusContent";
import {
  formateDateClient,
  formateDateSystem,
  linkPathPhotoDocument,
  toKhmerDay,
  toKhmerMonth,
  toKhmerYear,
} from "../../share/helper";
import { MdOutlineEdit, MdOutlineDelete } from "react-icons/md";
import { HiOutlineDownload } from "react-icons/hi";
import { FaRegEye } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";
import { BsDownload } from "react-icons/bs";
import ComponentToPrint from "../letter/PrintComponents";
import MyDatePicker from "../../share/MyDatePicker";
import { useReactToPrint } from "react-to-print";
import { PlusOutlined } from "@ant-design/icons";
import { BiCheckDouble } from "react-icons/bi";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";

const { Option } = Select;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const LettersPage = () => {
  const [totalCount, setTotalCount] = useState("");
  const [listdocument, setlistdocument] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [list, setList] = useState([]);
  const [listJoiner, setListJoiner] = useState([]);
  const [UserID, setUserID] = useState("");
  const [UserFile_ID, setUserFile_ID] = useState("");
  const [UserLetters_type, setUserLetters_Type] = useState("");
  const [UserLetters_FromTo, setUserLetters_FromTo] = useState("");
  const [UserLettter_number, setUserLettter_number] = useState("");
  const [UserLettter_Date, setUserLettter_Date] = useState("");
  const [UserLetters_Note, setUserLeters_Note] = useState();
  const [UserLettter_Description, setUserLettter_Description] = useState("");
  const [UserLetters_DateStart, setUserLetters_DateStart] = useState("");
  const [UserLetters_DateEnd, setUserLetters_DateEnd] = useState("");
  const [UserLetters_location, setUserLetters_location] = useState("");
  const [UserLetters_PrepareBy, setUserLetters_PrepareBy] = useState("");
  const [UserLetters_SupportBy, setUserLetters_SupportBy] = useState("");
  const [UserLetters_StaffCodeJoin, setUserLetters_StaffCodeJoin] = useState();

  const [imageDeletePath, setImageDeletePath] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingSpin, setLoadingSpin] = useState(false);

  // -----------------  permisstion page --------------------
  const userPermission = getPermission();
  const letterPageValue = userPermission.find(
    (permission) => permission.pagename === "letterspage"
  ) || { add: 0, edit: 0, deleted: 0, print: 0 };
  const {
    add: addvalue,
    edit: Editvalue,
    deleted: deletevalue,
    print: printValue,
  } = letterPageValue;
  // -----------------  end permisstion page --------------------
  const [previewFile, setPreviewFile] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const handleCancel = () => setPreviewOpen(false);

  // print component
  const componentRef = useRef(null);
  const [itemPrint, setItemPrint] = useState(null);
  const [shouldPrint, setShouldPrint] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  // -------get car list
  const [listcar, setListCar] = useState([]);
  const [car_name, setCar_name] = useState(null);
  useEffect(() => {
    if (dataFetched && shouldPrint) {
      printFunction();
      setDataFetched(false); // Reset dataFetched after printing
      setShouldPrint(false); // Reset shouldPrint after printing
    }
  }, [dataFetched, shouldPrint]);

  //---------------- get Joiner------------

  const [getListJoiner, setGetListJoinner] = useState([]);
  const handlePrint = async (item) => {
    const res = await request("letters/" + item.id, "get", {});
    if (res) {
      setGetListJoinner(res.API_Server_listJoiner);
    }
    setDataFetched(true);
    setItemPrint(item);
    setShouldPrint(true);
  };
  // end print component
  const printFunction = useReactToPrint({
    content: () => componentRef.current,
  });
  // const handlePreview = async (file) => {
  //   console.log("file this is : " + file);
  //   // Close any existing preview modal if needed
  //   setVisableModelPreviewFile(false);

  //   // Check if the file has a URL or needs to generate a preview
  //   if (!file.url && !file.preview) {
  //     file.preview = await getBase64(file.originFileObj);
  //   }

  //   // Set the preview image, handling different file types
  //   if (file.type === "application/pdf") {
  //     setPreviewImage("/PDFLogo.png"); // Use a PDF logo for PDF files
  //   } else {
  //     setPreviewImage(file.url || file.preview);
  //   }

  //   // Log the preview image URL or base64 data
  //   console.log("link download: " + (file.url || file.preview));

  //   // Open the preview modal and set the title
  //   setPreviewOpen(true);
  //   setPreviewTitle(
  //     file.name ||
  //       (file.url ? file.url.substring(file.url.lastIndexOf("/") + 1) : "")
  //   );
  // };

  const handlePreview = async (file) => {
    setVisableModelPreviewFile(false);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    console.log("link donwload " + previewImage);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const modifiedFileList = fileList.map((file) => ({
    ...file,
    name: file.name_kh, // Replace name with name_kh if available
  }));
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const handleRemove = async (file) => {
    setImageDeletePath(file.name);
    console.log("Path delete" + imageDeletePath);
    const res = await request("letters/" + file.uid, "delete", {
      imageDeletePath,
    });
    if (res) {
      setFileList((prevFileList) =>
        prevFileList.filter((item) => item.uid !== file.uid)
      );
      console.log("delete file success");
    }
  };

  const handleConfirmDelete = () => {
    // Perform the deletion logic here, if needed
    // This function will be called when the user confirms the deletion
  };
  //--------------------------- end list photo
  const getList = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 400));
      const res = await request("letters", "get", {});
      if (res) {
        setList(res.API_Server_ListLetters[1]);
        setListJoiner(res.API_Server_ListJoiner);
        console.log("list of LETTER" + res.API_Server_ListJoiner);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    console.log("this path of delete file  ", imageDeletePath);
  }, [imageDeletePath]);

  const DataJoiner = listJoiner.map((staff, index) => ({
    label: staff.staffname_kh1,
    value: staff.staffname_kh1, // Using idnumber as the value
  }));
  const [StaffName, setStaffName] = useState("");
  const onChange_UserLetter_Staffjoin = (value) => {
    // Log the selected value(s) for debugging
    console.log("Selected value(s):", value);

    // Filter the DataJoiner array to get only the options corresponding to the selected value(s)
    const selectedOptions = DataJoiner.filter((option) =>
      value.includes(option.value)
    );

    // Extract idnumbers from the filtered options
    const selectedIdNumbers = selectedOptions.map((option) => option.value);

    // Join the idnumbers into a single string separated by commas
    const staffNamesString = selectedIdNumbers.join(", ");

    // Update state or perform any necessary action with the staffNamesString
    setStaffName(staffNamesString);

    // Log the updated state for debugging
    console.log("Selected staff names:", StaffName);

    setUserLetters_StaffCodeJoin(value);
  };

  //-------------- page management ----------------------
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = list.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  //-------------------------------------------

  // ----------------- open modle ----------
  const [visableModelPreviewFile, setVisableModelPreviewFile] = useState(false);
  const onOpenModelPreviewFile = async (item) => {
    setUserID(item.id);
    const res = await request("letters/" + item.file_id, "get", {});
    console.log("API response:", res);

    if (res) {
      setlistdocument(res.API_Server_ListDocument || []);
      setTotalCount(res.API_Server_Total || 0);
      const fileList = res.API_Server_ListDocument.map((docItem, index) => ({
        uid: docItem.id,
        name: docItem.file_name,
        url: linkPathPhotoDocument + docItem.file_name,
        name_kh: docItem.file_name_kh,
        status: "done",
      }));

      setFileList(fileList);
    } else {
      setFileList([]);
    }
    setVisableModelPreviewFile(true);
  };
  const onCLoseModelDelete = () => {
    // setlistdocument([])
    // setFileList([])
    setVisableModelPreviewFile(false);
  };

  //---------------------------
  const getListCar = async () => {
    const id = 1;
    const res = await request("letters/" + id, "get", {});
    console.log("API response:", res);

    if (res) {
      setListCar(res.API_Server_CarUsing);
    }
  };
  //------------- save ----------
  const [visableNewModel, setVisableNewModel] = useState(false);
  const onOpenSaveNew = () => {
    getListCar();
    const today = new Date();
    // Formatting the date if needed (e.g., "YYYY-MM-DD")
    const formattedDate = today.toISOString().split("T")[0];
    setFileList(null);
    setUserID("");
    setTotalCount("");
    setStaffName("");
    setFileList([]);
    setlistdocument([]);
    setPreviewImage("");
    setPreviewTitle("");
    setUserLetters_Type("ចូល");
    setUserLetters_FromTo("");
    setUserLettter_number("");
    setUserLettter_Date(formattedDate);
    setUserLeters_Note("");
    setUserLetters_StaffCodeJoin("");
    setUserLettter_Description("");
    setUserLetters_DateStart(formattedDate);
    setUserLetters_DateEnd(formattedDate);
    setUserLetters_location("");
    setUserLetters_PrepareBy("");
    setUserLetters_SupportBy("");
    setCar_name("");
    setVisableNewModel(true);
    setEnable(true);
    setDisable(false);
  };

  //-------------   enable and disable ---------------

  const [enable, setEnable] = useState(false);
  const [disable, setDisable] = useState(false);
  const handleCheckboxChange_Enable = (e) => {
    setEnable(e.target.checked);
    setDisable(false);
  };
  const handleCheckboxChange_Diable = (e) => {
    setDisable(e.target.checked);
    setEnable(false);
  };
  //--------------------------------------------

  //------------------- save data to letters --------

  const onSaveData = async () => {
    const formData = new FormData();
    formData.append("id", UserID);
    formData.append("transfer_name", UserLetters_type);
    formData.append("letters_from", UserLetters_FromTo);
    formData.append("letters_number", UserLettter_number);
    formData.append("letters_date", UserLettter_Date);
    formData.append("letters_type", UserLetters_Note);
    formData.append("invite_staff", StaffName);
    formData.append("join_des", UserLettter_Description);
    formData.append("joindate_start", UserLetters_DateStart);
    formData.append("joindate_end", UserLetters_DateEnd);
    formData.append("location", UserLetters_location);
    formData.append("prepare_by", UserLetters_PrepareBy);
    formData.append("support_by", UserLetters_SupportBy);
    formData.append("create_By", "admin update");
    formData.append("status", enable ? 1 : disable ? 0 : 0);
    formData.append("file_id", update_file_id);
    formData.append("other", "letters");
    formData.append("carusing", car_name);

    fileList.forEach((file) => {
      if (file.originFileObj instanceof Blob) {
        formData.append(
          UserID !== "" ? "upload_file_update" : "upload_file",
          file.originFileObj,
          file.name
        );
      } else {
        console.error("File object is not a Blob:", file);
      }
    });
    // Append each file to FormData
    if (UserID == "") {
      const res = request("letters", "post", formData);
      setVisableNewModel(false);
      if (res) {
        getList(); // re call function list
        setLoadingSpin(true);
        await new Promise((resolve) => setTimeout(resolve, 400));
        openNotification("success", "Success", "Save  Success");
      } else {
        alert("Error!");
      }
      setLoadingSpin(false);
    } else {
      const res = request("letters", "put", formData);
      setVisableNewModel(false);
      if (res) {
        getList(); // re call function list
        setLoadingSpin(true);
        await new Promise((resolve) => setTimeout(resolve, 400));
        openNotification("success", "Success", "Update  Success");
      } else {
        alert("Error!");
      }
      setLoadingSpin(false);
    }
  };

  const [DownloadReport, setDownloadReport] = useState(false);
  const onClickDownloadReport = () => {
    setDownloadReport(true);
  };

  //-------------------------
  const onCancelModel = () => {
    setFileList(null);
    setlistdocument(null);
    setFileList([]);
    setlistdocument([]);

    setVisableNewModel(false);
  };
  const onCanceDelete = () => {
    setVisableModelPreviewFile(false);
  };

  const onChange_UserLetters_Type = (event) => {
    setUserLetters_Type(event);
    console.log(UserLetters_type);
  };
  const onChange_UserLetters_FromTo = (event) => {
    setUserLetters_FromTo(event.target.value);
  };
  const onChange_UserLettter_number = (event) => {
    setUserLettter_number(event.target.value);
  };
  const onChange_UserLetters_Date = (value) => {
    setUserLettter_Date(formateDateSystem(value));
  };
  const onChange_UserLetters_Note = (value) => {
    setUserLeters_Note(value);
  };
  const onChange_UserLettter_Description = (event) => {
    setUserLettter_Description(event.target.value);
  };
  const onChange_UserLetters_DateStart = (value) => {
    setUserLetters_DateStart(formateDateSystem(value));
  };
  const onChange_UserLetters_DateEnd = (value) => {
    setUserLetters_DateEnd(formateDateSystem(value));
  };
  const onChange_UserLetters_location = (event) => {
    setUserLetters_location(event.target.value);
  };
  const onChange_UserLetters_PrepareBy = (event) => {
    setUserLetters_PrepareBy(event.target.value);
  };
  const onChange_UserLetters_SupportBy = (event) => {
    setUserLetters_SupportBy(event.target.value);
  };
  useEffect(() => {
    console.log("user file id " + UserFile_ID);
  }, [UserFile_ID]);
  useEffect(() => {
    console.log("File list updated:", fileList);
  }, [fileList, listdocument]);
  const [update_file_id, setUpdateFileID] = useState("");
  const [SaveFile, setSaveFile] = useState();

  const onEditData = async (item) => {
    try {
      setUserFile_ID(item.file_id);
      console.log("user file id " + UserFile_ID);

      const res = await request("letters/" + item.file_id, "get", {});
      console.log("API response:", res);

      if (res) {
        setlistdocument(res.API_Server_ListDocument || []);
        console.log("List document:", res.API_Server_ListDocument);

        setTotalCount(res.API_Server_Total || 0);
        console.log("Total count:", res.API_Server_Total);
        setListCar(res.API_Server_CarUsing);

        const fileList = res.API_Server_ListDocument.map((docItem, index) => ({
          uid: docItem.id,
          name: docItem.file_name,
          name_kh: docItem.file_name_kh,
          url: linkPathPhotoDocument + docItem.file_name,
          status: "done",
        }));
        console.log("Upload", fileList);
        setFileList(fileList);
      } else {
        console.log("No documents found.");
        setFileList([]);
      }

      setStaffName(item.invite_staff);
      setUserID(item.id);
      const arrayValueForSelect = item.invite_staff
        .split(",")
        .map((value) => value.trim());

      setUserLetters_Type(item.transfer_name);
      setUserLetters_FromTo(item.letters_from);
      setUserLettter_number(item.letters_number);
      setUserLettter_Date(formateDateSystem(item.letters_date));
      setUserLeters_Note(item.letters_type);
      setUserLetters_StaffCodeJoin(arrayValueForSelect);
      setUserLettter_Description(item.join_des);
      setUserLetters_DateStart(formateDateSystem(item.joindate_start));
      setUserLetters_DateEnd(formateDateSystem(item.joindate_end));
      setUserLetters_location(item.location);
      setUserLetters_PrepareBy(item.prepare_by);
      setUserLetters_SupportBy(item.support_by);
      setUpdateFileID(item.file_id);
      setCar_name(item.carusing);
      setVisableNewModel(true);

      if (item.status === 1) {
        setEnable(true);
        setDisable(false);
      } else {
        setEnable(false);
        setDisable(true);
      }
    } catch (error) {
      // Handle errors appropriately
      console.error("Error occurred:", error);
    }
  };
  const onClick_Download = () => {
    window.open(previewImage, "_blank");
  };

  const formattedDate = (date) => {
    const [day, month, year] = date.split("/");
    return ` ${"ថ្ងៃទី" + toKhmerDay(day)} ${"ខែ" + toKhmerMonth(month)} ${
      "ឆ្នាំ" + toKhmerYear(year)
    }`;
  };

  class MyComponentToPrint extends React.Component {
    render() {
      return (
        <div className="print-content">
          {itemPrint && (
            <ComponentToPrint
              reason={itemPrint.join_des}
              node={
                "លិខិតលេខ​ " +
                itemPrint.letters_number +
                "  ចុះ" +
                formattedDate(formateDateClient(itemPrint.letters_date)) +
                " របស់ " +
                itemPrint.prepare_by
              }
              liststaff={getListJoiner}
              date_start={formattedDate(
                formateDateClient(itemPrint.joindate_start)
              )}
              date_end={formattedDate(
                formateDateClient(itemPrint.joindate_end)
              )}
              support_by={itemPrint.support_by}
              car_using={itemPrint.carusing}
            />
          )}
        </div>
      );
    }
  }

  const onChangeCarusing = (value) => {
    setCar_name(value);
  };
  const getFileType = (file) => {
    // Get file extension from the name
    const extension = file.name.split(".").pop().toLowerCase();
    // Check if the file extension is 'pdf'
    return extension === "pdf" ? "application/pdf" : "";
  };
  // const itemRender = (originNode, file, fileList, actions) => {
  //   const isPDF = getFileType(file) === "application/pdf";
  //   const  isExcel = getFileType(file) === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  //   const isWord = getFileType(file) === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  //   const isPowerPoint = getFileType(file) === "application/vnd.openxmlformats-officedocument.presentationml.presentation";

  //   return (
  //     <div style={{ height: 100, width: 100 }}>
  //       {isPDF ? (
  //         <img
  //           src="https://seeklogo.com/images/A/acrobat-file-pdf-logo-37A1BFDE35-seeklogo.com.png"
  //           alt="PDF Preview"
  //           style={{ alignItems: "center", width: "90%", height: "100%" }}
  //         />
  //       ) : (
  //         originNode
  //       )}

  //       <div style={{ textAlign: "center", marginTop: 8 }}>{file.name_kh}</div>
  //     </div>
  //   );
  // };
  const itemRender = (originNode, file, fileList, actions) => {
    const getFileType = (file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      switch (extension) {
        case "pdf":
          return "application/pdf";
        case "xlsx":
        case "xls":
          return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        case "docx":
        case "doc":
          return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        case "pptx":
        case "ppt":
          return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
        default:
          return "";
      }
    };

    const isPDF = getFileType(file) === "application/pdf";
    const isExcel =
      getFileType(file) ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const isWord =
      getFileType(file) ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    const isPowerPoint =
      getFileType(file) ===
      "application/vnd.openxmlformats-officedocument.presentationml.presentation";

    return (
      <div style={{ height: 100, width: 100 }}>
        {isPDF ? (
          <img
            src="https://seeklogo.com/images/A/acrobat-file-pdf-logo-37A1BFDE35-seeklogo.com.png"
            alt="PDF Preview"
            style={{ alignItems: "center", width: "90%", height: "100%" }}
          />
        ) : isExcel ? (
          <img
            src="https://i.pinimg.com/originals/1b/db/8a/1bdb8ac897512116cbac58ffe7560d82.png"
            alt="Excel Preview"
            style={{ alignItems: "center", width: "100%", height: "100%" }}
          />
        ) : isWord ? (
          <img
            src="https://static.vecteezy.com/system/resources/previews/027/179/388/non_2x/microsoft-word-icon-logo-symbol-free-png.png"
            alt="Word Preview"
            style={{ alignItems: "center", width: "100%", height: "100%" }}
          />
        ) : isPowerPoint ? (
          <img
            src="https://seeklogo.com/images/M/microsoft-powerpoint-logo-5EAA3DDBA5-seeklogo.com.png"
            alt="PowerPoint Preview"
            style={{ alignItems: "center", width: "100%", height: "100%" }}
          />
        ) : (
          originNode
        )}

        <div style={{ textAlign: "center", marginTop: 8 }}>{file.name_kh}</div>
      </div>
    );
  };

  const deleteList = async (item) => {
    try {
      console.log("onDeleteListItem", item);
      const res = await request("letterslist/" + item, "delete", {});
      if (res) {
        openNotification("success", "Success", "Successfully deleted list");
      } else {
        openNotification("error", "Error", "Failed to delete list");
      }
    } catch (error) {
      openNotification("error", "Error", error.message);
    }
  };
  const onDeleteListItem = async (item) => {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: "Are you sure you want to delete this file?",
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          deleteList(item.id);
          getList();
        },
        // onCancel: () => {reject}
      });
    });
  };
  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>

      <div style={boxHeader}>
        <div style={{ display: "flex", justifyItems: "center", width: "auto" }}>
          <Space>
            {boxHeaderTagText("ការគ្រប់គ្រង់លិខិត[100] ")}
            <Input
              style={{ width: 200 }}
              className="styleTageStatusForm"
              placeholder="Search..."
              allowClear
            />
          </Space>
        </div>
        <div>
          <Space>
            <Button
              className="buttonstylekhmer"
              onClick={onClickDownloadReport}
              type="primary"
              icon={<BsDownload />}
            >
              {" "}
              ទាយយករបាយការណ៍
            </Button>
            <Button
              onClick={onOpenSaveNew}
              disabled={!addvalue}
              className="custom-form-item"
              type="primary"
              icon={<PlusCircleOutlined />}
            >
              បន្ថែមថ្មី
            </Button>
          </Space>{" "}
        </div>
      </div>

      <div>
        {loading ? (
          <div className="sprinCustome">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Table
            // striped
            // bordered
            // hover
            className="rounded-table"
            itemsPerPage={10}
          >
            <thead>
              <tr class="table-success">
                <th>NO</th>
                <th>ប្រភេទលិខិត </th>
                <th>លិខិតលេខ</th>
                <th>ខ្លឹមសារលិខិត</th>
                <th>ថ្ងៃខែឆ្នាំបេសកកម្ម</th>
                <th>ទីតាំងបេសកកម្ម</th>
                <th>រៀបចំដោយ និងថវិការ</th>
                <th>ស្ថានភាព</th>
                <th>សកម្មភាព</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>

                  <td>
                    <div>
                      <div>{getLettersContent(item.transfer_name)}</div>
                      <div style={{ paddingTop: 5 }}>
                        <Tag bordered={false} color="processing">
                          {formateDateClient(item.letters_date)}
                        </Tag>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div>
                        <Tag
                          style={{ fontFamily: "Khmer OS Battambang" }}
                          color="#2db7f5"
                        >
                          {" "}
                          {item.letters_from}{" "}
                        </Tag>
                      </div>
                      <div>
                        <Tag
                          style={{ fontFamily: "Khmer OS Battambang" }}
                          bordered={false}
                          color="processing"
                        >
                          {item.letters_number}
                        </Tag>
                      </div>
                      <div>
                        <Tag
                          style={{ fontFamily: "Khmer OS Battambang" }}
                          color="#2db7f5"
                        >
                          {" "}
                          {item.letters_type}{" "}
                        </Tag>
                      </div>
                    </div>
                  </td>
                  {/* {getTageStyle( item.join_des)} */}

                  <td class="word-wrap">
                    <div style={{ whiteSpace: "pre-wrap", color: "#108ee9" }}>
                      <BiCheckDouble /> {item.join_des}
                    </div>
                  </td>
                  <td>
                    <div>
                      <div>
                        <Tag bordered={false} color="processing">
                          {formateDateClient(item.joindate_start)}
                        </Tag>
                      </div>
                      <div>
                        <Tag bordered={false} color="processing">
                          {formateDateClient(item.joindate_end)}
                        </Tag>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div style={{ color: "rgb(11, 145, 112)" }}>
                        <GrLocation /> {item.location}
                      </div>
                      <div>
                        <Tag
                          style={{
                            paddingTop: 2,
                            fontFamily: "Khmer OS Battambang",
                            width: 120,
                            whiteSpace: "normal",
                          }}
                        >
                          {item.invite_staff}
                        </Tag>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div>{item.prepare_by}</div>
                      <div>
                        <Tag
                          style={{ fontFamily: "Khmer OS Battambang" }}
                          color="#f50"
                        >
                          {item.support_by}
                        </Tag>
                      </div>
                    </div>
                  </td>
                  <td>{getStatusContent(item.status)}</td>
                  <td style={{ width: 100 }}>
                    <Stack gap={1} direction="horizontal">
                      <Button
                        onClick={() => onOpenModelPreviewFile(item)}
                        type="primary"
                        icon={<FaRegEye />}
                      />
                      <Button
                        disabled={!Editvalue}
                        onClick={() => onEditData(item)}
                        type="primary"
                        icon={<MdOutlineEdit />}
                      />
                      <div className="print-container">
                        <MyComponentToPrint
                          ref={componentRef}
                          key={index}
                          item={itemPrint}
                        />
                        <Button
                          danger
                          type="primary"
                          disabled={!printValue}
                          onClick={() => handlePrint(item)}
                          icon={<DownloadOutlined />}
                        />
                      </div>
                      <Button
                        disabled={!printValue}
                        type="primary"
                        onClick={() => onDeleteListItem(item)}
                        icon={<MdOutlineDelete />}
                        danger
                      />

                      {/* <Button disabled={!Editvalue} type="primary" onClick={()=>onOpenEdit(item)}  >Edit</Button>{' '}
                                    <Button  disabled={!deletevalue}  type="primary" onClick={()=>onDeleteModel(item)} danger>Delete</Button> */}
                    </Stack>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        <Pagination>
          {Array.from({ length: Math.ceil(list.length / itemsPerPage) }).map(
            (_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            )
          )}
        </Pagination>

        <Modal
          title={UserID == "" ? "បង្កើតថ្មី" : "កែប្រែ"}
          className="styleTageStatusForm"
          open={visableNewModel}
          onCancel={onCancelModel}
          width={1100}
          footer={[
            <div>
              <Space>
                {" "}
                <Button onClick={onCancelModel}>Cancel</Button>
                <Button type="primary" onClick={onSaveData}>
                  {UserID == "" ? "Save" : "Update"}
                </Button>
              </Space>
            </div>,
          ]}
        >
          <div style={{ display: "flex", justifyContent: "start" }}>
            <div style={{ width: 300 }}>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ប្រភេទលិខិត ៖
              </p>
              <Select
                className="styleTageStatusForm"
                value={UserLetters_type}
                onChange={onChange_UserLetters_Type}
                style={{ width: "100%" }}
                allowClear
              >
                <Option className="styleTageStatusForm" value="ចូល">
                  ចូល
                </Option>
                <Option className="styleTageStatusForm" value="ចេញ">
                  ចេញ
                </Option>
                <Option className="styleTageStatusForm" value="ឯកសារផ្សេងៗ">
                  ឯកសារផ្សេងៗ
                </Option>
              </Select>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                {UserLetters_type === "ចូល" ? "លិខិតចូលមកពី" : ""}
                {UserLetters_type === "ឯកសារផ្សេងៗ" ? "ឯកសារផ្សេងនៅក្នុង" : ""}
                {UserLetters_type === "ចេញ" ? "ចេញទៅ" : ""} ៖
              </p>
              <Input
                value={UserLetters_FromTo}
                onChange={onChange_UserLetters_FromTo}
                className="styleTageStatusForm"
                placeholder="Input...."
              />

              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                លិខិតលេខ ៖
              </p>
              <Input
                value={UserLettter_number}
                onChange={onChange_UserLettter_number}
                className="styleTageStatusForm"
                placeholder="Input...."
              />

              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                {UserLetters_type === "ចូល" ? "ថ្ងៃខែ លិខិតចូល" : ""}
                {UserLetters_type === "ឯកសារផ្សេងៗ"
                  ? "ថ្ងៃរក្សារ ឯកសារផ្សេង"
                  : ""}
                {UserLetters_type === "ចេញ" ? "ថ្ងៃខែ លិខិតចេញ" : ""} ៖
              </p>
              <MyDatePicker
                value={UserLettter_Date}
                onChange={onChange_UserLetters_Date}
                readOnly={false}
                width="100%"
              />

              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                កំណត់សម្គាល់ លិខិត ៖
              </p>

              <Select
                className="styleTageStatusForm"
                value={UserLetters_Note}
                onChange={onChange_UserLetters_Note}
                style={{ width: "100%" }}
                allowClear
              >
                <Option className="styleTageStatusForm" value="លិខិតអញ្ជើញ">
                  លិខិតអញ្ជើញ
                </Option>
                <Option
                  className="styleTageStatusForm"
                  value="លិខិតឧទ្ទេសនាមបេសកកម្ម"
                >
                  លិខិតឧទ្ទេសនាមបេសកកម្ម
                </Option>
                <Option className="styleTageStatusForm" value="លិខិតបង្គាប់ការ">
                  លិខិតបង្គាប់ការ
                </Option>
                <Option className="styleTageStatusForm" value="លិខិតផ្សេងៗ">
                  លិខិតផ្សេងៗ
                </Option>
              </Select>
            </div>

            <div style={{ paddingLeft: 20, width: 300 }}>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ខ្លឹមសារលិខិត ៖
              </p>
              <Input.TextArea
                value={UserLettter_Description}
                onChange={onChange_UserLettter_Description}
                className="styleTageStatusForm"
                rows={10}
                placeholder="បញ្ចូល ខ្លឹមសាររបស់ឯកសារ....."
              />
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ចាប់ផ្តើមថ្ងៃទី ៖
              </p>
              <MyDatePicker
                value={UserLetters_DateStart}
                onChange={onChange_UserLetters_DateStart}
                width="100%"
              />
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ដល់ថ្ងៃទី ៖
              </p>
              <MyDatePicker
                value={UserLetters_DateEnd}
                onChange={onChange_UserLetters_DateEnd}
                width="100%"
              />
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ទីតាំងបេសកកម្ម ៖
              </p>
              <Input.TextArea
                value={UserLetters_location}
                onChange={onChange_UserLetters_location}
                className="styleTageStatusForm"
                rows={2}
                placeholder="បញ្ចូល ទីតាំងបេសកកម្ម....."
              />
            </div>
            <div style={{ paddingLeft: 20, width: 300 }}>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                រៀបចំដោយ ៖
              </p>
              <Input.TextArea
                value={UserLetters_PrepareBy}
                onChange={onChange_UserLetters_PrepareBy}
                className="styleTageStatusForm"
                rows={2}
                placeholder="បញ្ចូល រៀបចំដោយ....."
              />
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ឧបត្ថមលុយដោយ ៖
              </p>
              <Input.TextArea
                value={UserLetters_SupportBy}
                onChange={onChange_UserLetters_SupportBy}
                className="styleTageStatusForm"
                rows={2}
                placeholder="បញ្ចូល រៀបចំដោយ....."
              />
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                មន្ត្រីចូលរួម ៖
              </p>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                value={UserLetters_StaffCodeJoin}
                onChange={onChange_UserLetter_Staffjoin}
                options={DataJoiner}
                showSearch // Enable search functionality
                filterOption={(inputValue, option) =>
                  option.label
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                } // Custom
              />
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ប្រើប្រាស់រថយន្ត / Car Using
              </p>
              <Select
                style={{ width: 250 }}
                // showSearch
                placeholder="Select a Car"
                // optionFilterProp="children"
                onChange={onChangeCarusing}
                value={car_name}
              >
                {listcar.map((option) => (
                  <Option
                    className="styleTageStatusForm"
                    key={option.id}
                    value={option.car_name}
                  >
                    {option.car_name}{" "}
                    {/* Replace with the field from your MySQL table */}
                  </Option>
                ))}
              </Select>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ស្ថានភាព / Status
              </p>

              <Tag>
                <div style={{ padding: 10 }}>
                  <Checkbox
                    checked={enable}
                    onChange={handleCheckboxChange_Enable}
                  >
                    Enable
                  </Checkbox>
                  <Checkbox
                    style={{ color: "red" }}
                    checked={disable}
                    onChange={handleCheckboxChange_Diable}
                  >
                    Disable
                  </Checkbox>
                </div>
              </Tag>
            </div>
            <div style={{ paddingLeft: 20, width: 300 }}>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                File ឯកសាររបស់លិខិត ៖
              </p>

              <Upload
                key={null} //
                action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                listType="picture-card"
                fileList={modifiedFileList}
                onPreview={handlePreview}
                onChange={handleChange}
                beforeRemove={(file) => false} // Prevent automatic removal of files
                onRemove={(file) => {
                  return new Promise((resolve, reject) => {
                    Modal.confirm({
                      title: "Are you sure you want to delete this file?",
                      okText: "Yes",
                      cancelText: "No",
                      onOk: () => {
                        handleRemove(file);
                      },
                      // onCancel: () => {reject}
                    });
                  });
                }}
              >
                {fileList.length >= 8 ? null : uploadButton}
              </Upload>

              <p style={{ margin: 5, color: "darkgray" }}>
                សរុបឯកសារ ៖ <Tag color="#f50">{totalCount}</Tag>{" "}
              </p>
            </div>
          </div>
        </Modal>

        <Modal
          width={500}
          title="មើលលិខិតច្បាប់ដើម"
          className="custom-form-item"
          open={visableModelPreviewFile}
          onCancel={onCanceDelete}
          footer={[
            <div>
              <Button onClick={onCLoseModelDelete}>Close</Button>
            </div>,
          ]}
        >
          <div style={{ height: "auto" }}>
            <Upload
              key={null} //
              listType="picture"
              fileList={modifiedFileList}
              onPreview={handlePreview}
              //itemRender={itemRender}
            >
              {fileList.length >= 8 ? null : null}
            </Upload>
          </div>
        </Modal>

        <Modal
          open={previewOpen}
          // title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          {previewImage &&
            (previewImage.endsWith(".png") ||
              previewImage.endsWith(".jpg") ||
              previewImage.endsWith(".jpeg")) && (
              <img
                style={{
                  width: "80%",
                }}
                src={previewImage}
              />
            )}
          {!previewImage ||
            (!previewImage.endsWith(".png") &&
              !previewImage.endsWith(".jpg") &&
              !previewImage.endsWith(".jpeg") && (
                <dvi>
                  <p
                    style={{
                      margin: 5,
                      color: "darkgray",
                      paddingTop: 10,
                      fontFamily: "Khmer OS Battambang ,sans-serif",
                    }}
                  >
                    ឈ្មោះឯកសារ : {previewTitle}
                  </p>
                  <img
                    style={{
                      width: "50%",
                    }}
                    src=" https://icon-library.com/images/file-browser-icon/file-browser-icon-4.jpg"
                  />
                </dvi>
              ))}

          <Button
            type="primary"
            className="buttonstylekhmer"
            onClick={onClick_Download}
          >
            ទាញយក / Download
          </Button>
        </Modal>
        <Modal
          title="ទាញរបាយការណ៍"
          className="custom-modal" // Use the custom-modal class for responsive styles
          open={DownloadReport}
          onCancel={() => {
            setDownloadReport(false);
          }}
          footer={[
            <div>
              <Button className="custom-form-item" type="primary">
                ទាញយក (Excel)
              </Button>
            </div>,
          ]}
        >
          <div style={{ width: "520px" }}>
            <Space>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Space style={{ width: "100%" }}>
                  ច្បាប់ចាប់ពីថ្ងៃ ទី ខែ :
                  <DatePicker
                  //  onChange={handleDateChange_Start}
                  />
                </Space>
              </div>
              <div>
                <Space>
                  ដល់ថ្ងៃ ទី ខែ :
                  <DatePicker
                  //  onChange={handleDateChange_End}
                  />
                </Space>
              </div>
            </Space>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default LettersPage;
