import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class MyDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
    };
  }

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  render() {
    const { selectedDate } = this.state;
    return (
      <div className="input-group" style={{ width: 250 }}>
        <DatePicker
          showIcon
          selected={selectedDate}
          onChange={this.handleDateChange}
          dateFormat={"dd-MM-yyyy"}
          className="styleDatePicker"
          readOnly={this.props.readOnly}
          {...this.props}
        />
      </div>
    );
  }
}

export default MyDatePicker;
