import React from "react";
import OrganizationsPage from "../organizations/OrganizationsPage";

export default function SettingPage() {
  return (
    <div>
      <OrganizationsPage />
    </div>
  );
}
