import { ClockCircleOutlined, ApartmentOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import ShiftPage from "./ShiftPage";
import { FaPeopleArrows } from "react-icons/fa6";

import LeaveTypeMaster from "./LeavTypePage";
const { TabPane } = Tabs;
const SetupType = () => {
  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="ប្រភេទច្បាប់" key="2" icon={<FaPeopleArrows />}>
          <LeaveTypeMaster />
        </TabPane>
        <TabPane
          tab="កាលវិភាគម៉ោងធ្វើការ"
          key="3"
          icon={<ClockCircleOutlined />}
        >
          <ShiftPage />
        </TabPane>
        <TabPane
          tab="ក្រុម​ កាលវិភាគម៉ោងធ្វើការ"
          key="4"
          icon={<ApartmentOutlined />}
        >
          <p>Shift Group Comming in New Version...</p>
        </TabPane>
      </Tabs>
    </div>
  );
};
export default SetupType;
