// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// for raksmey.itmoh@gmail.com
const firebaseConfig = {
  apiKey: "AIzaSyAlxwZOHaI5D5sdk9gr-KEa5AOybDOhu48",
  authDomain: "otp-verify-2ed7d.firebaseapp.com",
  projectId: "otp-verify-2ed7d",
  storageBucket: "otp-verify-2ed7d.appspot.com",
  messagingSenderId: "697927097004",
  appId: "1:697927097004:web:42271fa897550a37e91d09",
  measurementId: "G-YK675YPXWZ",
};

// for raksmey.itmoh1@gmail.com
// const firebaseConfig = {
//   apiKey: "AIzaSyCFPs9LkH_OdNdmRYw91Qf2_PNBmHmbhX0",
//   authDomain: "otp-verify-c59ba.firebaseapp.com",
//   projectId: "otp-verify-c59ba",
//   storageBucket: "otp-verify-c59ba.appspot.com",
//   messagingSenderId: "1001160212842",
//   appId: "1:1001160212842:web:9adc16ff25fcd4ec392513",
//   measurementId: "G-WT1DFB0E8K",
// };
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth
const auth = getAuth(app);

// Disable app verification for testing (if you're testing locally or in development)
if (window.location.hostname === "localhost") {
  auth.settings.appVerificationDisabledForTesting = true;
}

export { auth };
