import { useEffect, useState } from "react";
import {
  PlusCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import {
  Modal,
  Button,
  Form,
  Input,
  Item,
  UserOutlined,
  Select,
  Space,
  Tag,
  Checkbox,
  Pagination,
} from "antd";
import "../../styles/Home-module.css";
import styles from "../../styles/Font.css";
import { request } from "../../share/request";
import Table from "react-bootstrap/Table";
import { Stack } from "react-bootstrap";

import { openNotification } from "../../share/message";
import { Circles } from "react-loader-spinner";
import { getCurrentUser, getPermission } from "../../share/getProfile";
import { getTageStyle } from "../../share/getStatusContent";
import GetLoading from "../../share/getLoading";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";

const { Option } = Select;
const Position = () => {
  const getProfileOrg = getCurrentUser();
  const [list, setList] = useState([]);
  const [listdepart, setListDepart] = useState([]);
  const [id, setID] = useState("");
  const [position, setPosition] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingSpin, setLoadingSpin] = useState(false);

  const [objFilter, setObjFilter] = useState({
    page: 1,
    department_code: "",
    org_code: getProfileOrg.org_code,
  });
  // -----------------  permisstion page --------------------
  const userPermission = getPermission();
  const positionValue = userPermission.find(
    (permission) => permission.pagename === "position"
  ) || { add: 0, edit: 0, deleted: 0 };
  const {
    add: addvalue,
    edit: Editvalue,
    deleted: deletevalue,
  } = positionValue;
  // -----------------  end permisstion page --------------------

  // const getList = async (objFilter) => {
  //   const param = new URLSearchParams({
  //     page: objFilter.page,
  //     department_code: objFilter.department_code,
  //     org_code: getProfileOrg.org_code,
  //   });
  //   try {
  //     await new Promise((resolve) => setTimeout(resolve, 400));
  //     const res = await request("position?" + param, "get", {});
  //     if (res) {
  //       setList(res.listPosition);
  //       setListDepart(res.listDepart);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const getList = async (objFilter) => {
    // Construct query parameters
    const param = new URLSearchParams({
      page: objFilter.page,
      department_code: objFilter.department_code,
      org_code: getProfileOrg.org_code, // Using the org_code from user profile
    });
    console.log("Parameters for department:", param.toString());
    try {
      // Simulate a delay for demonstration (remove in production)
      await new Promise((resolve) => setTimeout(resolve, 400));

      // Make the API request
      const res = await request("position?" + param.toString(), "get", {});

      // Check if the response contains data
      if (res) {
        setList(res.listPosition); // Update state with the list of positions
        setListDepart(res.listDepartment); // Update state with the list of departments
        setTotalPage(res.total_records);
      }
    } catch (error) {
      // Log any errors encountered during the fetch
      console.error("Error fetching data:", error);
    } finally {
      // Set loading state to false when the request is done
      setLoading(false);
    }
  };

  useEffect(() => {
    // block form load
    getList(objFilter);
  }, []);
  //-------------- page management ----------------------
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = list.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  //-------------------------------------------

  // ----------------- open modle ----------
  const [visableModelDelete, setVisableModeleDelete] = useState(false);
  const onDeleteModel = (item) => {
    setVisableModeleDelete(true);
    setID(item.id);
    setPosition(item.position_kh);
  };
  const onCLoseModelDelete = () => {
    setVisableModeleDelete(false);
  };
  const onComfirmModelDelete = async () => {
    console.log("ID delete is : " + id);
    const res = await request("position/" + id, "delete", {});
    setVisableModeleDelete(false);
    if (res) {
      getList(objFilter);
      setLoadingSpin(true);
      await new Promise((resolve) => setTimeout(resolve, 400));
      openNotification("success", "Success", "Delete Success !");
    } else {
      alert("Error!");
    }
    setLoadingSpin(false);
  };
  // --------------- end model delete--------

  //---------------- statuse --------------
  const getStatusContent = (status) => {
    switch (status) {
      case 1:
        return (
          <Button
            type="primary"
            ghost
            shape="round"
            icon={<CheckCircleOutlined />}
          >
            {" "}
            Enable{" "}
          </Button>
        );
      case 0:
        return (
          <Button
            type="primary"
            danger
            ghost
            shape="round"
            icon={<ClockCircleOutlined />}
          >
            {" "}
            Disable{" "}
          </Button>
        );

      default:
        return null;
    }
  };
  // ------------- end status----------

  //------------- style tage --------
  const styleTageStatus = {
    fontSize: "14px",
    height: "30px",
    borderRadius: "30px",
    paddingTop: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
  };

  //---------------------------

  //------------- save ----------
  const [visableNewModel, setVisableNewModel] = useState(false);
  const onOpenSaveNew = () => {
    setID("");
    setVisableNewModel(true);
    setEnable(true);
    setDisable(false);

    setDepartment("");
    setPosition_KH("");
    setPosition_EN("");
    setOther("");
  };
  const onCLoseNewDataModel = () => {
    setVisableNewModel(false);
  };
  //-------------   enable and disable ---------------

  const [enable, setEnable] = useState(false);
  const [disable, setDisable] = useState(false);
  const handleCheckboxChange_Enable = (e) => {
    setEnable(e.target.checked);
    setDisable(false);
  };
  const handleCheckboxChange_Diable = (e) => {
    setDisable(e.target.checked);
    setEnable(false);
  };
  //--------------------------------------------

  //------------------- save data to position--------
  const [department, setDepartment] = useState("");
  const [code, setCode] = useState("");
  const [position_kh, setPosition_KH] = useState("");
  const [position_en, setPosition_EN] = useState("");
  const [other, setOther] = useState("");
  const [TotalPage, setTotalPage] = useState("");
  //   const [status,setStatus]=useState(1);
  const onSaveData = async () => {
    if (id == "") {
      var data = {
        code: code,
        position_kh: position_kh,
        position_en: position_en,
        des: other,
        status: enable == true ? 1 : disable == true ? 0 : 0,
        user_create: getProfileOrg.fullname,
      };
      const res = request("position", "post", data);
      setVisableNewModel(false);
      if (res) {
        setLoadingSpin(true);
        await new Promise((resolve) => setTimeout(resolve, 400));
        openNotification("success", "Success", "Save Position Success");
      } else {
        alert("Error!");
      }
      getList(objFilter); // re call function list
      setLoadingSpin(false);
    } else {
      var data = {
        id: id,
        code: code,
        position_kh: position_kh,
        position_en: position_en,
        des: other,
        status: enable == true ? 1 : disable == true ? 0 : 1,
        user_create: getProfileOrg.fullname,
      };
      const res = request("position", "put", data);
      setVisableNewModel(false);
      if (res) {
        setLoadingSpin(true);
        await new Promise((resolve) => setTimeout(resolve, 400));
        openNotification("success", "Success", "Update Position Success");
      } else {
        alert("Error!");
      }
      getList(objFilter); // re call function list
      setLoadingSpin(false);
    }
  };
  //   const onSaveData = async () => {
  //     const isNew = id === "";
  //     const data = {
  //         id: isNew ? id : undefined,
  //         code: department,
  //         position_kh,
  //         position_en,
  //         des: other,
  //         status: enable ? 1 : 0,
  //         user_create: isNew ? "System create" : "System update"
  //     };

  //     const method = isNew ? "post" : "put";
  //     const res = await request("position", method, data);

  //     setVisableNewModel(false);
  //     if (res) {
  //         getList();
  //         setLoadingSpin(true);
  //         await new Promise(resolve => setTimeout(resolve, 400));
  //         openNotification('success', 'Success', isNew ? 'Save Position Success' : 'Update Position Success');
  //     } else {
  //         alert("Error!");
  //     }
  //     setLoadingSpin(false);
  // };

  const onChangeKH = (event) => {
    setPosition_KH(event.target.value);
  };
  const onChangeEN = (event) => {
    setPosition_EN(event.target.value);
  };
  const onChangeOther = (event) => {
    setOther(event.target.value);
  };
  const onChangeDepartment = async (value) => {
    setCode(value);
    console.log(value);
    setCurrentPage(1);
    try {
      setLoadingSpin(true);

      var objTmp = {
        ...objFilter,
        department_code: value, // Set the department name
        page: 1, // Set page to 1 if input is empty, otherwise retain current page
      };

      setObjFilter(objTmp); // Update the filter state
      getList(objTmp); // Fetch the updated list
      await new Promise((resolve) => setTimeout(resolve, 400));
    } catch (error) {
    } finally {
      setLoadingSpin(false); //
    }
  };
  const onChangeDepartmentNew = (value) => {
    setCode(value);
  };
  //--------------------

  //--------------- Eidt data ----------
  const onOpenEdit = (item) => {
    setID(item.id);
    setCode(item.code);
    setDepartment(item.code + " -" + item.department_name);
    setPosition_KH(item.position_kh);
    setPosition_EN(item.position_en);
    setOther(item.des);
    setVisableNewModel(true);
    if (item.status === 1) {
      return setEnable(true), setDisable(false);
    } else {
      return setEnable(false), setDisable(true);
    }
  };

  //-------------------------
  const onCancelModel = () => {
    setVisableNewModel(false);
  };
  const onCanceDelete = () => {
    setVisableModeleDelete(false);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const onShowSizeChange = async (current) => {
    console.log(current);
    setPageNumber(current);
    try {
      setLoadingSpin(false);
      var objTmp = {
        ...objFilter,
        page: current,
      };
      await new Promise((resolve) => setTimeout(resolve, 400));
      setObjFilter(objTmp);
      getList(objTmp);
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };

  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <GetLoading />
          </div>
        )}
      </div>

      <div style={boxHeader}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {boxHeaderTagText("ការគ្រប់គ្រង់ ព៏តមានតួនាទី")}
          <Select
            className="styleTageStatusForm"
            placeholder="Select an Deparment"
            style={{ width: 250 }}
            onChange={onChangeDepartment}
            allowClear
          >
            {listdepart.map((option) => (
              <Option
                className="styleTageStatusForm"
                key={option.code}
                value={option.code}
              >
                {option.department_name}{" "}
                {/* Replace with the field from your MySQL table */}
              </Option>
            ))}
          </Select>
        </div>
        <Button
          onClick={onOpenSaveNew}
          disabled={!addvalue}
          className="custom-form-item"
          type="primary"
          icon={<PlusCircleOutlined />}
        >
          បន្ថែមតួនាទីថ្មី
        </Button>
      </div>

      <div>
        {loading ? (
          <div className="sprinCustome">
            <GetLoading />
          </div>
        ) : (
          <Table
            // striped
            // bordered
            // hover
            className="rounded-table"
            itemsPerPage={10}
          >
            <thead>
              <tr class="table-success">
                <th>NO</th>
                <th>ផ្នែក</th>
                <th>តួនាទី ភាសាខ្មែរ</th>
                <th>តួនាទី English</th>
                <th>ផ្សេងៗ</th>
                <th>ស្ថានភាព</th>
                <th>អ្នកបង្កើត</th>
                <th>សកម្មភាព</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((item, index) => (
                <tr key={index}>
                  <td>{10 * (pageNumber - 1) + (index + 1)}</td>
                  <td>{getTageStyle(item.department_name)}</td>
                  <td>{item.position_kh}</td>
                  <td>{item.position_en}</td>
                  <td>{item.des}</td>
                  <td>{getStatusContent(item.status)}</td>
                  <td>
                    <Tag
                      style={styleTageStatus}
                      icon={<UsergroupAddOutlined />}
                      color="green"
                    >
                      {item.user_create}
                    </Tag>
                  </td>

                  <td style={{ width: 100 }}>
                    <Stack gap={1} direction="horizontal">
                      <Button
                        disabled={!Editvalue}
                        type="primary"
                        onClick={() => onOpenEdit(item)}
                      >
                        Edit
                      </Button>{" "}
                      <Button
                        disabled={!deletevalue}
                        type="primary"
                        onClick={() => onDeleteModel(item)}
                        danger
                      >
                        Delete
                      </Button>
                    </Stack>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        <Pagination
          //   showSizeChanger
          onChange={onShowSizeChange}
          defaultCurrent={currentPage}
          total={TotalPage}
        />

        <Modal
          title={id == "" ? "New Position" : "Update Position"}
          className="styleTageStatusForm"
          open={visableNewModel}
          onCancel={onCancelModel}
          footer={[
            <div>
              <Space>
                <Button onClick={onCLoseNewDataModel}>Cancel</Button>
                <Button type="primary" onClick={onSaveData}>
                  {id == "" ? "Save" : "Update"}
                </Button>
              </Space>
            </div>,
          ]}
        >
          <div style={{ display: "flex", justifyContent: "start" }}>
            <div>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ឈ្មោះផ្នែក / Deparment Name
              </p>

              <Select
                style={{ width: 250 }}
                // showSearch
                placeholder="Select a department"
                // optionFilterProp="children"
                onChange={onChangeDepartmentNew}
                value={code}
              >
                {listdepart.map((option) => (
                  <Option
                    className="styleTageStatusForm"
                    key={option.code}
                    value={option.code}
                  >
                    {option.code} {"-"} {option.department_name}{" "}
                    {/* Replace with the field from your MySQL table */}
                  </Option>
                ))}
              </Select>

              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                តួរនាទី ភាសាខ្មែរ / Position in Khmer
              </p>

              <Input.TextArea
                className="styleTageStatusForm"
                rows={2}
                placeholder="input description"
                value={position_kh}
                onChange={onChangeKH}
                style={{ width: 250 }}
              />

              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                តួរនាទី English
              </p>

              <Input.TextArea
                className="styleTageStatusForm"
                rows={2}
                placeholder="input other"
                value={position_en}
                onChange={onChangeEN}
                style={{ width: 250 }}
              />

              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ផ្សេងៗ / Other
              </p>
              <Input.TextArea
                className="styleTageStatusForm"
                rows={4}
                placeholder="input other"
                value={other}
                onChange={onChangeOther}
                style={{ width: 250 }}
              />
            </div>

            <div style={{ paddingLeft: 20 }}>
              <p
                style={{
                  margin: 5,
                  color: "darkgray",
                  paddingTop: 10,
                  fontFamily: "Khmer OS Battambang ,sans-serif",
                }}
              >
                ស្ថានភាព / Status
              </p>

              <Tag>
                {" "}
                <div style={{ padding: 10 }}>
                  <Checkbox
                    checked={enable}
                    onChange={handleCheckboxChange_Enable}
                  >
                    Enable
                  </Checkbox>
                  <Checkbox
                    style={{ color: "red" }}
                    checked={disable}
                    onChange={handleCheckboxChange_Diable}
                  >
                    Disable
                  </Checkbox>
                </div>
              </Tag>
            </div>
          </div>
        </Modal>

        <Modal
          title="លុបតួនារទីចេញពីប្រព័ន្ធ "
          className="custom-form-item"
          open={visableModelDelete}
          onCancel={onCanceDelete}
          footer={[
            <div>
              <Button onClick={onCLoseModelDelete}>No</Button>
              <Button onClick={onComfirmModelDelete} type="primary">
                Yes
              </Button>
            </div>,
          ]}
        >
          <p className="custom-form-item" style={{ color: "red" }}>
            តើអ្នកចង់លុបផ្នែក ៖ {position} នេះចេញពីប្រព័ន្ធ ?
          </p>
        </Modal>
      </div>
    </div>
  );
};

export default Position;
